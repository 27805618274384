import React from 'react'

import {v4 as uuidv4} from 'uuid'

import {useDrag} from 'react-dnd'
import {useIntl} from 'react-intl'

const Gauge = () => {
  const intl = useIntl()
  const initialData = {
    value: 1,
    segments: 5,
    segmentColors: ['#a3be8c', '#d08770', '#ebcb8b', '#bf616a', '#b48ead'],
    width: 400,
    height: 400,
  }
  const [{isDragging}, drag] = useDrag({
    type: 'gauge',
    item: {
      dataGrid: {x: 0, y: 0, w: 4, h: 5, isResizable: false},
      selectedTag: null,
      id: uuidv4(),
      type: 'gauge',
      data: {
        gauge: {
          ...initialData,
        },
      },
    },

    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  })

  return (
    <div
      ref={drag}
      style={{
        opacity: isDragging ? 0.5 : 1,
        cursor: 'move',
        // border: '1px solid black',
        width: '100%',
        padding: 15,
        borderRadius: 4,
        marginTop: 20,
      }}
    >
      <div className='text-center'>
        <h6>{intl.formatMessage({id: 'GAUGECHART'})}</h6>
        <img alt='' src='/images/gauge.png' width='75' height={50} />
      </div>
    </div>
  )
}

export default Gauge
