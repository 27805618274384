import DataTable from 'react-data-table-component-with-filter'
import {useNavigate} from 'react-router-dom'
import {Loader} from '../Loader'
import Swal from 'sweetalert2'
import {useMutation, useQuery} from 'react-query'
import DeviceService from '../../service/device'
import {usePagination} from '../../hooks'
import {useEffect} from 'react'
import {useIntl} from 'react-intl'

const DeviceManagementTable = () => {
  const navigate = useNavigate()
  const intl = useIntl()
  const {setTotalRows, page, perPage, totalRows, handlePerRowsChange, handlePageChange} =
    usePagination()
  const {data, isLoading, refetch} = useQuery(['devices', page + perPage], async () =>
    DeviceService.getAll({
      page,
      perPage,
    })
  )

  const mutation = useMutation(async (id) => await DeviceService.remove(id))

  useEffect(() => {
    totalRows !== data?.totalCount && setTotalRows(data?.totalCount)
  }, [data?.totalCount])

  const columns = [
    {
      name: intl.formatMessage({id: 'DEVICENAME'}),
      selector: (row) => row.name,
      sortable: true,
      filterable: true,
    },
    {
      name: intl.formatMessage({id: 'DESCRIPTION'}),
      selector: (row) => row.description,
      sortable: true,
      filterable: true,
    },
    {
      name: intl.formatMessage({id: 'ACTIONS'}),
      sortable: false,
      filterable: false,
      cell: (row) => {
        return (
          <>
            <div>
              <button
                disabled={mutation.isLoading}
                onClick={() => navigate(`/device-management/${row.id}`)}
                className='btn btn-primary'
              >
                <i className='fa fa-pencil mx-2 '></i>
              </button>
            </div>
            <div>
              <button
                disabled={mutation.isLoading}
                onClick={() => {
                  Swal.fire({
                    title: intl.formatMessage({id: 'AREYOUSURE'}),
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: intl.formatMessage({id: 'YES'}),
                    cancelButtonText: intl.formatMessage({id: 'NO'}),
                  }).then(async (result) => {
                    if (result.isConfirmed) {
                      const res = await mutation.mutateAsync(row.id)
                      if (res.isSuccess) {
                        Swal.fire({
                          title: intl.formatMessage({id: 'SUCCESS'}),
                          // text: intl.formatMessage({id: 'DELETEDSUCCESSFULLY'}),
                          icon: 'success',
                          showConfirmButton: false,
                          timer: 1500,
                        })
                        refetch()
                        return
                      }

                      Swal.fire({
                        title: 'Hata',
                        text: res?.errors?.[0]?.message,
                        icon: 'error',
                        showConfirmButton: false,
                        timer: 2000,
                      })
                    }
                  })
                }}
                className='btn btn-danger mx-4'
              >
                <i className='fa fa-trash mx-2 '></i>
              </button>
            </div>
          </>
        )
      },
    },
  ]
  return (
    <div className='card'>
      <div className='card-body p-4'>
        {isLoading ? (
          <div className='text-center mt-2 mb-2'>
            <Loader />
          </div>
        ) : (
          <>
            <div className='table-page-header p-5 my-3'>
              <div className='add-new-user '>
                <button
                  onClick={() => navigate('/device-management/add')}
                  className='btn btn-primary '
                >
                  <i className='fa fa-plus mx-2 '></i> {intl.formatMessage({id: 'ADDNEWDEVICE'})}
                </button>
                <button
                  onClick={() => navigate('/device-management/add?package=1')}
                  className='btn btn-dark mx-4'
                >
                  <i className='fa fa-plus mx-2 '></i>
                  {intl.formatMessage({id: 'ADDPACKAGEDEVİCE'})}
                </button>
              </div>
            </div>

            <DataTable
              progressPending={isLoading}
              progressComponent={<Loader />}
              className='table-striped custom-filtered-table'
              pagination
              paginationServer
              paginationTotalRows={totalRows}
              paginationDefaultPage={page}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
              noDataComponent={
                <div style={{margin: '25px 0px'}}>{intl.formatMessage({id: 'TABLE:NODATA'})}</div>
              }
              customStyles={{
                rows: {
                  style: {
                    cursor: 'pointer',
                    backgroundColor: 'var(--table-bg)',
                    '&:hover': {
                      backgroundColor: '#f3f3f3',
                    },
                  },
                },
              }}
              columns={columns}
              data={data?.items}
            />
          </>
        )}
      </div>
    </div>
  )
}

export default DeviceManagementTable
