import DataTable from 'react-data-table-component-with-filter'
import {useNavigate} from 'react-router-dom'
import {Loader} from '../Loader'
import Swal from 'sweetalert2'
import {useMutation, useQuery} from 'react-query'
import {usePagination} from '../../hooks'
import {useCallback, useEffect, useState} from 'react'
import TagService from '../../service/tag'
import {useIntl} from 'react-intl'
import {Modal} from 'react-bootstrap'
import {useDropzone} from 'react-dropzone'
import DeviceService from '../../service/device'

const DataManagementTable = () => {
  const navigate = useNavigate()
  const [showModal, setShowModal] = useState(false)
  const [file, setFile] = useState(null)
  const [edgeDeviceId, setEdgeDeviceId] = useState(null)
  const [deviceId, setDeviceId] = useState(null)

  const [uploadLoading, setUploadLoading] = useState(false)

  const {data: devices} = useQuery(
    ['devices', edgeDeviceId],
    async () =>
      !!edgeDeviceId &&
      DeviceService.getDeviceByEdgeDeviceId({
        id: edgeDeviceId,
      })
  )
  const {data: edgeDevices} = useQuery(['edgedevices'], async () =>
    DeviceService.getAllEdgeDevices({
      page: 1,
      perPage: 1000,
    })
  )

  const onDrop = useCallback((acceptedFiles) => {
    setFile(acceptedFiles[0])
  }, [])
  const {isDragActive, getRootProps, getInputProps} = useDropzone({
    onDrop,
    multiple: false,
    accept: {
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': '.xlsx',
      'application/vnd.ms-excel': '.xls',
    },
  })

  const handleSubmit = async (event) => {
    event.preventDefault()
    setUploadLoading(true)
    if (!file) {
      Swal.fire('Please select a file first!')
      setUploadLoading(false)
      return
    }
    try {
      await TagService.importExcel({deviceId, file})
      Swal.fire(intl.formatMessage({id: 'SUCCESS'}), '', 'success')
      refetch()
      setShowModal(false)
      setUploadLoading(false)
    } catch (error) {
      setUploadLoading(false)
      Swal.fire(
        intl.formatMessage({id: 'ERROR'}),
        `${error.response.data.errors.map((e) => e.message).join('<br/>')}`,
        'error'
      )
    }
  }
  const intl = useIntl()
  const {setTotalRows, page, perPage, totalRows, handlePerRowsChange, handlePageChange} =
    usePagination()
  const {data, isLoading, refetch} = useQuery(['tags', page + perPage], async () =>
    TagService.getAll({
      page,
      perPage,
    })
  )

  const mutation = useMutation(async (id) => await TagService.remove(id))

  useEffect(() => {
    totalRows !== data?.totalCount && setTotalRows(data?.totalCount)
  }, [data?.totalCount])

  const columns = [
    {
      name: intl.formatMessage({id: 'DEVICENAME'}),
      selector: (row) => row?.device?.name,
      sortable: true,
      filterable: true,
    },
    {
      name: intl.formatMessage({id: 'TAGNAME'}),
      selector: (row) => row.name,
      sortable: true,
      filterable: true,
    },
    {
      name: intl.formatMessage({id: 'TAGADDRESS'}),
      selector: (row) => row.address,
      sortable: true,
      filterable: true,
    },
    {
      name: intl.formatMessage({id: 'DATATYPE'}),
      selector: (row) => row.dataType?.name,
      sortable: true,
      filterable: true,
    },
    {
      name: intl.formatMessage({id: 'ACTIONS'}),
      sortable: false,
      filterable: false,
      cell: (row) => {
        return (
          <>
            <div>
              <a
                disabled={mutation.isLoading}
                href={`/data-management/${row.id}`}
                className='btn btn-primary'
              >
                <i className='fa fa-pencil mx-2 '></i>
              </a>
            </div>
            <div>
              <button
                disabled={mutation.isLoading}
                onClick={() => {
                  Swal.fire({
                    title: intl.formatMessage({id: 'AREYOUSURE'}),
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: intl.formatMessage({id: 'YES'}),
                    cancelButtonText: intl.formatMessage({id: 'NO'}),
                  }).then(async (result) => {
                    if (result.isConfirmed) {
                      await mutation.mutateAsync(row.id)
                      Swal.fire({
                        title: intl.formatMessage({id: 'DELETED'}),
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 1500,
                      })
                      refetch()
                    }
                  })
                }}
                className='btn btn-danger mx-4'
              >
                <i className='fa fa-trash mx-2 '></i>
              </button>
            </div>
          </>
        )
      },
    },
  ]
  return (
    <div className='card'>
      <div className='card-body p-4'>
        {isLoading ? (
          <div className='text-center mt-2 mb-2'>
            <Loader />
          </div>
        ) : (
          <>
            <div className='table-page-header p-5 my-3'>
              <div className='add-new-user '>
                <button
                  onClick={() => navigate('/data-management/add')}
                  className='btn btn-primary '
                >
                  <i className='fa fa-plus mx-2 '></i> {intl.formatMessage({id: 'ADDNEWDATA'})}
                </button>
              </div>
              <div className='import-excel '>
                <button onClick={() => setShowModal(true)} className='btn btn-primary '>
                  <i className='fa fa-plus mx-2 '></i> {intl.formatMessage({id: 'IMPORTEXCEL'})}
                </button>
              </div>
            </div>

            <DataTable
              progressPending={isLoading}
              progressComponent={<Loader />}
              className='table-striped custom-filtered-table'
              pagination
              paginationServer
              paginationTotalRows={totalRows}
              paginationDefaultPage={page}
              noDataComponent={
                <div style={{margin: '25px 0px'}}> {intl.formatMessage({id: 'TABLE.NODATA'})} </div>
              }
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
              customStyles={{
                rows: {
                  style: {
                    cursor: 'pointer',
                    backgroundColor: 'var(--table-bg)',
                    '&:hover': {
                      backgroundColor: '#f3f3f3',
                    },
                  },
                },
              }}
              columns={columns}
              data={data}
            />
          </>
        )}
      </div>
      <Modal
        className='modal fade'
        id='kt_modal_select_location'
        data-backdrop='static'
        tabIndex={-1}
        role='dialog'
        show={showModal}
        dialogClassName='modal-xl'
      >
        <div className='modal-content'>
          <div className='modal-header'>
            <h5 className='modal-title'>
              {intl.formatMessage({id: 'EDGEDEVICE'})} {intl.formatMessage({id: 'UPDATE'})}{' '}
            </h5>
          </div>
          <div className='modal-body'>
            <div className='row mb-6'>
              <label className='col-lg-3 col-form-label required fw-bold fs-6'>
                {intl.formatMessage({id: 'EDGEDEVICE'})}
              </label>

              <div className='col-lg-9'>
                <div className='row'>
                  <div className='col-lg-12 fv-row'>
                    <select
                      className='form-select'
                      onChange={(e) => {
                        setEdgeDeviceId(parseInt(e.target.value))
                      }}
                    >
                      <option value='' disabled selected>
                        {intl.formatMessage({id: 'CHOOSE'})}
                      </option>
                      {!!edgeDevices?.items &&
                        edgeDevices?.items?.map((item, index) => {
                          return (
                            <option value={item.id} key={index}>
                              {' '}
                              {item.name}{' '}
                            </option>
                          )
                        })}
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-3 col-form-label required fw-bold fs-6'>
                {' '}
                {intl.formatMessage({id: 'DEVICE'})}{' '}
              </label>

              <div className='col-lg-9'>
                <div className='row'>
                  <div className='col-lg-12 fv-row'>
                    <select
                      name='deviceId'
                      onChange={(e) => {
                        setDeviceId(parseInt(e.target.value))
                      }}
                      className='form-select '
                    >
                      <option value='' disabled selected>
                        {intl.formatMessage({id: 'CHOOSE'})}
                      </option>
                      {!!devices &&
                        devices?.map((item) => (
                          <option key={item.id} value={item.id}>
                            {item.name}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <section className='container'>
              <div
                style={{
                  textAlign: 'end',
                  marginBottom: '10px',
                }}
              >
                <a href='/tag-import.xlsx' download='/tag-import.xlsx' className=''>
                  {intl.formatMessage({id: 'SAMPLEFILE'})}{' '}
                </a>
              </div>
              <div
                {...getRootProps({className: 'dropzone'})}
                style={{
                  backgroundColor: isDragActive ? '#3594e8' : '',
                }}
              >
                <input {...getInputProps()} />
                {uploadLoading ? (
                  <Loader />
                ) : file ? (
                  <p>{file.name}</p>
                ) : (
                  <p>{intl.formatMessage({id: 'UPLOADFILE'})} (.xls, .xlsx)</p>
                )}
              </div>
            </section>
          </div>
          <div className='modal-footer'>
            <button
              type='button'
              className='btn btn-light-primary'
              onClick={() => setShowModal(false)}
            >
              {intl.formatMessage({id: 'CLOSE'})}
            </button>
            <button
              id='submit'
              type='button'
              className='btn btn-primary'
              onClick={handleSubmit}
              disabled={!edgeDeviceId || !deviceId || isLoading || !file || uploadLoading}
              // onClick={async () => {
              //   setShowModal(false)
              //   await mutation.mutateAsync({
              //     id: selectedDevice.id,
              //     name: deviceName,
              //     licenceId: selectedDevice.licenceId,
              //     dataSimId: selectedDevice.dataSimId,
              //     deviceTypeId: selectedDevice.deviceTypeId,
              //     tenantId: JSON.parse(localStorage.getItem('user')).tenantId,
              //   })
              //   refetch()
              // }}
            >
              {intl.formatMessage({id: 'SAVE'})}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default DataManagementTable
