import axios from './index'

const getRental = async (params) => {
  try {
    const res = await axios.get('/Rental', {
      params,
    })

    return res?.data?.value
  } catch (error) {}
}
const getRentalMachine = async (params) => {
  try {
    const res = await axios.get('/RentalMachine', {
      params,
    })

    return res?.data?.value
  } catch (error) {}
}
const getRentalCompany = async (params) => {
  try {
    const res = await axios.get('/RentalCompany', {
      params,
    })

    return res?.data?.value
  } catch (error) {}
}
const getRentalDetail = async (id) => {
  try {
    const res = await axios.get(`/Rental/${id}`)
    return res?.data?.value
  } catch (error) {
    console.log(error)
  }
}
const getRentalMachineDetail = async (id) => {
  try {
    const res = await axios.get(`/RentalMachine/${id}`)
    return res?.data?.value
  } catch (error) {
    console.log(error)
  }
}
const getRentalCompanyDetail = async (id) => {
  try {
    const res = await axios.get(`/RentalCompany/${id}`)
    return res?.data?.value
  } catch (error) {
    console.log(error)
  }
}
const removeRental = async (id) => {
  const res = await axios.delete(`/Rental/${id}`)
  return res?.data
}
const removeRentalMachine = async (id) => {
  const res = await axios.delete(`/RentalMachine/${id}`)
  return res?.data
}
const removeRentalCompany = async (id) => {
  const res = await axios.delete(`/RentalCompany/${id}`)
  return res?.data
}
const createRental = async (data) => {
  const res = await axios.post(`/Rental`, data)
  return res?.data
}
const createRentalMachine = async (data) => {
  const res = await axios.post(`/RentalMachine`, data)
  return res?.data
}
const createRentalCompany = async (data) => {
  const res = await axios.post(`/RentalCompany`, data)
  return res?.data
}
const updateRental = async (id, data) => {
  const res = await axios.put(`/Rental/${id}`, data)
  return res?.data
}
const updateRentalMachine = async (id, data) => {
  const res = await axios.put(`/RentalMachine/${id}`, data)
  return res?.data
}
const updateRentalCompany = async (id, data) => {
  const res = await axios.put(`/RentalCompany/${id}`, data)
  return res?.data
}

const RentalService = {
  getRental,
  getRentalMachine,
  getRentalCompany,
  createRental,
  createRentalCompany,
  createRentalMachine,
  removeRentalMachine,
  removeRentalCompany,
  removeRental,
  updateRental,
  updateRentalMachine,
  updateRentalCompany,
  getRentalDetail,
  getRentalMachineDetail,
  getRentalCompanyDetail,
}

export default RentalService
