/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {SidebarMenuItem} from './SidebarMenuItem'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'

const SidebarMenuMain = () => {
  const intl = useIntl()

  return (
    <>
      <SidebarMenuItem
        icon='code'
        to='/dashboard-management'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
      />
      <SidebarMenuItem
        to='/edge-device-management'
        icon='gear'
        title={intl.formatMessage({id: 'MENU.EDGEDEVICEMANAGEMENT'})}
        fontIcon='bi-app-indicator'
      />
      <SidebarMenuItem
        to='/category'
        icon='gear'
        title={intl.formatMessage({id: 'MENU.CATEGORY'})}
        fontIcon='bi-app-indicator'
      />
      <SidebarMenuItem
        to='/device-management'
        icon='gear'
        title={intl.formatMessage({id: 'MENU.DEVICEMANAGEMENT'})}
        fontIcon='bi-app-indicator'
      />

      <SidebarMenuItem
        to='/data-management'
        icon='gear'
        title={intl.formatMessage({id: 'MENU.DATAMANAGEMENT'})}
        fontIcon='bi-app-indicator'
      />
      <SidebarMenuItemWithSub
        to='#'
        title={intl.formatMessage({id: 'MENU.RENTAL'})}
        icon='gear'
        fontIcon='bi-app-indicator'
      >
        <SidebarMenuItem
          to='rental-machine'
          title={intl.formatMessage({id: 'MENU.RENTALMACHINE'})}
          icon='user'
          fontIcon='bi-app-user'
        ></SidebarMenuItem>
        <SidebarMenuItem
          to='rental-company'
          title={intl.formatMessage({id: 'MENU.RENTALCOMPANY'})}
          icon='user'
          fontIcon='bi-app-user'
        ></SidebarMenuItem>
        <SidebarMenuItem
          to='rental'
          title={intl.formatMessage({id: 'MENU.RENTALSETUP'})}
          icon='user'
          fontIcon='bi-app-user'
        ></SidebarMenuItem>
      </SidebarMenuItemWithSub>
      {/* <SidebarMenuItem
        to='/report-management'
        icon='gear'
        title={intl.formatMessage({id: 'MENU.REPORTMANAGEMENT'})}
        fontIcon='bi-app-indicator'
      /> */}
      <SidebarMenuItem icon='code' to='/flows' title={intl.formatMessage({id: 'MENU.FLOW'})} />
      {/* <SidebarMenuItem
        to='crafted/account/settings'
        title={intl.formatMessage({id: 'MENU.ACCOUNTSETTING'})}
        icon='user'
        fontIcon='bi-app-indicator'
      /> */}
      <SidebarMenuItemWithSub
        to='#'
        title={intl.formatMessage({id: 'MENU.ACCOUNTSETTING'})}
        icon='user'
        fontIcon='bi-app-user'
      >
        <SidebarMenuItem
          to='crafted/account/settings'
          title={intl.formatMessage({id: 'MENU.PROFILE'})}
          icon='user'
          fontIcon='bi-app-user'
        ></SidebarMenuItem>
        <SidebarMenuItem
          to='account-management'
          title={intl.formatMessage({id: 'MENU.USERSLIST'})}
          icon='user'
          fontIcon='bi-app-user'
        ></SidebarMenuItem>
      </SidebarMenuItemWithSub>

      {/* <SidebarMenuItemWithSub
        to='#'
        title='Log Manager'
        icon='information'
        fontIcon='bi-app-user'
      >
      {/* <SidebarMenuItemWithSub
        to='#'
        title='Log Manager'
        icon='information'
        fontIcon='bi-app-user'
      >
       
      <SidebarMenuItem
        to='/system-logs'
        hasBullet={true}
        title={intl.formatMessage({id: 'MENU.SYSTEMLOGS'})}
        fontIcon='bi-app-user'
      />
      <SidebarMenuItem
        to='/device-logs'
        hasBullet={true}
        title={intl.formatMessage({id: 'MENU.DEVICELOGS'})}
        fontIcon='bi-app-user'
      />
      </SidebarMenuItemWithSub> */}

      {/* 

      <SidebarMenuItem
        to='/runs'
        icon='element-12'
        title={intl.formatMessage({id: 'MENU.RUNS'})}
        fontIcon='bi-app-indicator'
      />

      <SidebarMenuItem to='/table' icon='element-12' title={'Table'} fontIcon='bi-app-indicator' /> 
      <SidebarMenuItem
        to='/new-run-detail/04b463c1-2502-42cb-8e1f-e74848584757'
        icon='element-12'
        title={'New Run Detail'}
        fontIcon='bi-app-indicator'
      />

      <SidebarMenuItem
        to='/reports'
        icon='document'
        title={'Reports'}
        fontIcon='bi-app-indicator'
      />

      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Static</span>
        </div>
      </div>

      <SidebarMenuItem to='/faq' icon='abstract-28' title='FAQ' fontIcon='bi-layers' />

      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Admin</span>
        </div>
      </div>

      <SidebarMenuItem
        to='/users-list'
        icon='element-12'
        title={intl.formatMessage({id: 'MENU.USERSLIST'})}
        fontIcon='bi-app-indicator'
      />
      <SidebarMenuItem
        to='/users-run-list'
        icon='element-12'
        title={intl.formatMessage({id: 'MENU.RUNSLIST'})}
        fontIcon='bi-app-indicator'
      /> */}
      {/* <div className='menu-item'>
        <a
          target='_blank'
          className='menu-link'
          href={process.env.REACT_APP_PREVIEW_DOCS_URL + '/docs/changelog'}
        >
          <span className='menu-icon'>
            <KTIcon iconName='code' className='fs-2' />
          </span>
          <span className='menu-title'>Changelog {process.env.REACT_APP_VERSION}</span>
        </a>
      </div> */}
    </>
  )
}

export {SidebarMenuMain}
