/* eslint-disable jsx-a11y/anchor-is-valid */
import {PageTitle} from '../../_metronic/layout/core'
import {useIntl} from 'react-intl'
import DeviceLogsTable from '../../components/DeviceLogs/DeviceLogsTable'

const DeviceLogsPage = () => {
  const intl = useIntl()

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DEVICELOGS'})}</PageTitle>
    <DeviceLogsTable/>
    </>
  )
}

export {DeviceLogsPage}
