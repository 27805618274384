/* eslint-disable jsx-a11y/anchor-is-valid */
import {PageTitle} from '../../_metronic/layout/core'
import {useMutation, useQuery} from 'react-query'
import DeviceService from '../../service/device'
import {Loader} from '../../components/Loader'
import {Link} from 'react-router-dom'
import {useEffect, useState} from 'react'
import Swal from 'sweetalert2'
import AccountService from '../../service/account'

const CreateEdgeDevicePage = () => {
  // const [selledgeDevices, setSellEdgeDevices] = useState([])
  const {data, isLoading} = useQuery('edgedevices', async () =>
    DeviceService.getEdgeDeviceSell({
      page: 1,
      perPage: 1000,
    })
  )

  const {data: selectedDevices, isLoading: isSellLoading} = useQuery(
    ['edgedevices', 1 + 1000],
    async () =>
      DeviceService.getAllEdgeDevices({
        page: 1,
        perPage: 1000,
      })
  )

  // useEffect(() => {
  //   if (selectedDevices?.items) {
  //     const selectedDevicesIds = selectedDevices.items.map((item) => item.id)
  //     setSellEdgeDevices(selectedDevicesIds)
  //   }
  // }, [selectedDevices])

  const mutation = useMutation((deviceId) =>
    AccountService.sendMail({
      name: JSON.parse(localStorage.getItem('user')).userName,
      email: JSON.parse(localStorage.getItem('user')).email,
      message: `Kullanıcı Cihaz Talep Ediyor !!! Cihaz ID ${deviceId}`,
    })
  )

  return (
    <>
      <PageTitle breadcrumbs={[]}>Photonce Ağ Geçidi Talep Et</PageTitle>
      <div className='table-page-header p-5 my-3'>
        <div className='add-new-user '>
          <Link className='btn  btn-primary ' to='/edge-device-management'>
            Geri Dön
          </Link>
        </div>
      </div>

      <div className='row'>
        {(isSellLoading || isLoading) && <Loader />}
        {!isSellLoading &&
          !isLoading &&
          data?.items.map((item) => (
            <div className='col-12 col-md-3 card' key={item.id}>
              <img
                width={300}
                className='card-img-top'
                height={300}
                src={item.image}
                alt={item.name}
              />
              <div class='card-body'>
                <h3 class='card-title'>{item.name}</h3>
                <p class='card-text'>{item.description}</p>
                <p class='card-text'>
                  <b>
                    {Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(
                      item.price
                    )}
                  </b>
                </p>
                <button
                  disabled={isSellLoading || isLoading || mutation.isLoading}
                  onClick={async () => {
                    mutation
                      .mutateAsync(item.id)
                      .then(() => {
                        Swal.fire(
                          'Başarılı',
                          'Talebiniz Oluşturuldu <br> En kısa sürede size geri dönüş sağlayacağız.',
                          'success'
                        )
                      })
                      .catch((e) => {})
                  }}
                  class='btn btn-primary'
                >
                  Talep Oluştur
                </button>
              </div>
            </div>
          ))}
      </div>
    </>
  )
}

export {CreateEdgeDevicePage}
