import DataTable from 'react-data-table-component-with-filter'
import {useNavigate} from 'react-router-dom'
import {useIntl} from 'react-intl'
import Swal from 'sweetalert2'
import {Loader} from '../Loader'
const DeviceLogsTable = () => {
  const navigate = useNavigate()
  const intl = useIntl()
  const tableIsLoading = false
  const user = [
    {
      id: '6f988de5-275a-49b5-936f-6253455420fb',
      logMessage: 'Log Message',
      deviceName: 'Device-1',
      logDate: '02/04/23 14:46:32',
      status: 1,
    },

    {
      id: '6f988de5-275a-49b5-936f-6253455420fb',
      logMessage: 'Log Message',
      deviceName: 'Device-1',
      logDate: '02/04/23 14:46:32',
      status: 1,
    },

    {
      id: '6f988de5-275a-49b5-936f-6253455420fb',
      logMessage: 'Log Message',
      deviceName: 'Device-1',
      logDate: '02/04/23 14:46:32',
      status: 2,
    },

    {
      id: '6f988de5-275a-49b5-936f-6253455420fb',
      logMessage: 'Log Message',
      deviceName: 'Device-1',
      logDate: '02/04/23 14:46:32',
      status: 2,
    },
  ]

  const columns = [
    {
      name: 'Device Name',
      selector: (row) => row.deviceName,
      sortable: true,
      filterable: true,
    },
    {
      name: 'Log',
      selector: (row) => row.logMessage,
      sortable: true,
      filterable: true,
    },
    {
      name: 'Status',
      selector: (row) => row.status,
      sortable: true,
      filterable: true,
      cell: (row) => {
        const statusClass =
          row.status === 1
            ? 'badge   badge-light-success'
            : row.status === 2 || row.status === 0
            ? 'badge   badge-light-danger'
            : 'badge   badge-light-warning'
        const statusText =
          row.status === 1
            ? 'Success'
            : row.status === 2 || row.status === 0
            ? 'Error'
            : 'Processing'

        return <span className={`${statusClass}`}>{statusText}</span>
      },
    },
    {
      name: 'Date',
      selector: (row) => row.logDate,
      sortable: true,
      filterable: true,
    },
  ]
  return (
    <div className='card'>
      <div className='card-body p-4'>
        {tableIsLoading ? (
          <div className='text-center mt-2 mb-2'>
            <Loader />
          </div>
        ) : (
          <>
            <DataTable
              className='table-striped custom-filtered-table'
              pagination
              noDataComponent={<div style={{margin: '25px 0px'}}>Gösterilecek kayıt yok</div>}
              customStyles={{
                rows: {
                  style: {
                    cursor: 'pointer',
                    backgroundColor: 'var(--table-bg)',
                    '&:hover': {
                      backgroundColor: '#f3f3f3',
                    },
                  },
                },
              }}
              onRowClicked={(row) => {}}
              columns={columns}
              data={user}
            />
          </>
        )}
      </div>
    </div>
  )
}

export default DeviceLogsTable
