import axios from './index'

const getAll = async (params) => {
  try {
    const res = await axios.get('/dashboard', {
      params,
    })

    return res?.data?.value
  } catch (error) {}
}

const create = async (data) => {
  const res = await axios.post(`/dashboard`, data)
  return res?.data
}

const update = async (id, data) => {
  const res = await axios.put(`/dashboard/${id}`, data)
  return res?.data
}

const get = async (id) => {
  const res = await axios.get(`/dashboard/${id}`)
  return res?.data?.value
}

const remove = async (id) => {
  const res = await axios.delete(`/dashboard/${id}`)
  return res?.data
}
const getBarLineValues = async (id) => {
  const res = await axios.get(
    `https://dataprogressor.photonce.com/api/Dashboard/GetDashboardValuesById?id=${id}`
  )
  return res?.data
}

const getDefaultDashboard = async () => {
  const res = await axios.get(`/dashboard/getDefault`)
  return res?.data?.value
}

const setDefaultDashboard = async (id) => {
  const res = await axios.post(`/dashboard/makeDefault/${id}`)
  return res?.data
}

const DashboardService = {
  create,
  get,
  getDefaultDashboard,
  getAll,
  setDefaultDashboard,
  getBarLineValues,
  update,
  remove,
}

export default DashboardService
