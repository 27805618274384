/* eslint-disable jsx-a11y/anchor-is-valid */
import {PageTitle} from '../../_metronic/layout/core'
import {useIntl} from 'react-intl'
import ReportManagementTable from '../../components/ReportManagement/ReportManagementTable'

const ReportManagementPage = () => {
  const intl = useIntl()

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.REPORTMANAGEMENT'})}</PageTitle>
    <ReportManagementTable/>
    </>
  )
}

export {ReportManagementPage}
