import axios from './index'

const getAll = async (params) => {
  try {
    const res = await axios.get('/PackageDevicesBrand', {
      params,
    })

    return res?.data?.value
  } catch (error) {}
}

const getModelWithBrandId = async (params) => {
  try {
    const res = await axios.get('/PackageDevicesBrandModel/GetModelWithBrandId', {
      params,
    })

    return res?.data?.value
  } catch (error) {}
}
const getPackageDeviceByModelId = async (params) => {
  try {
    const res = await axios.get('/PackageDevice/GetDeviceWithModelId', {
      params,
    })

    return res?.data?.value
  } catch (error) {}
}
 
const BrandService = {
  getAll,
  getModelWithBrandId,
  getPackageDeviceByModelId
}

export default BrandService
