import Swal from 'sweetalert2'
import {useNavigate} from 'react-router-dom'
import {useQuery} from 'react-query'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {useIntl} from 'react-intl'
import DeviceService from '../../service/device'
import TagService from '../../service/tag'
import RentalService from '../../service/rental'

const AddNewRentalSetupPage = () => {
  const navigate = useNavigate()
  const intl = useIntl()

  const {data: rentalMachine} = useQuery(['rentalmachine'], async () =>
    RentalService.getRentalMachine({
      page: 1,
      perPage: 1000,
    })
  )
  const {data: edgeDevices} = useQuery(['edgedevices'], async () =>
    DeviceService.getAllEdgeDevices({
      page: 1,
      perPage: 1000,
    })
  )

  const {data: rentalCompanies} = useQuery(['rentalcompany'], async () =>
    RentalService.getRentalCompany({
      page: 1,
      perPage: 1000,
    })
  )
  console.log(rentalMachine)
  console.log(rentalCompanies)
  const formik = useFormik({
    initialValues: {
      edgeDeviceId: '',
      rentalCompanyId: '',
      startDate: '',
      endTime: '',
    },
    // isInitialValid: false,
    // validationSchema: Yup.object().shape({
    //   edgeDeviceId: Yup.number().required(intl.formatMessage({id: 'REQUIRED'})),
    //   runTagId: Yup.number().required(intl.formatMessage({id: 'REQUIRED'})),
    //   errorTagId: Yup.number().required(intl.formatMessage({id: 'REQUIRED'})),
    //   stopTagId: Yup.number().required(intl.formatMessage({id: 'REQUIRED'})),
    //   stopFunction: Yup.number().required(intl.formatMessage({id: 'REQUIRED'})),
    // }),
    onSubmit: async (values) => {
      console.log(values)
      await RentalService.createRental(values)
      Swal.fire({
        type: 'success',
        title: intl.formatMessage({id: 'SUCCESS'}),
        showConfirmButton: false,
        timer: 1500,
        icon: 'success',
      })
      navigate('/rental')
    },
  })

  return (
    <div className='card'>
      <div className='card-body p-4'>
        <button onClick={() => navigate('/rental')} className='btn btn-primary'>
          {intl.formatMessage({id: 'AUTH.GENERAL.BACK_BUTTON'})}
        </button>

        <div id='kt_account_profile_details' className='collapse show'>
          <form noValidate className='form'>
            <div className='card-body p-9'>
              <div className='row mb-6'>
                <label className='col-lg-3 col-form-label fw-bold fs-6'>
                  {intl.formatMessage({id: 'EDGEDEVICE'})}
                </label>
                <div className='col-lg-9'>
                  <div className='row'>
                    <div className='col-lg-12 fv-row'>
                      <select
                        name='edgeDeviceId'
                        className='form-select form-control-solid'
                        onChange={(e) => {
                          formik.setFieldValue('edgeDeviceId', parseInt(e.target.value))
                        }}
                      >
                        <option>{intl.formatMessage({id: 'CHOOSE'})}</option>
                        {rentalMachine?.items?.map((data) => (
                          <option value={data.edgeDeviceId} key={data.name}>
                            {
                              edgeDevices?.items?.find((item) => item.id === data.edgeDeviceId)
                                ?.name
                            }
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-3 col-form-label fw-bold fs-6'>
                  {intl.formatMessage({id: 'RENTINGCOMPANY'})}
                </label>
                <div className='col-lg-9'>
                  <div className='row'>
                    <div className='col-lg-12 fv-row'>
                      <select
                        name='rentalCompanyId'
                        className='form-select form-control-solid'
                        onChange={(e) => {
                          formik.setFieldValue('rentalCompanyId', parseInt(e.target.value))
                        }}
                      >
                        <option>{intl.formatMessage({id: 'CHOOSE'})}</option>
                        {rentalCompanies?.items?.map((data) => (
                          <option value={data.id} key={data.name}>
                            {data.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-3 col-form-label fw-bold fs-6'>
                  {intl.formatMessage({id: 'STARTDATE'})}
                </label>
                <div className='col-lg-9'>
                  <input
                    type='date'
                    className='form-control form-control-lg form-control-solid'
                    name='startDate'
                    value={formik.values.startDate}
                    onChange={formik.handleChange}
                  />
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-3 col-form-label fw-bold fs-6'>
                  {intl.formatMessage({id: 'ENDTIME'})}
                </label>
                <div className='col-lg-9'>
                  <input
                    type='date'
                    className='form-control form-control-lg form-control-solid'
                    name='endTime'
                    value={formik.values.endTime}
                    onChange={formik.handleChange}
                  />
                </div>
              </div>
            </div>

            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <button
                disabled={!formik.isValid || formik.isSubmitting}
                type='submit'
                onClick={formik.handleSubmit}
                className='btn  btn-primary '
              >
                {intl.formatMessage({id: 'SAVE'})}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export {AddNewRentalSetupPage}
