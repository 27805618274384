/* eslint-disable jsx-a11y/anchor-is-valid */
import {PageTitle} from '../../_metronic/layout/core'
import {useIntl} from 'react-intl'
import SystemLogsTable from '../../components/SystemLogs/SystemLogsTable'

const SystemLogsPage = () => {
  const intl = useIntl()

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.SYSTEMLOGS'})}</PageTitle>
    <SystemLogsTable/>
    </>
  )
}

export {SystemLogsPage}
