import {useNavigate, useParams} from 'react-router-dom'
import {useEffect} from 'react'
import {useQuery} from 'react-query'
import DeviceService from '../../service/device'
import {Loader} from '../../components/Loader'
import Swal from 'sweetalert2'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import AccountService from '../../service/account'
import {useIntl} from 'react-intl'

const EditUserPage = () => {
  const navigate = useNavigate()
  const params = useParams()
  const intl = useIntl()
  // const validationSchema = Yup.object().shape({
  //   name: Yup.string().nullable(),
  //   description: Yup.string().nullable(),
  //   sourceDataKeyValue: Yup.object().nullable(),
  // })
  const formik = useFormik({
    initialValues: {
      name: '',
      surname: '',
      email: '',
      phoneNumber: '',
      userName: '',
      // password: '',
      role: '',
    },
    // isInitialValid: true,
    // validationSchema: validationSchema,
    onSubmit: async (values) => {
      const roleArray = [values.role]
      values.role = roleArray
      values.isActive = true
      values.lockoutEnabled = true
      var res = await AccountService.update(params.id, {...values})
      console.log(res)
      if (res.status === 200) {
        Swal.fire({
          type: 'success',
          title: intl.formatMessage({id: 'SUCCESS'}),
          showConfirmButton: false,
          timer: 1500,
          icon: 'success',
        })
        navigate('/account-management')
      } else {
        Swal.fire({
          type: 'error',
          title: res.data.error.message,
          text: res.data.error.details,
          showConfirmButton: true,
          timer: 5000,
          icon: 'error',
        })
      }
    },
  })

  const {data, isLoading} = useQuery(['user', params.id], async () => AccountService.get(params.id))

  const {data: roles} = useQuery(['roles'], async () => AccountService.getRoles())

  // useEffect(() => {
  //   if (!!deviceDetails?.sourceDataKeys) {
  //     const clonedDataTypes = {}
  //     deviceDetails.sourceDataKeys.forEach((item) => {
  //       clonedDataTypes[item.id] = {
  //         sourceDataKeyId: item.id,
  //         value: item.sourceDataValue,
  //         sourceDataId: item.sourceDataId,
  //       }
  //     })
  //     console.log(clonedDataTypes)
  //     formik.setFieldValue('sourceDataKeyValue', clonedDataTypes)
  //   } else {
  //     formik.setFieldValue('sourceDataKeyValue', {})
  //   }
  // }, [sourceDataKeys, deviceDetails])

  useEffect(() => {
    if (data) {
      formik.setValues({
        name: data.name,
        surname: data.surname,
        email: data.email,
        phoneNumber: data.phoneNumber,
        userName: data.userName,
        // password: data.password,
        role: data.role,
      })
    }
  }, [data, roles])

  const buttonIsDisabled = () => {
    if (formik.isSubmitting) {
      return true
    }

    // if (!sourceDataKeys?.length || sourceDataKeys?.length === 0) {
    //   return true
    // }

    return false
  }

  if (isLoading) {
    return <Loader />
  }

  return (
    <div className='card'>
      <div className='card-body p-4'>
        <button onClick={() => navigate('/account-management')} className='btn btn-primary'>
          {intl.formatMessage({id: 'AUTH.GENERAL.BACK_BUTTON'})}
        </button>

        <div id='kt_account_profile_details' className='collapse show'>
          <div className='card-body p-9'>
            <div>
              <form noValidate className='form'>
                <div className='card-body p-9'>
                  <div className='row mb-6'>
                    <label className='col-lg-3 col-form-label required fw-bold fs-6'>
                      {' '}
                      {intl.formatMessage({id: 'NAME'})}{' '}
                    </label>

                    <div className='col-lg-9'>
                      <div className='row'>
                        <div className='col-lg-12 fv-row'>
                          <input
                            type='text'
                            className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                            placeholder={intl.formatMessage({id: 'NAME'})}
                            name='name'
                            value={formik.values.name}
                            onChange={formik.handleChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-lg-3 col-form-label required fw-bold fs-6'>
                      {' '}
                      {intl.formatMessage({id: 'SURNAME'})}
                    </label>

                    <div className='col-lg-9'>
                      <div className='row'>
                        <div className='col-lg-12 fv-row'>
                          <input
                            type='text'
                            className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                            placeholder={intl.formatMessage({id: 'SURNAME'})}
                            name='surname'
                            value={formik.values.surname}
                            onChange={formik.handleChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-lg-3 col-form-label required fw-bold fs-6'>
                      {intl.formatMessage({id: 'CONTACT_MAIL'})}
                    </label>

                    <div className='col-lg-9'>
                      <div className='row'>
                        <div className='col-lg-12 fv-row'>
                          <input
                            type='text'
                            className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                            placeholder={intl.formatMessage({id: 'CONTACT_MAIL'})}
                            name='email'
                            value={formik.values.email}
                            onChange={formik.handleChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-lg-3 col-form-label required fw-bold fs-6'>
                      {intl.formatMessage({id: 'PHONE'})}
                    </label>

                    <div className='col-lg-9'>
                      <div className='row'>
                        <div className='col-lg-12 fv-row'>
                          <input
                            type='text'
                            className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                            placeholder={intl.formatMessage({id: 'PHONE'})}
                            name='phoneNumber'
                            value={formik.values.phoneNumber}
                            onChange={formik.handleChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-lg-3 col-form-label required fw-bold fs-6'>
                      {intl.formatMessage({id: 'USERNAME'})}
                    </label>

                    <div className='col-lg-9'>
                      <div className='row'>
                        <div className='col-lg-12 fv-row'>
                          <input
                            type='text'
                            className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                            placeholder={intl.formatMessage({id: 'USERNAME'})}
                            name='userName'
                            value={formik.values.userName}
                            onChange={formik.handleChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className='row mb-6'>
                    <label className='col-lg-3 col-form-label required fw-bold fs-6'>Şifre</label>

                    <div className='col-lg-9'>
                      <div className='row'>
                        <div className='col-lg-12 fv-row'>
                          <input
                            type='text'
                            className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                            placeholder='Şifre'
                            name='password'
                            value={formik.values.password}
                            onChange={formik.handleChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div> */}
                  <div className='row mb-6'>
                    <label className='col-lg-3 col-form-label fw-bold fs-6'>
                      {' '}
                      {intl.formatMessage({id: 'ROLE'})}
                    </label>

                    <div className='col-lg-9'>
                      <div className='row'>
                        <div className='col-lg-12 fv-row'>
                          <select
                            value={formik.values.role}
                            onChange={formik.handleChange}
                            name='role'
                            className='form-select form-control-solid'
                          >
                            <option selected>{intl.formatMessage({id: 'CHOOSE'})}</option>
                            {roles?.items?.map((item, key) => (
                              <option value={item.name} key={key}>
                                {item.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='card-footer d-flex justify-content-end py-6 px-9'>
                  <button
                    disabled={!formik.isValid || formik.isSubmitting}
                    type='submit'
                    onClick={formik.handleSubmit}
                    className='btn  btn-primary '
                  >
                    {intl.formatMessage({id: 'SAVE'})}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {EditUserPage}
