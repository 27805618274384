import {lazy, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {EdgeDeviceManagementPage} from '../pages/EdgeDeviceManagementPage'
import {CreateEdgeDevicePage} from '../pages/CreateEdgeDevicePage'
import {AccountManagementPage} from '../pages/AccountManagementPage'
import {RoleManagementPage} from '../pages/RoleManagementPage'
import {SystemLogsPage} from '../pages/SystemLogsPage'
import {DeviceLogsPage} from '../pages/DeviceLogsPage'
import {DataManagementPage} from '../pages/DataManagementPage'
import {ReportManagementPage} from '../pages/ReportManagementPage'
import {FlowPage} from '../pages/FlowPage'
import {FlowListPage} from '../pages/FlowListPage'
import {AddNewDataPage} from '../pages/AddNewDataPage'
import {EditCategoryPage} from '../pages/EditCategoryPage'
import {CategoryPage} from '../pages/CategoryPage'
import {AddNewCategoryPage} from '../pages/AddNewCategoryPage'
import {DeviceManagementPage} from '../pages/DeviceManagementPage'
import {AddNewDevicePage} from '../pages/AddNewDevicePage'
import {EditDevicePage} from '../pages/EditDevicePage'
import {EditTagPage} from '../pages/EditTagPage'
import {AddFlowPage} from '../pages/AddFlowPage'
import {DashboardManagementPage} from '../pages/DashboardManagementPage'
import {DashboardManagementDetailPage} from '../pages/DashboardManagementDetailPage'
import {AddNewUserPage} from '../pages/AddNewUserPage'
import {EditUserPage} from '../pages/EditUserPage'
import {DefaultDashboardPage} from '../pages/DefaultDashboardPage'
import {RentalMachinePage} from '../pages/RentalMachinePage'
import {AddNewRentalMachinePage} from '../pages/AddNewRentalMachinePage'
import {EditRentalMachinePage} from '../pages/EditRentalMachinePage'
import RentalCompanyManagementTable from '../../components/RentalCompany/RentalCompanyManagementTable'
import {AddNewRentalCompanyPage} from '../pages/AddNewRentalCompanyPage'
import {EditRentalCompanyPage} from '../pages/EditRentalCompanyPage'
import RentalSetupManagementTable from '../../components/RentalSetup/RentalSetupManagementTable'
import {AddNewRentalSetupPage} from '../pages/AddNewRentalSetupPage'
import {EditRentalSetupPage} from '../pages/EditRentalSetupPage'

const PrivateRoutes = () => {
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard/management' />} />
        {/* Pages */}
        <Route path='index' element={<DefaultDashboardPage />} />
        <Route path='dashboard' element={<DefaultDashboardPage />} />
        <Route path='edge-device-management' element={<EdgeDeviceManagementPage />} />
        <Route path='edge-device-management/new' element={<CreateEdgeDevicePage />} />
        <Route path='rental' element={<RentalSetupManagementTable />} />
        <Route path='rental/add' element={<AddNewRentalSetupPage />} />
        <Route path='rental/:id' element={<EditRentalSetupPage />} />
        <Route path='rental-machine' element={<RentalMachinePage />} />
        <Route path='rental-machine/add' element={<AddNewRentalMachinePage />} />
        <Route path='rental-machine/:id' element={<EditRentalMachinePage />} />
        <Route path='rental-company' element={<RentalCompanyManagementTable />} />
        <Route path='rental-company/add' element={<AddNewRentalCompanyPage />} />
        <Route path='rental-company/:id' element={<EditRentalCompanyPage />} />
        <Route path='category/:id' element={<EditCategoryPage />} />
        <Route path='category' element={<CategoryPage />} />
        <Route path='category/add' element={<AddNewCategoryPage />} />

        <Route path='device-management' element={<DeviceManagementPage />} />
        <Route path='device-management/add' element={<AddNewDevicePage />} />
        <Route path='device-management/:id' element={<EditDevicePage />} />

        <Route path='report-management' element={<ReportManagementPage />} />
        <Route path='data-management' element={<DataManagementPage />} />
        <Route path='data-management/add' element={<AddNewDataPage />} />
        <Route path='data-management/:id' element={<EditTagPage />} />

        <Route path='add-new-data' element={<AddNewDataPage />} />
        <Route path='account-management' element={<AccountManagementPage />} />
        <Route path='account-management/add' element={<AddNewUserPage />} />
        <Route path='account-management/:id' element={<EditUserPage />} />
        <Route path='role-management' element={<RoleManagementPage />} />
        <Route path='system-logs' element={<SystemLogsPage />} />
        <Route path='device-logs' element={<DeviceLogsPage />} />
        <Route path='dashboard-management/' element={<DashboardManagementPage />} />
        <Route path='dashboard-management/:id' element={<DashboardManagementDetailPage />} />
        <Route path='flows' element={<FlowListPage />} />
        <Route path='flows/add-flow' element={<AddFlowPage />} />
        <Route path='flows/:id' element={<FlowPage />} />

        {/* Lazy Modules */}
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />

        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      0: baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
