/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../helpers'
import clsx from 'clsx'
import { useIntl } from 'react-intl'

const HeaderUserMenu: FC = () => {
  const intl = useIntl()

  return (
    <>
      <div
        className={clsx('cursor-pointer symbol', 'symbol-35px')}
        data-kt-menu-trigger={"{default: 'click', lg: 'hover'}"}
        data-kt-menu-attach='parent'
        data-kt-menu-placement={'bottom-end'}
      >
        <img src={toAbsoluteUrl('/media/avatars/user.png')} alt='' />
      </div>
      {/* begin::Menu toggle */}

      {/* begin::Menu */}
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-title-gray-700 menu-icon-muted menu-active-bg menu-state-primary fw-semibold py-4 fs-base w-175px'
        data-kt-menu='true'
      >
        <div className='menu-item px-5 my-1'>
          <Link to='/crafted/account/settings' className='menu-link px-5'>
            {intl.formatMessage({id: 'ACCOUNTSETTINGS'})}
          </Link>
        </div>

        <div className='menu-item px-5'>
          <a
            onClick={() => {
              localStorage.removeItem('user')
              localStorage.removeItem('userToken')
              localStorage.removeItem('refreshToken')
              window.location.href = '/auth'
            }}
            className='menu-link px-5'
          >
            {intl.formatMessage({id: 'LOGOUT'})}
          </a>
        </div>
      </div>
      {/* end::Menu */}
    </>
  )
}

export {HeaderUserMenu}
