import {Handle, Position} from 'reactflow'
import './nodes.module.css'
import { useIntl } from 'react-intl'

export default function ConditionNode(props) {
  const intl = useIntl()
  return (
    <>
      <Handle
        type='target'
        isConnectableStart={false}
        position={Position.Left}
        style={{
          width: '15px',
          height: '15px',
          position: 'absolute',
          right: '-5px',
          zIndex: 10,
          background: '#1e88e5',
          border: '2px solid #000',
        }}
      />

      <Handle
        type='source'
        id='source-1'
        isConnectableEnd={false}
        position={Position.Right}
        style={{
          width: '15px',
          height: '15px',
          position: 'absolute',
          top: '10px',
          zIndex: 10,
          background: '#1ee564',
          border: '2px solid #1e88e5',
        }}
      />

      <Handle
        type='source'
        id='source-2'
        isConnectableEnd={false}
        position={Position.Right}
        style={{
          width: '15px',
          height: '15px',
          position: 'absolute',
          top: '65px',
          zIndex: 10,
          background: '#e51e42',
          border: '2px solid #1e88e5',
        }}
      />

      <div
        style={{
          padding: '10px',
          border: `3px solid ${!props.selected ? '#bebebe' : '#1e88e5'}`,
          borderRadius: '20px',
          width: '200px',
          height: '75px',
          backgroundColor: 'white',
          position: 'relative',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <img src="/images/flow-chart.png" width={45} />
        <strong>{props.data.label ?? intl.formatMessage({id:'CONDITION'}) }</strong>
      </div>
    </>
  )
}
