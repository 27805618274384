import DataTable from 'react-data-table-component-with-filter'
import {useNavigate} from 'react-router-dom'
import {useIntl} from 'react-intl'
import Swal from 'sweetalert2'
import {Loader} from '../Loader'
import {useMemo, useState} from 'react'
const ReportManagementTable = () => {
  const navigate = useNavigate()
  const intl = useIntl()
  const tableIsLoading = false

  const user = [
    {
      id: '6f988de5-275a-49b5-936f-6253455420fb',
      reportName: 'reportName',
      creationTime: '02/04/2023 13:46:34',
    },
    {
      id: '6f988de5-275a-49b5-936f-6253455420fb',
      reportName: 'reportName',
      creationTime: '02/04/2023 13:46:34',
    },
    {
      id: '6f988de5-275a-49b5-936f-6253455420fb',
      reportName: 'reportName',
      creationTime: '02/04/2023 13:46:34',
    },
    {
      id: '6f988de5-275a-49b5-936f-6253455420fb',
      reportName: 'reportName',
      creationTime: '02/04/2023 13:46:34',
    },
    {
      id: '6f988de5-275a-49b5-936f-6253455420fb',
      reportName: 'reportName',
      creationTime: '02/04/2023 13:46:34',
    },
    {
      id: '6f988de5-275a-49b5-936f-6253455420fb',
      reportName: 'reportName',
      creationTime: '02/04/2023 13:46:34',
    },
  ]

  const columns = [
    {
      name: 'Report Name',
      selector: (row) => row.reportName,
      sortable: true,
      filterable: true,
    },
    {
      name: 'Creation Time',
      selector: (row) => row.creationTime,
      sortable: true,
      filterable: true,
    },
    {
      name: 'Actions',
      sortable: false,
      filterable: false,
      cell: (row) => {
        return (
          <div>
            {' '}
            <span
              className='btn  btn-primary '
              data-bs-toggle='modal'
              data-bs-target='#editUserModal'
            >
              <i className='fa fa-pencil mx-2 '></i>
            </span>{' '}
            <span
              onClick={() => {
                Swal.fire({
                  html: 'Are you sure to delete <strong>' + row?.name + '</strong> ?',
                  icon: 'warning',
                  confirmButtonText: 'Yes',
                  showCancelButton: true,
                  cancelButtonText: 'No',
                }).then(async (result) => {
                  if (result.isConfirmed) {
                    Swal.fire(row.name + ' Removed successfully!', '', 'success')
                  }
                })
              }}
              className='btn  btn-secondary '
            >
              <i className='fa fa-close mx-2 '></i>
            </span>
          </div>
        )
      },
    },
  ]
  return (
    <div className='card'>
      <div className='card-body p-4'>
        {tableIsLoading ? (
          <div className='text-center mt-2 mb-2'>
            <Loader />
          </div>
        ) : (
          <>
            <div className='table-page-header p-5 my-3 pull-right '>
              <div className='add-new-user  pull-right '>
                <span className='btn  btn-primary '>
                  <i className='fa fa-plus mx-2 '></i> Add New Report
                </span>
              </div>
            </div>

            <DataTable
              className='table-striped custom-filtered-table'
              pagination
              editable
              noDataComponent={<div style={{margin: '25px 0px'}}>Gösterilecek kayıt yok</div>}
              customStyles={{
                rows: {
                  style: {
                    cursor: 'pointer',
                    backgroundColor: 'var(--table-bg)',
                    '&:hover': {
                      backgroundColor: '#f3f3f3',
                    },
                  },
                },
              }}
              onRowClicked={(row) => {}}
              columns={columns}
              data={user}
            />
          </>
        )}
      </div>
    </div>
  )
}

export default ReportManagementTable
