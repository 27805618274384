import React from 'react'
import {v4 as uuidv4} from 'uuid'
import {useDrag} from 'react-dnd'

const TrueFalse = () => {
  const [{isDragging}, drag] = useDrag({
    type: 'boolean',
    item: {
      selectedTag: null,
      id: uuidv4(),
      dataGrid: {x: 0, y: 0, w: 3, h: 4, minW: 3, minH: 4},
      type: 'boolean',
      data: {
        boolean: {
          type: 'boolean',
          data: true,
        },
      },
    },

    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  })

  return (
    <div
      ref={drag}
      style={{
        opacity: isDragging ? 0.5 : 1,
        cursor: 'move',
        // border: '1px solid black',
        width:'100%',
        padding: 15,
        borderRadius: 4,
        marginTop: 10,
      }}
    >
      <div className='text-center'>
        <h6>Boolean</h6>
        <img alt='' src='/images/bool.png' width='75' height={50} />
      </div>
    </div>
  )
}

export default TrueFalse
