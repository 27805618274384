import React from 'react'
import {v4 as uuidv4} from 'uuid'
import {useDrag} from 'react-dnd'
import {useIntl} from 'react-intl'

const Line = () => {
  const intl = useIntl()
  const [{isDragging}, drag] = useDrag({
    type: 'line',
    item: {
      selectedTags: [],
      id: uuidv4(),
      type: 'line',
      dataGrid: {x: 0, y: 0, w: 4, h: 6, minW: 4, minH: 6,maxH:6},
      data: {
        line: {
          options: {
            tooltip: {
              trigger: 'axis'
            },
            legend: {
              data: ['Email', 'Union Ads', 'Video Ads', 'Direct', 'Search Engine']
            },
            grid: {
              left: '3%',
              right: '4%',
              bottom: '3%',
              containLabel: true
            },
            toolbox: {
              feature: {
                saveAsImage: {}
              }
            },
            xAxis: {
              type: 'category',
              boundaryGap: false,
              data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
            },
            yAxis: {
              type: 'value'
            },
            series: [
              {
                name: 'Email',
                type: 'line',
                stack: 'Total',
                data: [120, 132, 101, 134, 90, 230, 210]
              },
              {
                name: 'Union Ads',
                type: 'line',
                stack: 'Total',
                data: [220, 182, 191, 234, 290, 330, 310]
              },
              {
                name: 'Video Ads',
                type: 'line',
                stack: 'Total',
                data: [150, 232, 201, 154, 190, 330, 410]
              },
              {
                name: 'Direct',
                type: 'line',
                stack: 'Total',
                data: [320, 332, 301, 334, 390, 330, 320]
              },
              {
                name: 'Search Engine',
                type: 'line',
                stack: 'Total',
                data: [820, 932, 901, 934, 1290, 1330, 1320]
              }
            ]
          },
        },
      },
    },

    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  })

  return (
    <div
      ref={drag}
      style={{
        opacity: isDragging ? 0.5 : 1,
        cursor: 'move',
        // border: '1px solid black',
        width: '100%',
        padding: 2,
        borderRadius: 4,
        marginTop: 20,
      }}
    >
      <div className='text-center'>
        <h6>{intl.formatMessage({id:'LINECHART'})}</h6>
        <img alt='' src='/images/line.png' width='75' height={50} />
      </div>
    </div>
  )
}

export default Line
