import {useNavigate, useParams} from 'react-router-dom'
import {useEffect, useState} from 'react'
import {useQuery} from 'react-query'
import DeviceService from '../../service/device'
import {Loader} from '../../components/Loader'
import SourceTypeService from '../../service/sourceType'
import Swal from 'sweetalert2'
import {useFormik} from 'formik'
import {renderSourceTypeField} from '../../utils'
import RegexParser from 'regex-parser'
import {useIntl} from 'react-intl'

const EditDevicePage = () => {
  const navigate = useNavigate()
  const params = useParams()
  const intl = useIntl()
  // const validationSchema = Yup.object().shape({
  //   name: Yup.string().nullable(),
  //   description: Yup.string().nullable(),
  //   sourceDataKeyValue: Yup.object().nullable(),
  // })
  const [dataTypesLoading, setDataTypesLoading] = useState(true)
  const formik = useFormik({
    initialValues: {
      name: '',
      description: '',
      sourceDataKeyValue: {},
    },
    // isInitialValid: true,
    // validationSchema: validationSchema,
    onSubmit: async (values) => {
      const sourceDataKeyValueObject = Object.values(values.sourceDataKeyValue)
      const sourceDataKeyValue = []

      await DeviceService.update(params.id, {...values, sourceDataKeyValue})
      sourceDataKeyValueObject.map(async (item) => {
        if (item.value === undefined) return
        await DeviceService.updateDeviceSourceData(item.sourceDataId, {
          sourceDataKeyId: item.sourceDataKeyId,
          value: item.value,
          sourceTypeId: values.sourceTypeId,
          deviceId: Number(params.id),
          tenantId: 0,
        })
      })
      Swal.fire({
        type: 'success',
        title: 'Başarılı',
        text: 'Cihaz başarıyla güncellendi.',
        showConfirmButton: false,
        timer: 1500,
        icon: 'success',
      })
      navigate('/device-management')
    },
  })

  const {data, isLoading} = useQuery(
    ['category', params.id],
    async () => DeviceService.get(params.id),
    {
      cacheTime: 0,
    }
  )
  const {data: deviceDetails, isLoading: isDeviceDetailsLoading} = useQuery(
    ['deviceDetails', params.id],
    async () => DeviceService.getDeviceDetailById(params.id),
    {
      cacheTime: 0,
    }
  )

  const [sourceDataKeys, setSourceDataKeys] = useState([])
  const [isSourceDataKeysLoading, setIsSourceDataKeysLoading] = useState(true)

  useEffect(() => {
    setIsSourceDataKeysLoading(true)
    SourceTypeService.getSourceDataKeysWithSourceTypeId(formik.values.sourceTypeId)
      .then((response) => {
        setSourceDataKeys(response)
      })
      .finally(() => {
        setIsSourceDataKeysLoading(false)
      })
  }, [formik.values.sourceTypeId])

  useEffect(() => {
    if (!!deviceDetails?.sourceDataKeys) {
      setDataTypesLoading(true)
      const clonedDataTypes = {}
      deviceDetails.sourceDataKeys.forEach((item) => {
        clonedDataTypes[item.id] = {
          sourceDataKeyId: item.id,
          value: item.sourceDataValue,
          sourceDataId: item.sourceDataId,
        }
      })

      setTimeout(() => {
        formik.setFieldValue('sourceDataKeyValue', clonedDataTypes)
        setDataTypesLoading(false)
      }, 500)
    } else {
      formik.setFieldValue('sourceDataKeyValue', {})
    }
  }, [sourceDataKeys, deviceDetails])

  useEffect(() => {
    if (data) {
      formik.setValues({
        name: data.name,
        description: data.description,
        sourceTypeId: data.sourceTypeId,
        sourceDataKeyValue: sourceDataKeys,
        edgeDeviceId: data.edgeDeviceId,
      })
    }
  }, [data, sourceDataKeys])

  const buttonIsDisabled = () => {
    if (isSourceDataKeysLoading || formik.isSubmitting) {
      return true
    }

    let anyNotMatch = false

    sourceDataKeys?.forEach((dataKey) => {
      if (!dataKey.regex) {
        anyNotMatch = false
        return
      }
      const regex = RegexParser(dataKey.regex)
      if (!formik.values.sourceDataKeyValue[dataKey?.id]?.value?.match(regex)) {
        anyNotMatch = true
      }
    })

    // if (!sourceDataKeys?.length || sourceDataKeys?.length === 0) {
    //   return true
    // }

    return anyNotMatch
  }

  if (isLoading || dataTypesLoading) {
    return <Loader />
  }

  return (
    <div className='card'>
      <div className='card-body p-4'>
        <button onClick={() => navigate('/device-management')} className='btn btn-primary'>
          {intl.formatMessage({id: 'AUTH.GENERAL.BACK_BUTTON'})}
        </button>

        <div id='kt_account_profile_details' className='collapse show'>
          <div className='card-body p-9'>
            <div>
              <div className='row mb-6'>
                <label className='col-lg-3 col-form-label fw-bold fs-6'>
                  {' '}
                  {intl.formatMessage({id: 'CONNECTIONTYPE'})}{' '}
                </label>
                <div className='col-lg-9'>
                  <div className='row'>
                    <div className='col-lg-12 fv-row'>
                      <input
                        autoComplete='off'
                        type='text'
                        disabled
                        className='form-control'
                        value={data?.sourceType?.name}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-3 col-form-label required fw-bold fs-6'>
                  {intl.formatMessage({id: 'NAME'})}
                </label>
                <div className='col-lg-9'>
                  <div className='row'>
                    <div className='col-lg-12 fv-row'>
                      <input
                        autoComplete='off'
                        type='text'
                        className='form-control form-control-lg  mb-3 mb-lg-0'
                        placeholder={intl.formatMessage({id: 'NAME'})}
                        required
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        name='name'
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-3 col-form-label fw-bold fs-6'>
                  {intl.formatMessage({id: 'DESCRIPTION'})}
                </label>
                <div className='col-lg-9'>
                  <div className='row'>
                    <div className='col-lg-12 fv-row'>
                      <textarea
                        type='text'
                        className='form-control form-control-lg  mb-3 mb-lg-0'
                        placeholder={intl.formatMessage({id: 'DESCRIPTION'})}
                        name='description'
                        value={formik.values.description}
                        onChange={formik.handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {(isDeviceDetailsLoading || isSourceDataKeysLoading) && <Loader />}
              {sourceDataKeys?.map((dataKey, index) => {
                return (
                  <div className='row mb-6' key={index}>
                    <label className='col-lg-3 col-form-label required fw-bold fs-6'>
                      {dataKey?.name}
                    </label>
                    <div className='col-lg-9'>
                      <div className='row'>
                        <div className='col-lg-12 fv-row'>
                          {renderSourceTypeField(dataKey, formik, deviceDetails)}
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })}

              <div className='card-footer d-flex justify-content-end py-6 px-9'>
                <button
                  disabled={buttonIsDisabled()}
                  onClick={formik.handleSubmit}
                  type='button'
                  className='btn  btn-primary'
                >
                  {intl.formatMessage({id: 'SAVE'})}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {EditDevicePage}
