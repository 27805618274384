import {Handle, Position} from 'reactflow'
import './nodes.module.css'
import {useIntl} from 'react-intl'

export default function OutputNode(props) {
  const intl = useIntl()

  return (
    <>
      <Handle
        type='target'
        position={Position.Left}
        isConnectableStart={false}
        style={{
          width: '15px',
          height: '15px',
          position: 'absolute',
          right: '-5px',
          zIndex: 10,
          background: '#1e88e5',
          border: '2px solid #000',
        }}
      />

      <div
        style={{
          padding: '10px',
          border: `3px solid ${!props.selected ? '#bebebe' : '#1e88e5'}`,
          borderRadius: '20px',
          width: '200px',
          height: '75px',
          backgroundColor: 'white',
          position: 'relative',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {!!(props.data.icon || props?.data?.output?.inputValue?.label) && (
          <img
            src={`/images/${props.data.icon ?? props?.data?.output?.inputValue?.value + '.png'}`}
            width={45}
            style={{marginRight: 16}}
            alt=''
          />
        )}
        <strong>
          {props?.data?.label === intl.formatMessage({id: 'OUTPUT'}) && intl.formatMessage({id: 'OUTPUT'})}
          {(props?.data?.label || props?.data?.output?.inputValue?.value || '')?.toLowerCase()?.includes('sms') && intl.formatMessage({id: 'SENDSMS'})}
          {(props?.data?.label || props?.data?.output?.inputValue?.value || '')?.toLowerCase()?.includes('mail') && intl.formatMessage({id: 'SENDMAIL'})}
          {(props?.data?.label || props?.data?.output?.inputValue?.value || '')?.toLowerCase()?.includes('webhook') && intl.formatMessage({id: 'WEBHOOK'})}
        </strong>
      </div>
    </>
  )
}
