import Swal from 'sweetalert2'
import {useNavigate, useParams} from 'react-router-dom'
import {useEffect, useMemo, useState} from 'react'
import {useQuery} from 'react-query'
import DataTypeService from '../../service/dataType'
import DeviceService from '../../service/device'
import CategoryService from '../../service/category'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import TagService from '../../service/tag'
import {Loader} from '../../components/Loader'
import TreeList from '../../components/DataManagement/TreeList'
import {useIntl} from 'react-intl'

const EditTagPage = () => {
  const navigate = useNavigate()
  const params = useParams()
  const intl = useIntl()
  const formik = useFormik({
    initialValues: {
      deviceId: null,
      areaId: null,
      name: '',
      address: '',
      dataTypeId: null,
      tenantId: 0,
      registerOrder: 'true',
      edgeDeviceId: null,
      multiplier: null,
      function: 1,
      wordCount: 1,
    },
    isInitialValid: false,
    enableReinitialize: false,
    validationSchema: Yup.object().shape({
      deviceId: Yup.number().required('Device Id is required'),
      areaId: Yup.number().required('Area Id is required'),
      name: Yup.string().required('Name is required'),
      address: Yup.string().required('Name is required'),
      dataTypeId: Yup.number().required('Data Type is required'),
      tenantId: Yup.number().nullable(),
      registerOrder: Yup.string().nullable(),
      multiplier: Yup.number().required(),
      function: Yup.number().required(),
      wordCount: Yup.number().required(),
    }),
    onSubmit: async (values) => {
      await TagService.update(
        {
          ...values,
          registerOrder: values.registerOrder === 'true',
          function: parseInt(values.function),
          wordCount: parseInt(values.wordCount),
        },
        params.id
      )
      Swal.fire({
        type: 'success',
        title: 'Başarılı',
        text: 'Tag başarıyla oluşturuldu.',
        showConfirmButton: false,
        timer: 1500,
        icon: 'success',
      })
      navigate('/data-management')
    },
  })

  const {data, isLoading} = useQuery(['tag', params.id], async () => TagService.get(params.id))

  const {data: dataTypes} = useQuery(['dataTypes'], async () =>
    DataTypeService.getAll({
      page: 1,
      perPage: 1000,
    })
  )
  const {data: devices} = useQuery(
    ['devices', formik.values.edgeDeviceId],
    async () =>
      !!formik.values.edgeDeviceId &&
      DeviceService.getDeviceByEdgeDeviceId({
        id: formik.values.edgeDeviceId,
      })
  )
  const {data: edgeDevices} = useQuery(['edgedevices'], async () =>
    DeviceService.getAllEdgeDevices({
      page: 1,
      perPage: 1000,
    })
  )

  const {data: treeViewData, isLoading: isTreeViewLoading} = useQuery(
    'nested-categories',
    async () => CategoryService.getNestedCategories()
  )

  const [filteredTreeView, setFilteredTreeView] = useState([])

  useEffect(() => {
    if (data) {
      formik.setValues({
        areaId: data.areaId,
        name: data.name,
        address: data.address,
        dataTypeId: data.dataType?.id,
        tenantId: data.tenantId,
        registerOrder: data.registerOrder,
        edgeDeviceId: data.edgeDevice?.id,
        deviceId: data.device?.id,
        multiplier: data?.multiplier,
        function: data?.function,
        wordCount: data?.wordCount,
      })
    }
  }, [data])

  function findTopLevelData(dataArray, initialSelectedId) {
    if (!dataArray || dataArray.length === 0) {
      return null
    }
    for (let i = 0; i < dataArray.length; i++) {
      const topLevelData = dataArray[i]
      if (topLevelData && topLevelData.subDatas && topLevelData.subDatas.length > 0) {
        for (let j = 0; j < topLevelData.subDatas.length; j++) {
          const subData = topLevelData.subDatas[j]
          if (subData.id === initialSelectedId) {
            return topLevelData
          }
        }
      }
      const result = findTopLevelData(topLevelData.subDatas, initialSelectedId)
      if (result) {
        return topLevelData
      }
    }
    return null
  }

  useEffect(() => {
    if (!treeViewData) return
    const topLevelData = findTopLevelData(treeViewData, data?.areaId)
    const clonedTreeViewData = [...treeViewData]
    if (clonedTreeViewData.find((item) => item.id === topLevelData?.id)) {
      clonedTreeViewData.find((item) => item.id === topLevelData?.id).initialOpen = true
    }
    setFilteredTreeView(clonedTreeViewData)
  }, [treeViewData, data?.areaId])

  if (isLoading) {
    return <Loader />
  }

  return (
    <div className='card'>
      <div className='card-body p-4'>
        <button onClick={() => navigate(-1)} className='btn btn-primary'>
          {intl.formatMessage({id: 'AUTH.GENERAL.BACK_BUTTON'})}
        </button>
        <div id='kt_account_profile_details' className='collapse show'>
          <div className='card-body border-top p-9'>
            <div className='row'>
              <div className='col-4'>
                <div className='row'>
                  <div className={`col-md-12`}>
                    <label className='col-lg-3 col-form-label required fw-bold fs-6'>
                      {intl.formatMessage({id: 'CATEGORY'})}
                    </label>
                    {isTreeViewLoading && <Loader />}
                    {!isTreeViewLoading && (
                      <TreeList
                        initialSelectedItemId={data?.areaId}
                        formik={formik}
                        data={filteredTreeView}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className='col-8'>
                <div className='row mb-6'>
                  <label className='col-lg-3 col-form-label required fw-bold fs-6'>
                    {intl.formatMessage({id: 'EDGEDEVICE'})}
                  </label>

                  <div className='col-lg-9'>
                    <div className='row'>
                      <div className='col-lg-12 fv-row'>
                        <select
                          className='form-select'
                          onChange={(e) => {
                            formik.setFieldValue('edgeDeviceId', parseInt(e.target.value))
                          }}
                        >
                          <option value='' disabled selected>
                            {intl.formatMessage({id: 'CHOOSE'})}
                          </option>
                          {!!edgeDevices?.items &&
                            edgeDevices?.items?.map((item, index) => {
                              return (
                                <option
                                  selected={data?.edgeDevice?.id === item.id}
                                  value={item.id}
                                  key={index}
                                >
                                  {' '}
                                  {item.name}{' '}
                                </option>
                              )
                            })}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='row mb-6'>
                  <label className='col-lg-3 col-form-label required fw-bold fs-6'>
                    {' '}
                    {intl.formatMessage({id: 'DEVICE'})}{' '}
                  </label>

                  <div className='col-lg-9'>
                    <div className='row'>
                      <div className='col-lg-12 fv-row'>
                        <select
                          name='deviceId'
                          onChange={(e) => {
                            formik.setFieldValue('deviceId', parseInt(e.target.value))
                          }}
                          className='form-select '
                        >
                          <option value='' disabled>
                            {intl.formatMessage({id: 'CHOOSE'})}
                          </option>
                          {!!devices &&
                            devices?.map((item) => (
                              <option
                                selected={formik.values.deviceId === item.id}
                                key={item.id}
                                value={item.id}
                              >
                                {item.name}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-3 col-form-label required fw-bold fs-6'>
                    {intl.formatMessage({id: 'TAG'})} {intl.formatMessage({id: 'NAME'})}
                  </label>

                  <div className='col-lg-9'>
                    <div className='row'>
                      <div className='col-lg-12 fv-row'>
                        <input
                          name='name'
                          value={formik.values.name}
                          onChange={formik.handleChange}
                          type='text'
                          className='form-control form-control-lg  mb-3 mb-lg-0'
                          placeholder={
                            intl.formatMessage({id: 'TAG'}) + ' ' + intl.formatMessage({id: 'NAME'})
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-3 col-form-label required fw-bold fs-6'>
                    {intl.formatMessage({id: 'TAGADDRESS'})}
                  </label>

                  <div className='col-lg-9'>
                    <div className='row'>
                      <div className='col-lg-12 fv-row'>
                        <input
                          type='text'
                          className='form-control form-control-lg  mb-3 mb-lg-0'
                          placeholder={intl.formatMessage({id: 'TAGADDRESS'})}
                          name='address'
                          value={formik.values.address}
                          onChange={formik.handleChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-3 col-form-label required fw-bold fs-6'>
                    {' '}
                    {intl.formatMessage({id: 'DATATYPE'})}
                  </label>

                  <div className='col-lg-9'>
                    <div className='row'>
                      <div className='col-lg-12 fv-row'>
                        <select
                          name='dataTypeId'
                          value={formik.values.dataTypeId}
                          onChange={(e) => {
                            formik.setFieldValue('dataTypeId', parseInt(e.target.value))
                          }}
                          className='form-select '
                        >
                          <option value='' disabled selected>
                            {intl.formatMessage({id: 'CHOOSE'})}
                          </option>
                          {dataTypes?.items?.map((item) => (
                            <option
                              selected={formik?.values?.dataTypeId === item.id}
                              key={item.id}
                              value={item.id}
                            >
                              {item.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-3 col-form-label required fw-bold fs-6'>
                    {' '}
                    {intl.formatMessage({id: 'MULTIPLIER'})}
                  </label>

                  <div className='col-lg-9'>
                    <div className='row'>
                      <div className='col-lg-12 fv-row'>
                        <input
                          name='multiplier'
                          value={formik.values.multiplier}
                          onChange={formik.handleChange}
                          type='number'
                          min={0}
                          className='form-control form-control-lg  mb-3 mb-lg-0'
                          placeholder={intl.formatMessage({id: 'MULTIPLIER'})}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='row mb-6' style={{alignItems: 'center'}}>
                  <label className='col-lg-3 col-form-label fw-bold fs-6 required'>Fonksiyon</label>

                  <div className='col-lg-9'>
                    <div className='row'>
                      <div className='col-lg-12 fv-row'>
                        <select
                          value={formik.values.function}
                          onChange={(e) => {
                            formik.setFieldValue('function', e.target.value)
                          }}
                          className='form-select '
                        >
                          <option selected={formik.values.function === 1} value={1}>
                            ReadCoils
                          </option>
                          <option selected={formik.values.function === 2} value={2}>
                            ReadDiscreteInputs
                          </option>
                          <option selected={formik.values.function === 3} value={3}>
                            ReadHoldingRegisters
                          </option>
                          <option selected={formik.values.function === 4} value={4}>
                            ReadInputRegisters
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='row mb-6' style={{alignItems: 'center'}}>
                  <label className='col-lg-3 col-form-label fw-bold fs-6 required'>
                    Alan Sayısı
                  </label>

                  <div className='col-lg-9'>
                    <div className='row'>
                      <div className='col-lg-12 fv-row'>
                        <input
                          type='number'
                          min={0}
                          className='form-control form-control-lg  mb-3 mb-lg-0'
                          placeholder='Alan Sayısı'
                          name='wordCount'
                          value={formik.values.wordCount}
                          onChange={formik.handleChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className='row mb-6' style={{alignItems: 'center'}}>
                  <label className='col-lg-3 col-form-label  fw-bold fs-6'>Register Order</label>

                  <div className='col-lg-9'>
                    <div className='row'>
                      <div className='col-lg-12 fv-row'>
                        <select
                          value={formik.values.registerOrder}
                          onChange={(e) => {
                            formik.setFieldValue('registerOrder', e.target.value)
                          }}
                          className='form-select '
                        >
                          <option selected={formik.values.registerOrder === 'true'} value='true'>
                            HighLow
                          </option>
                          <option selected={formik.values.registerOrder === 'false'} value='false'>
                            LowHigh
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button
              onClick={formik.handleSubmit}
              disabled={!formik.isValid || formik.isSubmitting}
              className='btn  btn-primary '
            >
              <i className='fa fa-plus mx-2 '></i> {intl.formatMessage({id: 'SAVE'})}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export {EditTagPage}
