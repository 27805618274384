import DataTable from 'react-data-table-component-with-filter'
import {Loader} from '../Loader'
import {useNavigate} from 'react-router-dom'

import {useEffect, useState} from 'react'
import {useMutation, useQuery} from 'react-query'
import {usePagination} from '../../hooks'
import DeviceService from '../../service/device'

import {useIntl} from 'react-intl'
import RentalService from '../../service/rental'
import TagService from '../../service/tag'
import Swal from 'sweetalert2'

const RentalCompanyManagementTable = () => {
  const intl = useIntl()
  const navigate = useNavigate()

  const [showModal, setShowModal] = useState(false)
  const [buttonSpinner, setButtonSpinner] = useState(false)
  const [selectedDevice, setSelectedDevice] = useState({})
  const [deviceName, setDeviceName] = useState('')
  const [ipAdress, setIpAdress] = useState('')
  const [subnet, setSubnet] = useState('')

  const {setTotalRows, page, perPage, totalRows, handlePerRowsChange, handlePageChange} =
    usePagination()
  const {data, isLoading, refetch} = useQuery(['rentalcompany', page + perPage], async () =>
    RentalService.getRentalCompany({
      page,
      perPage,
    })
  )

  const mutation = useMutation(async (id) => await RentalService.removeRentalCompany(id))

  useEffect(() => {
    totalRows !== data?.totalCount && setTotalRows(data?.totalCount)
  }, [data?.totalCount])
  const columns = [
    {
      name: intl.formatMessage({id: 'NAME'}),
      selector: (row) => row.name,
      sortable: true,
      filterable: true,
    },
    {
      name: intl.formatMessage({id: 'ADDRESS'}),
      selector: (row) => row.address,
      sortable: true,
      filterable: true,
    },
    {
      name: intl.formatMessage({id: 'PHONE'}),
      selector: (row) => row.phoneNumber,
      sortable: true,
      filterable: true,
    },

    {
      name: intl.formatMessage({id: 'GSM'}),
      selector: (row) => row.gsmNumber,
      sortable: true,
      filterable: true,
    },
    {
      name: intl.formatMessage({id: 'CONTACT_MAIL'}),
      selector: (row) => row.mailAddress,
      sortable: true,
      filterable: true,
    },
    {
      name: intl.formatMessage({id: 'ACTIONS'}),
      sortable: false,
      filterable: false,
      cell: (row) => {
        return (
          <>
            <div>
              <button
                disabled={mutation.isLoading}
                onClick={() => navigate(`/rental-company/${row.id}`)}
                className='btn btn-primary'
              >
                <i className='fa fa-pencil mx-2 '></i>
              </button>
            </div>
            <div>
              <button
                disabled={mutation.isLoading}
                onClick={() => {
                  Swal.fire({
                    title: intl.formatMessage({id: 'AREYOUSURE'}),
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: intl.formatMessage({id: 'YES'}),
                    cancelButtonText: intl.formatMessage({id: 'NO'}),
                  }).then(async (result) => {
                    if (result.isConfirmed) {
                      const res = await mutation.mutateAsync(row.id)
                      if (res.isSuccess) {
                        Swal.fire({
                          title: intl.formatMessage({id: 'SUCCESS'}),
                          // text: intl.formatMessage({id: 'DELETEDSUCCESSFULLY'}),
                          icon: 'success',
                          showConfirmButton: false,
                          timer: 1500,
                        })
                        refetch()
                        return
                      }

                      Swal.fire({
                        title: 'Hata',
                        text: res?.errors?.[0]?.message,
                        icon: 'error',
                        showConfirmButton: false,
                        timer: 2000,
                      })
                    }
                  })
                }}
                className='btn btn-danger mx-4'
              >
                <i className='fa fa-trash mx-2 '></i>
              </button>
            </div>
          </>
        )
      },
    },
  ]
  return (
    <div className='card'>
      <div className='card-body p-4'>
        {mutation.isLoading || isLoading ? (
          <div className='text-center mt-2 mb-2'>
            <Loader />
          </div>
        ) : (
          <>
            <div className='table-page-header p-5 my-3'>
              <div className='add-new-user '>
                <button
                  onClick={() => navigate('/rental-company/add')}
                  className='btn btn-primary '
                >
                  <i className='fa fa-plus mx-2 '></i>{' '}
                  {intl.formatMessage({id: 'NEWRENTALCOMPANY'})}
                </button>
              </div>
            </div>
            <DataTable
              progressPending={mutation.isLoading || isLoading}
              progressComponent={<Loader />}
              className='table-striped custom-filtered-table'
              pagination
              paginationServer
              noDataComponent={
                <div style={{margin: '25px 0px'}}>{intl.formatMessage({id: 'TABLE.NODATA'})}</div>
              }
              paginationTotalRows={totalRows}
              paginationDefaultPage={page}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
              customStyles={{
                rows: {
                  style: {
                    cursor: 'pointer',
                    backgroundColor: 'var(--table-bg)',
                    '&:hover': {
                      backgroundColor: '#f3f3f3',
                    },
                  },
                },
              }}
              columns={columns}
              data={data?.items}
            />
          </>
        )}
      </div>
    </div>
  )
}

export default RentalCompanyManagementTable
