import {Routes, Route, BrowserRouter, Navigate} from 'react-router-dom'
import {PrivateRoutes} from './PrivateRoutes'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {Logout, AuthPage} from '../modules/auth'
import {App} from '../App'
import {LayoutSplashScreen} from '../../_metronic/layout/core'
import {useEffect, useState} from 'react'
import AccountService from '../../service/account'

const {PUBLIC_URL} = process.env

const AppRoutes = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const userToken = localStorage.getItem('userToken')

  useEffect(() => {
    ;(async () => {
      setIsLoading(true)
      if (!userToken) {
        setIsAuthenticated(false)
        setIsLoading(false)
        return
      }
      try {
        await AccountService.me()
        setIsAuthenticated(true)
        setIsLoading(false)
      } catch (error) {
        setIsAuthenticated(false)
        setIsLoading(false)
      }
    })()
  }, [])

  if (isLoading) {
    return <LayoutSplashScreen />
  }

  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          {!!userToken ? (
            <>
              <Route path='/*' element={<PrivateRoutes />} />
              <Route index element={<Navigate to='/index' />} />
            </>
          ) : (
            <>
              <Route path='auth/*' element={<AuthPage />} />
              <Route path='*' element={<Navigate to='/auth' />} />
            </>
          )}
          <Route path='error/*' element={<ErrorsPage />} />
          <Route path='logout' element={<Logout />} />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export {AppRoutes}
