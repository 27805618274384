import RegexParser from 'regex-parser'

export function kFormatter(num) {
  return Intl.NumberFormat('en', {notation: 'compact'}).format(num)
}

export function createFormData(e) {
  e.preventDefault()
  let formData = new FormData(e.currentTarget)
  let formObj
  for (let [key, value] of Array.from(formData.entries())) {
    if (!!value) {
      formObj = {...formObj, [key]: value}
    }
  }

  return formObj
}

const checkMatch = (regex, value) => {
  if (regex && !!value) {
    const regexPattern = RegexParser(regex)
    if (value.match(regexPattern)) {
      return null
    }
    return <div className='text-danger'>Bu alana uygun bir değer giriniz.</div>
  }
  return null
}

export const renderSourceTypeField = (dataKey, formik, deviceDetails) => {
  const clonedDataTypes = {
    ...formik.values.sourceDataKeyValue,
  }
  const targetItem = deviceDetails?.sourceDataKeys?.find((item) => item.id === dataKey?.id)
  console.log(dataKey)

  if (Array.isArray(dataKey?.values) && dataKey?.values.length > 0) {
    return (
      <select
        className='form-select'
        onChange={(e) => {
          const updatedValue = e.target.value
          const newData = {...formik.values.sourceDataKeyValue}
          newData[dataKey.id] = {
            sourceDataKeyId: dataKey.id,
            sourceDataId: targetItem?.sourceDataId ? targetItem.sourceDataId : null,
            value: updatedValue,
          }
          formik.setFieldValue('sourceDataKeyValue', newData)
        }}
      >
        <option selected={true} value=''>
          {dataKey?.name}
        </option>
        {dataKey?.values.map((item) => (
          <option
            selected={formik?.values?.sourceDataKeyValue?.[dataKey?.id]?.value === item}
            key={item}
            value={item}
          >
            {item}
          </option>
        ))}
      </select>
    )
  }
  if (
    dataKey.dataType.feName.toLocaleLowerCase() === 'int' ||
    dataKey.dataType.feName.toLocaleLowerCase() === 'integer'
  ) {
    return (
      <>
        <input
          autoComplete='off'
          pattern={new RegExp(dataKey?.regex)}
          type='number'
          className='form-control form-control-lg  mb-3 mb-lg-0'
          //placeholder={dataKey.dataType.feName}
          min='0'
          required
          value={formik?.values?.sourceDataKeyValue?.[dataKey?.id]?.value}
          onChange={(e) => {
            const updatedValue = e.target.value
            const newData = {...formik.values.sourceDataKeyValue} // sourceDataKeyValue'nin kopyasını al
            newData[dataKey.id] = {
              sourceDataKeyId: dataKey.id,
              sourceDataId: targetItem?.sourceDataId ? targetItem.sourceDataId : null,
              value: updatedValue,
            }
            formik.setFieldValue('sourceDataKeyValue', newData)
          }}
        />
        <div>
          {checkMatch(
            dataKey?.regex,
            formik?.values?.sourceDataKeyValue?.[dataKey?.id]?.value || ''
          )}
        </div>
      </>
    )
  }

  if (dataKey.dataType.feName.toLocaleLowerCase() === 'string') {
    return (
      <>
        <input
          autoComplete='off'
          type='text'
          className='form-control form-control-lg  mb-3 mb-lg-0'
          //placeholder={dataKey.dataType.feName}
          required
          value={formik?.values?.sourceDataKeyValue?.[dataKey?.id]?.value || ''}
          onChange={(e) => {
            const updatedValue = e.target.value
            const newData = {...formik.values.sourceDataKeyValue} // sourceDataKeyValue'nin kopyasını al
            newData[dataKey.id] = {
              sourceDataKeyId: dataKey.id,
              sourceDataId: targetItem?.sourceDataId ? targetItem.sourceDataId : null,
              value: updatedValue,
            }
            formik.setFieldValue('sourceDataKeyValue', newData)
          }}
        />
        <div>
          {checkMatch(
            dataKey?.regex,
            formik?.values?.sourceDataKeyValue?.[dataKey?.id]?.value || ''
          )}
        </div>
      </>
    )
  }
  if (dataKey.dataType.feName.toLocaleLowerCase() === 'boolean') {
    return (
      <select
        onChange={(e) => {
          if (!!e.target.value) {
            clonedDataTypes[dataKey.id] = {
              sourceDataKeyId: dataKey.id,
              sourceDataId: targetItem?.sourceDataId ? targetItem.sourceDataId : null,
              value: e.target.value === 'true' ? true : false,
            }
          }
          formik.setFieldValue('sourceDataKeyValue', clonedDataTypes)
        }}
        name={`sourceDataKeyValue[${dataKey.id}]`}
      >
        <option selected={!!formik?.values?.sourceDataKeyValue?.[dataKey?.id]?.value} value='true'>
          True
        </option>
        <option selected={!formik?.values?.sourceDataKeyValue?.[dataKey?.id]?.value} value='false'>
          False
        </option>
      </select>
    )
  }
}
