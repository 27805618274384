import axios from './index'

const getAll = async (params) => {
  try {
    const res = await axios.get('/flow', {
      params,
    })

    return res?.data?.value
  } catch (error) {}
}
const remove = async (id) => {
  const res = await axios.delete(`/flow/${id}`)
  return res?.data
}
const create = async (data) => {
  const res = await axios.post(`/flow`, data)
  return res?.data?.value
}
const get = async (id) => {
  const res = await axios.get(`/flow/${id}`)
  return res?.data?.value
}

const update = async (data, id) => {
  const res = await axios.put(`/flow/${id}`, data)
  return res?.data
}
 

const FlowService = {
  getAll,
  remove,
  create,
  get,
  update,
}

export default FlowService
