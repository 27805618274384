import React from 'react'
import {v4 as uuidv4} from 'uuid'
import {useDrag} from 'react-dnd'
import {useIntl} from 'react-intl'

const Machine = () => {
  const intl = useIntl()

  const [{isDragging}, drag] = useDrag({
    type: 'machine',
    item: {
      selectedTags: {},
      id: uuidv4(),
      dataGrid: {x: 0, y: 0, w: 3, h: 4, minW: 3, minH: 4, isResizable: false},
      type: 'machine',
      isRunning: true,
      hasError: true,
    },

    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  })

  return (
    <div
      ref={drag}
      style={{
        opacity: isDragging ? 0.5 : 1,
        cursor: 'move',
        // border: '1px solid black',
        width: '100%',
        padding: 15,
        borderRadius: 4,
        marginTop: 10,
      }}
    >
      <div className='text-center'>
        <h6>{intl.formatMessage({id: 'MACHINE'})}</h6>
        <img
          width={75}
          alt=''
          src='/images/machine.png?a=bb'
        />
      </div>
    </div>
  )
}

export default Machine
