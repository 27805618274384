import Swal from 'sweetalert2'
import {useNavigate} from 'react-router-dom'
import {useState} from 'react'
import {useQuery} from 'react-query'
import CategoryService from '../../service/category'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import AccountService from '../../service/account'
import {useIntl} from 'react-intl'

const AddNewUserPage = () => {
  const navigate = useNavigate()
  const [isMain, setIsMain] = useState(true)
  const intl = useIntl()
  const {data} = useQuery('users', async () =>
    AccountService.getAll({
      page: 1,
      perPage: 1000,
    })
  )
  const {data: roles} = useQuery(['roles'], async () => AccountService.getRoles())
  const formik = useFormik({
    initialValues: {
      name: '',
      surname: '',
      email: '',
      phoneNumber: '',
      userName: '',
      password: '',
      role: [],
    },
    isInitialValid: false,
    validationSchema: Yup.object().shape({
      name: Yup.string().required(' Name is required'),
      surname: Yup.string().required(' Surname is required'),
      email: Yup.string().required(' Email is required'),
      phoneNumber: Yup.string().required(' Phone Number is required'),
      userName: Yup.string().required(' User Name is required'),
      password: Yup.string().required(' Password is required'),
      role: Yup.string().required(' Role is required'),
    }),
    onSubmit: async (values) => {
      const roleArray = [values.role]
      values.role = roleArray
      values.isActive = true
      values.lockoutEnabled = true
      var res = await AccountService.create(values)
      console.log(res)
      if (res.status === 200) {
        Swal.fire({
          type: 'success',
          title: intl.formatMessage({id: 'SUCCESS'}),
          showConfirmButton: false,
          timer: 1500,
          icon: 'success',
        })
        navigate('/account-management')
      } else {
        Swal.fire({
          type: 'error',
          title: res.data.error.message,
          text: res.data.error.details,
          showConfirmButton: true,
          timer: 5000,
          icon: 'error',
        })
      }
    },
  })

  return (
    <div className='card'>
      <div className='card-body p-4'>
        <button onClick={() => navigate('/account-management')} className='btn btn-primary'>
          {intl.formatMessage({id: 'AUTH.GENERAL.BACK_BUTTON'})}
        </button>

        <div id='kt_account_profile_details' className='collapse show'>
          <form noValidate className='form'>
            <div className='card-body p-9'>
              <div className='row mb-6'>
                <label className='col-lg-3 col-form-label required fw-bold fs-6'>
                  {' '}
                  {intl.formatMessage({id: 'NAME'})}{' '}
                </label>

                <div className='col-lg-9'>
                  <div className='row'>
                    <div className='col-lg-12 fv-row'>
                      <input
                        type='text'
                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                        placeholder={intl.formatMessage({id: 'NAME'})}
                        name='name'
                        value={formik.values.name}
                        onChange={formik.handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-3 col-form-label required fw-bold fs-6'>
                  {intl.formatMessage({id: 'SURNAME'})}
                </label>

                <div className='col-lg-9'>
                  <div className='row'>
                    <div className='col-lg-12 fv-row'>
                      <input
                        type='text'
                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                        placeholder={intl.formatMessage({id: 'SURNAME'})}
                        name='surname'
                        value={formik.values.surname}
                        onChange={formik.handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-3 col-form-label required fw-bold fs-6'>
                  {intl.formatMessage({id: 'CONTACT_MAIL'})}
                </label>

                <div className='col-lg-9'>
                  <div className='row'>
                    <div className='col-lg-12 fv-row'>
                      <input
                        type='text'
                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                        placeholder={intl.formatMessage({id: 'CONTACT_MAIL'})}
                        name='email'
                        value={formik.values.email}
                        onChange={formik.handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-3 col-form-label required fw-bold fs-6'>
                  {intl.formatMessage({id: 'PHONE'})}
                </label>

                <div className='col-lg-9'>
                  <div className='row'>
                    <div className='col-lg-12 fv-row'>
                      <input
                        type='text'
                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                        placeholder={intl.formatMessage({id: 'PHONE'})}
                        name='phoneNumber'
                        value={formik.values.phoneNumber}
                        onChange={formik.handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-3 col-form-label required fw-bold fs-6'>
                  {intl.formatMessage({id: 'USERNAME'})}
                </label>

                <div className='col-lg-9'>
                  <div className='row'>
                    <div className='col-lg-12 fv-row'>
                      <input
                        type='text'
                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                        placeholder={intl.formatMessage({id: 'USERNAME'})}
                        name='userName'
                        value={formik.values.userName}
                        onChange={formik.handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-3 col-form-label required fw-bold fs-6'>
                  {intl.formatMessage({id: 'PASSWORD'})}
                </label>

                <div className='col-lg-9'>
                  <div className='row'>
                    <div className='col-lg-12 fv-row'>
                      <input
                        type='text'
                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                        placeholder={intl.formatMessage({id: 'PASSWORD'})}
                        name='password'
                        value={formik.values.password}
                        onChange={formik.handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-3 col-form-label fw-bold fs-6'>
                  {intl.formatMessage({id: 'ROLE'})}
                </label>

                <div className='col-lg-9'>
                  <div className='row'>
                    <div className='col-lg-12 fv-row'>
                      <select
                        value={formik.values.role}
                        onChange={formik.handleChange}
                        name='role'
                        className='form-select form-control-solid'
                      >
                        <option selected>{intl.formatMessage({id: 'CHOOSE'})}</option>
                        {roles?.items?.map((item, key) => (
                          <option value={item.name} key={key}>
                            {item.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <button
                disabled={!formik.isValid || formik.isSubmitting}
                type='submit'
                onClick={formik.handleSubmit}
                className='btn  btn-primary '
              >
                {intl.formatMessage({id: 'SAVE'})}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export {AddNewUserPage}
