/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import AccountService from '../../../../service/account'
import {jwtDecode} from 'jwt-decode'

const loginSchema = Yup.object().shape({
  email: Yup.string().max(50, 'Maximum 50 Karakter').required('Kullanıcı Adı veya E-Posta'),
  password: Yup.string()
    .min(3, 'Minimum 3 Karakter')
    .max(50, 'Maximum 50 Karakter')
    .required('Şifre giriniz'),
})

export function Login() {
  const [loading, setLoading] = useState(false)

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      remember: false,
    },
    isInitialValid: true,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        const loginRes = await AccountService.login({
          username: values.email,
          password: values.password,
          client_id: 'Backend_App',
          scope: 'offline_access Backend',
          grant_type: 'password',
        })

        if (!loginRes?.data?.access_token) {
          setStatus('Hatalı giriş bilgileri.')
          setSubmitting(false)
          setLoading(false)
          return
        }

        const decoded = jwtDecode(loginRes?.data?.access_token)
        console.log(decoded, loginRes)
        localStorage.setItem('userToken', loginRes?.data?.access_token)
        localStorage.setItem('refreshToken', loginRes?.data?.refresh_token)
        localStorage.setItem(
          'user',
          JSON.stringify({
            firstName: decoded.given_name,
            lastName: decoded?.family_name,
            email: decoded?.email,
            userName: decoded?.unique_name,
            tenantId: decoded?.tenantid,
          })
        )
        window.location.href = '/'
      } catch (error) {
        console.error(error)
        setStatus('Hatalı giriş bilgileri.')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className='text-center mb-11'>
        <h1 className='text-dark fw-bolder mb-3'>Giriş Yap</h1>
      </div>

      {formik.status && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )}

      {/* begin::Form group */}
      <div className='fv-row mb-8'>
        <label className='form-label fs-6 fw-bolder text-dark'>Kullanıcı Adı veya E-Posta</label>
        <input
          placeholder=''
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control bg-transparent',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
          type='email'
          name='email'
          autoComplete='off'
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{formik.errors.email}</span>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-3'>
        <label className='form-label fw-bolder text-dark fs-6 mb-0'>Şifre</label>
        <input
          type='password'
          autoComplete='off'
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.password && formik.errors.password,
            },
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
        />
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>

      <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
        <div />

        {/* begin::Link */}
        <Link to='/auth/forgot-password' className='link-primary'>
          Şifremi Unuttum
        </Link>
        {/* end::Link */}
      </div>
      {/* end::Wrapper */}

      {/* begin::Action */}
      <div className='d-grid mb-10'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-primary'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Giriş Yap</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Lütfen Bekleyin...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Action */}

      <div className='text-gray-500 text-center fw-semibold fs-6'>
        Henüz üye değil misiniz?{' '}
        <Link to='/auth/registration' className='link-primary'>
          Üye ol
        </Link>
      </div>
    </form>
  )
}
