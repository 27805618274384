import React from 'react'

function NodeConfigPanel({renderCondition, onClose, title, children}) {
  if (!renderCondition) return
  return (
    <div
      className='card w-25 h-75 p-10'
      style={{
        position: 'fixed',
        right: 0,
        top: 150,
      }}
    >
      <button
        onClick={() => onClose()}
        className='btn btn-primary d-flex align-items-center justify-content-center'
        style={{
          position: 'absolute',
          right: 10,
          top: 10,
          width: 25,
          height: 35,
        }}
      >
        <i className='fa fa-times'></i>
      </button>
      <div>
        <h3>{title}</h3>
        {children}
      </div>
    </div>
  )
}

export default NodeConfigPanel
