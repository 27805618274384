import axios from './index'

const getAll = async (params) => {
  try {
    const res = await axios.get('/device', {
      params,
    })

    return res?.data?.value
  } catch (error) {}
}

const get = async (id) => {
  const res = await axios.get(`/device/${id}`)
  return res?.data?.value
}
const getAllEdgeDevices = async (params) => {
  try {
    const res = await axios.get('/edgeDevice', {
      params,
    })

    return res?.data?.value
  } catch (error) {}
}

const create = async (data) => {
  const res = await axios.post(`/device`, data)
  return res?.data
}

const update = async (id, data) => {
  // const res = await axios.put(`/device/UpdateDeviceDetailById/${id}`, data)
  const res = await axios.put(`/device/${id}`, data)
  return res?.data
}
const updateDeviceSourceData = async (id, data) => {
  // const res = await axios.put(`/device/UpdateDeviceDetailById/${id}`, data)
  const res = await axios.put(`/sourceData/${id}`, data)
  return res?.data
}

const remove = async (id) => {
  const res = await axios.delete(`/device/${id}`)
  return res?.data
}
const createEdgeDeviceRequest = async (params) => {
  const res = await axios.post(`/EdgeDeviceRequestList`, params)
  return res?.data
}

const getPackageTagWithPackageDeviceId = async (id) => {
  const res = await axios.get(`/PackageDeviceTag/GetPackageTagWithPackageDeviceId/?id=${id}`)
  return res?.data
}
const getEdgeDeviceSell = async (params) => {
  try {
    const res = await axios.get('/EdgeDeviceSell', {
      params,
    })

    return res?.data?.value
  } catch (error) {}
}

const getDeviceByEdgeDeviceId = async (params) => {
  try {
    const res = await axios.get('/device/GetDeviceByEdgeDeviceId', {
      params,
    })

    return res?.data?.value
  } catch (error) {}
}

const getDeviceDetailById = async (id) => {
  try {
    const res = await axios.get('/device/GetDeviceDetailById?id=' + id)

    return res?.data?.value
  } catch (error) {}
}

const updateEdgeDeviceName = async ({
  id,
  name,
  licenceId,
  dataSimId,
  deviceTypeId,
  tenantId,
  subnet,
  ipAddress,
}) => {
  try {
    const res = await axios.put('/EdgeDevice/' + id, {
      name,
      licenceId,
      dataSimId,
      deviceTypeId,
      tenantId,
      ipAddress,
      subnet
    })

    return res?.data
  } catch (error) {}
}

const DeviceService = {
  getAll,
  getAllEdgeDevices,
  create,
  remove,
  getPackageTagWithPackageDeviceId,
  get,
  getEdgeDeviceSell,
  createEdgeDeviceRequest,
  getDeviceByEdgeDeviceId,
  updateEdgeDeviceName,
  getDeviceDetailById,
  update,
  updateDeviceSourceData,
}

export default DeviceService
