/* eslint-disable jsx-a11y/anchor-is-valid */
import {PageTitle} from '../../_metronic/layout/core'
import {useIntl} from 'react-intl'
import CategoryManagementTable from '../../components/CategoryManagement/CategoryManagementTable'

const CategoryPage = () => {
  const intl = useIntl()

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.CATEGORY'})}</PageTitle>
      <CategoryManagementTable />
    </>
  )
}

export {CategoryPage}
