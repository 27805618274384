import React from 'react'
import style from './flow.module.css'
import {useIntl} from 'react-intl'
function FlowAside({onDragStart, isConditionDraggable = true}) {
  const intl = useIntl()
  return (
    <aside style={{marginBottom: 12, display: 'flex', gap: 24}}>
      <div
        draggable
        className={`dndnode ${style.nodeBarItem}`}
        onDragStart={(event) => onDragStart(event, 'inputNode', 'inputNode')}
      >
        <strong> {intl.formatMessage({id:'INPUT'})} </strong>
      </div>
    
      <div
        draggable={isConditionDraggable}
        className={`dndnode ${style.nodeBarItem}`}
        style={{
          opacity: isConditionDraggable ? 1 : 0.5,
          pointerEvents: isConditionDraggable ? 'auto' : 'none',
        }}
        onDragStart={(event) => onDragStart(event, 'conditionNode', 'conditionNode')}
      >
        <img draggable='false' src='/images/flow-chart.png' width={45} />
        <strong>{intl.formatMessage({id:'CONDITION'})}</strong>
      </div>

      <div
        draggable
        className={`dndnode ${style.nodeBarItem}`}
        onDragStart={(event) => onDragStart(event, 'outputNode', 'outputNode')}
      >
        <strong> {intl.formatMessage({id:'OUTPUT'})}</strong>
      </div>
    </aside>
  )
}

export default FlowAside
