import {Loader} from '../../components/Loader'
import {useEffect, useState} from 'react'
import DragAndDropCharts from '../../components/DragAndDropCharts'
import {useQuery} from 'react-query'
import TagService from '../../service/tag'
import {useParams} from 'react-router-dom'
import DashboardService from '../../service/dashboard'
import {useIntl} from 'react-intl'
import {Modal} from 'react-bootstrap'
import Select from 'react-select'
import Swal from 'sweetalert2'

const DashboardManagementDetailPage = () => {
  const params = useParams()
  const intl = useIntl()

  const [showModal, setShowModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [selectedInterval, setSelectedInterval] = useState({})
  const [name, setName] = useState('')

  const [showAdd, setShowAdd] = useState(false)
  const [dashboardDetail, setDashboardDetail] = useState({})

  const fetchDashboardData = () => {
    DashboardService.get(params.id).then((res) => {
      setDashboardDetail(res)
      setName(res?.name)
      var interval = {
        value: res.selectedInterval.value,
        label:
          res.selectedInterval.value / 1000 < 60
            ? res.selectedInterval.value / 1000 + ' ' + intl.formatMessage({id: 'SECOND'})
            : res.selectedInterval.value / 1000 === 60
            ? '1' + intl.formatMessage({id: 'MINUTE'})
            : res.selectedInterval.value / 1000 / 60 + ' ' + intl.formatMessage({id: 'MINUTES'}),
      }
      console.log(interval)
      setSelectedInterval(interval)
    })
  }
  useEffect(() => {
    fetchDashboardData()
  }, [])

  const {data: tagsList, isLoading: isTagsLoading} = useQuery(['tags'], async () =>
    TagService.getAll({
      page: 1,
      perPage: 1000,
    })
  )

  if (isTagsLoading) {
    return <Loader />
  }
  return (
    <>
      <style
        dangerouslySetInnerHTML={{
          __html: `#kt_app_toolbar{
          display: none!important;
      }`,
        }}
      />
      <div
        className='d-flex align-items-center'
        style={{
          gap: 12,
        }}
      >
        <h3>
          {dashboardDetail?.name} {intl.formatMessage({id: 'MENU.DASHBOARD'})}
        </h3>
        <button
          onClick={() => {
            setShowModal(true)
          }}
          className='btn btn-primary mb-4'
        >
          <i className='fa fa-edit '></i>
        </button>
      </div>

      <a href='/dashboard-management' className='btn btn-danger mb-4'>
        <i className='fa fa-arrow-left mx-2 '></i>
        {intl.formatMessage({id: 'AUTH.GENERAL.BACK_BUTTON'})}
      </a>
      <button
        onClick={() => setShowAdd(true)}
        className='btn btn-primary mb-4 ml-2'
        style={{marginLeft: 12}}
      >
        <i className='fa fa-plus mx-2 '></i> {intl.formatMessage({id: 'DASHBOARD.ADDGRAP'})}
      </button>
      <div
        className='custom-dashboard-target'
        style={{
          maxWidth: showAdd ? '75%' : '100%',
        }}
      >
        <div className=''>
          {isTagsLoading ? (
            <div className='text-center mt-2 mb-2'>
              <Loader />
            </div>
          ) : (
            <DragAndDropCharts
              fetchDashboardData={fetchDashboardData}
              dashboardDetail={dashboardDetail}
              tagsList={tagsList}
              showAdd={showAdd}
              setShowAdd={setShowAdd}
            />
          )}

          <Modal
            className='modal fade'
            id='kt_modal_select_location'
            data-backdrop='static'
            tabIndex={-1}
            role='dialog'
            show={showModal}
            dialogClassName='modal-xl'
          >
            <div className='modal-content'>
              <div className='modal-header'>
                <h5 className='modal-title'> {intl.formatMessage({id: 'MENU.EDITDASHBOARD'})}</h5>
              </div>
              <div className='modal-body'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid mb-4 mb-lg-0'
                  placeholder={intl.formatMessage({id: 'DASHBOARD.NAME'})}
                  required
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <div className='mt-2 mb-2'></div>
                <label htmlFor=''>{intl.formatMessage({id: 'DASHBOARD.SELECTRANGE'})}</label>
                <Select
                  onChange={(e) => {
                    setSelectedInterval(e)
                  }}
                  value={selectedInterval}
                  placeholder={intl.formatMessage({id: 'DASHBOARD.SELECTRANGE'})}
                  options={[
                    {value: '10000', label: '10 ' + intl.formatMessage({id: 'SECOND'})},
                    {value: '20000', label: '20 ' + intl.formatMessage({id: 'SECOND'})},
                    {value: '30000', label: '30 ' + intl.formatMessage({id: 'SECOND'})},
                    {value: '45000', label: '45 ' + intl.formatMessage({id: 'SECOND'})},
                    {value: '60000', label: '1 ' + intl.formatMessage({id: 'MINUTE'})},
                    {value: '300000', label: '5 ' + intl.formatMessage({id: 'MINUTES'})},
                    ,
                  ]}
                />
              </div>
              <div className='modal-footer'>
                <button
                  type='button'
                  disabled={isLoading}
                  className='btn btn-light-primary'
                  onClick={() => setShowModal(false)}
                >
                  {intl.formatMessage({id: 'CLOSE'})}
                </button>
                <button
                  id='submit'
                  type='button'
                  disabled={!name || !selectedInterval.value || isLoading}
                  className='btn btn-primary'
                  onClick={async (e) => {
                    setIsLoading(true)
                    await DashboardService.update(dashboardDetail?.id, {
                      ...dashboardDetail,
                      name,
                      selectedInterval,
                    })
                    const res = await DashboardService.get(dashboardDetail?.id)
                    setDashboardDetail(res)

                    Swal.fire({
                      title: intl.formatMessage({id: 'SUCCESS'}),
                      icon: 'success',
                      showConfirmButton: false,
                      timer: 1500,
                    })
                    setShowModal(false)
                    setIsLoading(false)
                  }}
                >
                  {intl.formatMessage({id: 'SAVE'})}
                </button>
              </div>
            </div>
          </Modal>
        </div>
      </div>
    </>
  )
}

export {DashboardManagementDetailPage}
