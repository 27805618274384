import DataTable from 'react-data-table-component-with-filter'
import {useNavigate} from 'react-router-dom'
import {Loader} from '../Loader'
import Swal from 'sweetalert2'
import {useMutation, useQuery} from 'react-query'
import {useEffect, useState} from 'react'
import {usePagination} from '../../hooks'
import AccountService from '../../service/account'
import {Modal} from 'react-bootstrap'
import {useIntl} from 'react-intl'

const AccountManagementTable = () => {
  const [showModal, setShowModal] = useState(false)
  const [selectedUser, setSelectedUser] = useState({})
  const [password, setPassword] = useState('')
  const [buttonSpinner, setButtonSpinner] = useState(false)
  const intl = useIntl()
  const navigate = useNavigate()

  const {setTotalRows, page, perPage, totalRows, handlePerRowsChange, handlePageChange} =
    usePagination()
  const {data, isLoading, refetch} = useQuery(['user', page + perPage], async () =>
    AccountService.getUsers({
      SkipCount: 0,
      MaxResultCount: 100,
    })
  )

  useEffect(() => {
    totalRows !== data?.totalCount && setTotalRows(data?.totalCount)
  }, [data?.totalCount])

  const mutation = useMutation(async (id) => await AccountService.remove(id))

  const mutationPassword = useMutation(async (params) => {
    const {id} = params

    return AccountService.update(id, params)
  })
  const columns = [
    {
      name: intl.formatMessage({id: 'NAME'}),
      selector: (row) => row.name,
      sortable: true,
      filterable: true,
    },
    {
      name: intl.formatMessage({id: 'SURNAME'}),
      selector: (row) => row.surname,
      sortable: true,
      filterable: true,
    },
    {
      name: intl.formatMessage({id: 'CONTACT_MAIL'}),
      selector: (row) => row.email,
      sortable: true,
      filterable: true,
    },

    {
      name: intl.formatMessage({id: 'PHONE'}),
      selector: (row) => row.phoneNumber,
      sortable: true,
      filterable: true,
    },

    {
      name: intl.formatMessage({id: 'ACTIONS'}),
      sortable: false,
      filterable: false,
      cell: (row) => {
        return (
          <>
            <div>
              <button
                disabled={mutation.isLoading}
                onClick={() => navigate(`/account-management/${row.id}`)}
                className='btn btn-primary mx-2'
              >
                <i className='fa fa-pencil  '></i>
              </button>
            </div>
            <div>
              <button
                className='btn btn-warning mx-2 '
                onClick={() => {
                  setSelectedUser(row)
                  setShowModal(true)
                }}
              >
                <i className='fa fa-key  '></i>
              </button>
            </div>
            <div>
              <button
                disabled={mutation.isLoading}
                onClick={async () => {
                  await Swal.fire({
                    title: intl.formatMessage({id: 'AREYOUSURE'}),
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: intl.formatMessage({id: 'YES'}),
                    cancelButtonText: intl.formatMessage({id: 'NO'}),
                  }).then(async (result) => {
                    if (result.isConfirmed) {
                      await mutation.mutateAsync(row.id)
                      Swal.fire({
                        title: intl.formatMessage({id: 'DELETED'}),
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 1500,
                      })
                      refetch()
                    }
                  })
                }}
                className='btn btn-danger mx-2'
              >
                <i className='fa fa-trash  '></i>
              </button>
            </div>
          </>
        )
      },
    },
  ]
  return (
    <div className='card'>
      <div className='card-body p-4'>
        {isLoading ? (
          <div className='text-center mt-2 mb-2'>
            <Loader />
          </div>
        ) : (
          <>
            <div className='table-page-header p-5 my-3'>
              <div className='add-new-user '>
                <button
                  onClick={() => navigate('/account-management/add')}
                  className='btn btn-primary '
                >
                  <i className='fa fa-plus mx-2 '></i> {intl.formatMessage({id: 'ADDNEWUSER'})}
                </button>
              </div>
            </div>

            <DataTable
              progressPending={isLoading}
              progressComponent={<Loader />}
              className='table-striped custom-filtered-table'
              pagination
              paginationServer
              paginationTotalRows={totalRows}
              paginationDefaultPage={page}
              onChangeRowsPerPage={handlePerRowsChange}
              noDataComponent={
                <div style={{margin: '25px 0px'}}>{intl.formatMessage({id: 'TABLE.NODATA'})}</div>
              }
              onChangePage={handlePageChange}
              customStyles={{
                rows: {
                  style: {
                    cursor: 'pointer',
                    backgroundColor: 'var(--table-bg)',
                    '&:hover': {
                      backgroundColor: '#f3f3f3',
                    },
                  },
                },
              }}
              columns={columns}
              data={data?.items}
            />
          </>
        )}
      </div>
      <Modal
        className='modal fade'
        id='kt_modal_select_location'
        data-backdrop='static'
        tabIndex={-1}
        role='dialog'
        show={showModal}
        dialogClassName='modal-xl'
      >
        <div className='modal-content'>
          <div className='modal-header'>
            <h5 className='modal-title'>{intl.formatMessage({id: 'UPDATEUSERPASSWORD'})}</h5>
          </div>
          <div className='modal-body'>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
              placeholder={intl.formatMessage({id: 'PASSWORD'})}
              required
              value={password}
              onChange={(e) => {
                setPassword(e.target.value)
              }}
              name='password'
            />
          </div>
          <div className='modal-footer'>
            <button
              type='button'
              className='btn btn-light-primary'
              onClick={() => setShowModal(false)}
            >
              {intl.formatMessage({id: 'CLOSE'})}
            </button>
            <button
              id='submit'
              type='button'
              disabled={buttonSpinner}
              className='btn btn-primary'
              onClick={async () => {
                setButtonSpinner(true)

                var res = await mutationPassword.mutateAsync({
                  id: selectedUser.id,
                  email: selectedUser.email,
                  name: selectedUser.name,
                  surname: selectedUser.surname,
                  userName: selectedUser.userName,
                  password: password,
                  phoneNumber: selectedUser.phoneNumber,
                  isActive: true,
                  lockoutEnabled: true,
                })
                if (res.status === 200) {
                  Swal.fire({
                    type: 'success',
                    title: intl.formatMessage({id: 'SUCCESS'}),
                    showConfirmButton: false,
                    timer: 1500,
                    icon: 'success',
                  })
                  setShowModal(false)
                  refetch()
                } else {
                  Swal.fire({
                    type: 'error',
                    title: res.data.error.message,
                    text: res.data.error.details,
                    showConfirmButton: true,
                    timer: 5000,
                    icon: 'error',
                  })
                }
                setButtonSpinner(false)
              }}
            >
              {intl.formatMessage({id: 'SAVE'})}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default AccountManagementTable
