import DataTable from 'react-data-table-component-with-filter'
import {Loader} from '../Loader'
import {useNavigate} from 'react-router-dom'

import {useEffect, useState} from 'react'
import {useMutation, useQuery} from 'react-query'
import {usePagination} from '../../hooks'
import DeviceService from '../../service/device'
import {Modal} from 'react-bootstrap'

import {useIntl} from 'react-intl'
import RentalService from '../../service/rental'
import TagService from '../../service/tag'
import Swal from 'sweetalert2'

const RentalSetupManagementTable = () => {
  const intl = useIntl()
  const navigate = useNavigate()

  const [showModal, setShowModal] = useState(false)
  const [buttonSpinner, setButtonSpinner] = useState(false)
  const [selectedDevice, setSelectedDevice] = useState({})
  const [deviceName, setDeviceName] = useState('')
  const [ipAdress, setIpAdress] = useState('')
  const [subnet, setSubnet] = useState('')

  const {setTotalRows, page, perPage, totalRows, handlePerRowsChange, handlePageChange} =
    usePagination()
  const {data, isLoading, refetch} = useQuery(['rental', page + perPage], async () =>
    RentalService.getRental({
      page,
      perPage,
    })
  )
  const {data: edgeDevices} = useQuery(['edgedevices'], async () =>
    DeviceService.getAllEdgeDevices({
      page: 1,
      perPage: 1000,
    })
  )
  const {data: rentalCompany} = useQuery(['rentalcompany'], async () =>
    RentalService.getRentalCompany({
      page: 1,
      perPage: 1000,
    })
  )
  const mutation = useMutation(async (id) => await RentalService.removeRental(id))

  useEffect(() => {
    totalRows !== data?.totalCount && setTotalRows(data?.totalCount)
  }, [data?.totalCount])
  const columns = [
    {
      name: intl.formatMessage({id: 'EDGEDEVICE'}),
      selector: (row) => row.name,
      sortable: true,
      filterable: true,
      cell: (row) => {
        return <div>{edgeDevices?.items?.find((item) => item?.id === row?.edgeDeviceId)?.name}</div>
      },
    },
    {
      name: intl.formatMessage({id: 'RENTINGCOMPANY'}),
      selector: (row) => row.rentalCompanyId,
      sortable: true,
      filterable: true,
      cell: (row) => {
        return (
          <div>{rentalCompany?.items?.find((item) => item?.id === row?.rentalCompanyId)?.name}</div>
        )
      },
    },
    {
      name: intl.formatMessage({id: 'STARTDATE'}),
      selector: (row) => row.startDate,
      sortable: true,
      filterable: true,
    },
    {
      name: intl.formatMessage({id: 'ENDTIME'}),
      selector: (row) => row.endTime,
      sortable: true,
      filterable: true,
    },
    {
      name: intl.formatMessage({id: 'ACTIONS'}),
      sortable: false,
      filterable: false,
      cell: (row) => {
        return (
          <>
            <div>
              <button
                disabled={mutation.isLoading}
                onClick={() => navigate(`/rental/${row.id}`)}
                className='btn btn-primary'
              >
                <i className='fa fa-pencil mx-2 '></i>
              </button>
            </div>
            <div>
              <button
                disabled={mutation.isLoading}
                onClick={() => {
                  Swal.fire({
                    title: intl.formatMessage({id: 'AREYOUSURE'}),
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: intl.formatMessage({id: 'YES'}),
                    cancelButtonText: intl.formatMessage({id: 'NO'}),
                  }).then(async (result) => {
                    if (result.isConfirmed) {
                      const res = await mutation.mutateAsync(row.id)
                      if (res.isSuccess) {
                        Swal.fire({
                          title: intl.formatMessage({id: 'SUCCESS'}),
                          // text: intl.formatMessage({id: 'DELETEDSUCCESSFULLY'}),
                          icon: 'success',
                          showConfirmButton: false,
                          timer: 1500,
                        })
                        refetch()
                        return
                      }

                      Swal.fire({
                        title: 'Hata',
                        text: res?.errors?.[0]?.message,
                        icon: 'error',
                        showConfirmButton: false,
                        timer: 2000,
                      })
                    }
                  })
                }}
                className='btn btn-danger mx-4'
              >
                <i className='fa fa-trash mx-2 '></i>
              </button>
            </div>
          </>
        )
      },
    },
  ]
  return (
    <div className='card'>
      <div className='card-body p-4'>
        {mutation.isLoading || isLoading ? (
          <div className='text-center mt-2 mb-2'>
            <Loader />
          </div>
        ) : (
          <>
            <div className='table-page-header p-5 my-3'>
              <div className='add-new-user '>
                <button onClick={() => navigate('/rental/add')} className='btn btn-primary '>
                  <i className='fa fa-plus mx-2 '></i>{' '}
                  {intl.formatMessage({id: 'NEWRENTALSETUP'})}
                </button>
              </div>
            </div>
            <DataTable
              progressPending={mutation.isLoading || isLoading}
              progressComponent={<Loader />}
              className='table-striped custom-filtered-table'
              pagination
              paginationServer
              noDataComponent={
                <div style={{margin: '25px 0px'}}>{intl.formatMessage({id: 'TABLE.NODATA'})}</div>
              }
              paginationTotalRows={totalRows}
              paginationDefaultPage={page}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
              customStyles={{
                rows: {
                  style: {
                    cursor: 'pointer',
                    backgroundColor: 'var(--table-bg)',
                    '&:hover': {
                      backgroundColor: '#f3f3f3',
                    },
                  },
                },
              }}
              columns={columns}
              data={data?.items}
            />
          </>
        )}
      </div>

      <Modal
        className='modal fade'
        id='kt_modal_select_location'
        data-backdrop='static'
        tabIndex={-1}
        role='dialog'
        show={showModal}
        dialogClassName='modal-xl'
      >
        <div className='modal-content'>
          <div className='modal-header'>
            <h5 className='modal-title'>
              {intl.formatMessage({id: 'EDGEDEVICE'})} {intl.formatMessage({id: 'UPDATE'})}{' '}
            </h5>
          </div>
          <div className='modal-body'>
            <div className='mb-4'>
              <label htmlFor=''>Adi</label>
              <input
                type='text'
                className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                placeholder={intl.formatMessage({id: 'NAME'})}
                required
                value={deviceName}
                onChange={(e) => setDeviceName(e.target.value)}
                name='name'
              />
            </div>
            <div className='mb-4'>
              <label htmlFor=''>{intl.formatMessage({id: 'IPADRESS'})}</label>
              <input
                type='text'
                className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                placeholder={intl.formatMessage({id: 'IPADRESS'})}
                required
                value={ipAdress}
                onChange={(e) => setIpAdress(e.target.value)}
                name='ipAddress'
              />
            </div>
            <div className='mb-0'>
              <label htmlFor=''>{intl.formatMessage({id: 'SUBNET'})}</label>
              <input
                type='text'
                className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                placeholder={intl.formatMessage({id: 'SUBNET'})}
                required
                value={subnet}
                onChange={(e) => setSubnet(e.target.value)}
                name='ipAddress'
              />
            </div>
          </div>
          <div className='modal-footer'>
            <button
              type='button'
              className='btn btn-light-primary'
              onClick={() => setShowModal(false)}
            >
              {intl.formatMessage({id: 'CLOSE'})}
            </button>
            <button
              id='submit'
              type='button'
              className='btn btn-primary'
              disabled={isLoading}
              onClick={async () => {
                setShowModal(false)
                await mutation.mutateAsync({
                  id: selectedDevice.id,
                  name: deviceName,
                  licenceId: selectedDevice.licenceId,
                  dataSimId: selectedDevice.dataSimId,
                  deviceTypeId: selectedDevice.deviceTypeId,
                  tenantId: JSON.parse(localStorage.getItem('user')).tenantId,
                })
                refetch()
              }}
            >
              {intl.formatMessage({id: 'UPDATE'})}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default RentalSetupManagementTable
