import DataTable from 'react-data-table-component-with-filter'
import {Loader} from '../Loader'
import {useEffect, useState} from 'react'
import {useMutation, useQuery} from 'react-query'
import {usePagination} from '../../hooks'
import DeviceService from '../../service/device'
import {Modal} from 'react-bootstrap'
import AccountService from '../../service/account'
import Swal from 'sweetalert2'
import {useIntl} from 'react-intl'

const EdgeDeviceManagemetTable = () => {
  const intl = useIntl()
  const [showModal, setShowModal] = useState(false)
  const [buttonSpinner, setButtonSpinner] = useState(false)
  const [selectedDevice, setSelectedDevice] = useState({})
  const [deviceName, setDeviceName] = useState('')
  const [ipAdress, setIpAdress] = useState('')
  const [subnet, setSubnet] = useState('')

  const {setTotalRows, page, perPage, totalRows, handlePerRowsChange, handlePageChange} =
    usePagination()
  const {data, isLoading, refetch} = useQuery(['edgedevices', page + perPage], async () =>
    DeviceService.getAllEdgeDevices({
      page,
      perPage,
    })
  )
  const sendMail = () => {
    setButtonSpinner(true)
    AccountService.sendMail({
      name: JSON.parse(localStorage.getItem('user')).userName,
      email: JSON.parse(localStorage.getItem('user')).email,
      message: `Kullanıcı Cihaz Talep Ediyor !!!`,
    }).finally(() => {
      Swal.fire(
        intl.formatMessage({id: 'SUCCESS'}),
        intl.formatMessage({id: 'SUCCESSREQ'}),
        'success'
      )
      setButtonSpinner(false)
    })
  }

  const mutation = useMutation(async (params) => {
    const {id, name, licenceId, dataSimId, deviceTypeId, tenantId} = params
    console.log(params)
    return DeviceService.updateEdgeDeviceName({
      id,
      name,
      licenceId,
      dataSimId,
      deviceTypeId,
      tenantId,
      subnet,
      ipAddress: ipAdress,
    })
  })
  useEffect(() => {
    totalRows !== data?.totalCount && setTotalRows(data?.totalCount)
  }, [data?.totalCount])

  const columns = [
    {
      name: intl.formatMessage({id: 'DEVICENAME'}),
      selector: (row) => row.name,
      sortable: true,
      filterable: true,
    },
    {
      name: intl.formatMessage({id: 'STATUS'}),
      selector: (row) => row.deviceStatusName,
      sortable: true,
      filterable: false,
      cell: (row) => {
        const statusClass =
          row.deviceStatus === 1
            ? '   badge-light-primary'
            : row.deviceStatus === 2
            ? '   badge-light-warning'
            : row.deviceStatus === 3
            ? 'badge-light-dark'
            : row.deviceStatus === 4
            ? 'badge-light-success'
            : 'badge-light-danger'

        const statusText =
          row.deviceStatus === 1
            ? intl.formatMessage({id: 'APPOWALWAITING'})
            : row.deviceStatus === 2
            ? intl.formatMessage({id: 'SHIPPING'})
            : row.deviceStatus === 3
            ? intl.formatMessage({id: 'OFFLINE'})
            : row.deviceStatus === 4
            ? intl.formatMessage({id: 'ONLINE'})
            : intl.formatMessage({id: 'UNKNOWN'})
        return <span className={`badge ${statusClass}`}>{statusText}</span>
      },
    },
    {
      name: intl.formatMessage({id: 'ACTIONS'}),
      sortable: false,
      filterable: false,
      cell: (row) => {
        return (
          <>
            <div>
              <button
                onClick={() => {
                  setSelectedDevice(row)
                  setDeviceName(row.name)
                  setIpAdress(row.ipAddress)
                  setSubnet(row.subnet)
                  setShowModal(true)
                }}
                className='btn btn-primary'
              >
                <i className='fa fa-pencil mx-2 '></i>
              </button>
            </div>
          </>
        )
      },
    },
  ]
  return (
    <div className='card'>
      <div className='card-body p-4'>
        {mutation.isLoading || isLoading ? (
          <div className='text-center mt-2 mb-2'>
            <Loader />
          </div>
        ) : (
          <>
            <button
              className='btn btn-primary mb-4'
              disabled={buttonSpinner}
              onClick={async () => {
                sendMail()
              }}
            >
              {buttonSpinner ? (
                <span class='indicator-progress fs-1 ms-n1' style={{display: 'inline-block'}}>
                  <span class='spinner-border spinner-border-sm align-middle fs-1 ms-n1 '></span>
                </span>
              ) : (
                <span>
                  {' '}
                  <i className='fa fa-plus mx-2'></i> {intl.formatMessage({id: 'NEWEDGEDEVICEREQ'})}
                </span>
              )}
            </button>

            <DataTable
              progressPending={mutation.isLoading || isLoading}
              progressComponent={<Loader />}
              className='table-striped custom-filtered-table'
              pagination
              paginationServer
              noDataComponent={
                <div style={{margin: '25px 0px'}}>{intl.formatMessage({id: 'TABLE.NODATA'})}</div>
              }
              paginationTotalRows={totalRows}
              paginationDefaultPage={page}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
              customStyles={{
                rows: {
                  style: {
                    cursor: 'pointer',
                    backgroundColor: 'var(--table-bg)',
                    '&:hover': {
                      backgroundColor: '#f3f3f3',
                    },
                  },
                },
              }}
              columns={columns}
              data={data?.items}
            />
          </>
        )}
      </div>

      <Modal
        className='modal fade'
        id='kt_modal_select_location'
        data-backdrop='static'
        tabIndex={-1}
        role='dialog'
        show={showModal}
        dialogClassName='modal-xl'
      >
        <div className='modal-content'>
          <div className='modal-header'>
            <h5 className='modal-title'>
              {intl.formatMessage({id: 'EDGEDEVICE'})} {intl.formatMessage({id: 'UPDATE'})}{' '}
            </h5>
          </div>
          <div className='modal-body'>
            <div className='mb-4'>
              <label htmlFor=''>Adi</label>
              <input
                type='text'
                className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                placeholder={intl.formatMessage({id: 'NAME'})}
                required
                value={deviceName}
                onChange={(e) => setDeviceName(e.target.value)}
                name='name'
              />
            </div>
            <div className='mb-4'>
              <label htmlFor=''>{intl.formatMessage({id: 'IPADRESS'})}</label>
              <input
                type='text'
                className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                placeholder={intl.formatMessage({id: 'IPADRESS'})}
                required
                value={ipAdress}
                onChange={(e) => setIpAdress(e.target.value)}
                name='ipAddress'
              />
            </div>
            <div className='mb-0'>
              <label htmlFor=''>{intl.formatMessage({id: 'SUBNET'})}</label>
              <input
                type='text'
                className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                placeholder={intl.formatMessage({id: 'SUBNET'})}
                required
                value={subnet}
                onChange={(e) => setSubnet(e.target.value)}
                name='ipAddress'
              />
            </div>
          </div>
          <div className='modal-footer'>
            <button
              type='button'
              className='btn btn-light-primary'
              onClick={() => setShowModal(false)}
            >
              {intl.formatMessage({id: 'CLOSE'})}
            </button>
            <button
              id='submit'
              type='button'
              className='btn btn-primary'
              disabled={isLoading}
              onClick={async () => {
                setShowModal(false)
                await mutation.mutateAsync({
                  id: selectedDevice.id,
                  name: deviceName,
                  licenceId: selectedDevice.licenceId,
                  dataSimId: selectedDevice.dataSimId,
                  deviceTypeId: selectedDevice.deviceTypeId,
                  tenantId: JSON.parse(localStorage.getItem('user')).tenantId,
                })
                refetch()
              }}
            >
              {intl.formatMessage({id: 'UPDATE'})}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default EdgeDeviceManagemetTable
