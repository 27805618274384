import axios from './index'

const getAll = async (params) => {
  try {
    const res = await axios.get('/sourceType', {
      params,
    })

    return res?.data?.value
  } catch (error) {}
}

const getSourceDataKeysWithSourceTypeId = async (id) => {
  try {
    const res = await axios.get(`/SourceDataKey/GetSourceDataKeysWithSourceTypeId?id=${id}`)

    return res?.data?.value
  } catch (error) {}
}

const SourceTypeService = {
  getAll,
  getSourceDataKeysWithSourceTypeId
}

export default SourceTypeService
