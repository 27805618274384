/* eslint-disable jsx-a11y/anchor-is-valid */
import {useLocation} from 'react-router-dom'
import { NewFlowPageHome } from '../../components/FlowPage/NewFlowPageHome'

const AddFlowPage = () => {
  const {state} = useLocation()

  return (
    <NewFlowPageHome
      flowData={{
         ...state
      }}
    />
  )
}

export {AddFlowPage}
