/* eslint-disable jsx-a11y/anchor-is-valid */
import {useNavigate, useParams} from 'react-router-dom'
import FlowPageHome from '../../components/FlowPage/FlowPageHome'
import {useQuery} from 'react-query'
import FlowService from '../../service/flow'
import {Loader} from '../../components/Loader'
import { NewFlowPageHome } from '../../components/FlowPage/NewFlowPageHome'

const FlowPage = () => {
  const params = useParams()
  const navigate = useNavigate()
  const {id} = params
  const {data, isLoading, isError} = useQuery(['flow', id], async () => {
    return FlowService.get(id)
  })

  if (isError) {
    navigate('/404')
  }
  if (isLoading) {
    return <Loader />
  }
  return <NewFlowPageHome flowData={data} />
}

export {FlowPage}
