import axios from './index'

const getAll = async (params) => {
  try {
    const res = await axios.get('/area', {
      params,
    })

    return res?.data?.value
  } catch (error) {}
}
const remove = async (id) => {
  const res = await axios.delete(`/area/${id}`)
  return res?.data
}
const create = async (data) => {
  const res = await axios.post(`/area`, data)
  return res?.data
}
const get = async (id) => {
  const res = await axios.get(`/area/${id}`)
  return res?.data?.value
}

const update = async (data, id) => {
  const res = await axios.put(`/area/${id}`, data)
  return res?.data
}
const getNestedCategories = async () => {
  const res = await axios.get(`/area/GetNestedAreas`)
  return res?.data?.value
}

const CategoryService = {
  getAll,
  remove,
  create,
  get,
  update,
  getNestedCategories,
}

export default CategoryService
