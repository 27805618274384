/* eslint-disable jsx-a11y/anchor-is-valid */
import {PageTitle} from '../../_metronic/layout/core'
import {useIntl} from 'react-intl'
import EdgeDeviceManagemetTable from '../../components/EdgeDeviceManagemet/EdgeDeviceManagemetTable'

const EdgeDeviceManagementPage = () => {
  const intl = useIntl()

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.EDGEDEVICEMANAGEMENT'})}</PageTitle>
      <EdgeDeviceManagemetTable/>
    </>
  )
}

export {EdgeDeviceManagementPage}
