import DataTable from 'react-data-table-component-with-filter'
import {useNavigate} from 'react-router-dom'
import {Loader} from '../../components/Loader'
import {PageTitle} from '../../_metronic/layout/core'
import Swal from 'sweetalert2'
import {useMutation, useQuery} from 'react-query'
import {usePagination} from '../../hooks'
import FlowService from '../../service/flow'
import {useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {useIntl} from 'react-intl'

const FlowListPage = () => {
  const navigate = useNavigate()
  const intl = useIntl()
  const [showModal, setShowModal] = useState(false)
  const [flowName, setFlowName] = useState('')
  const [flowDescription, setFlowDescription] = useState('')

  const {setTotalRows, page, perPage, totalRows, handlePerRowsChange, handlePageChange} =
    usePagination()
  const {data, isLoading, refetch} = useQuery(['categories', page + perPage], async () =>
    FlowService.getAll({
      page,
      perPage,
    })
  )

  useEffect(() => {
    totalRows !== data?.totalCount && setTotalRows(data?.totalCount)
  }, [data?.totalCount])

  const mutation = useMutation(async (id) => await FlowService.remove(id))

  const columns = [
    {
      name: intl.formatMessage({id: 'FLOWNAME'}),
      selector: (row) => row.name,
      sortable: true,
      filterable: true,
    },
    {
      name: intl.formatMessage({id: 'ACTIONS'}),
      sortable: false,
      filterable: false,
      cell: (row) => {
        return (
          <>
            <div>
              <button
                disabled={mutation.isLoading}
                onClick={() => navigate(`/flows/${row.id}`)}
                className='btn btn-primary'
              >
                <i className='fa fa-pencil mx-2 '></i>
              </button>
            </div>
            <div>
              <button
                disabled={mutation.isLoading}
                onClick={async () => {
                  await Swal.fire({
                    title: intl.formatMessage({id: 'AREYOUSURE'}),
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: intl.formatMessage({id: 'YES'}),
                    cancelButtonText: intl.formatMessage({id: 'NO'}),
                  }).then(async (result) => {
                    if (result.isConfirmed) {
                      await mutation.mutateAsync(row.id)
                      Swal.fire({
                        title: intl.formatMessage({id: 'SUCCESS'}),
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 1500,
                      })
                      refetch()
                    }
                  })
                }}
                className='btn btn-danger mx-4'
              >
                <i className='fa fa-trash mx-2 '></i>
              </button>
            </div>
          </>
        )
      },
    },
  ]
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.FLOW'})}</PageTitle>
      <div className='card'>
        <div className='card-body p-4'>
          {isLoading ? (
            <div className='text-center mt-2 mb-2'>
              <Loader />
            </div>
          ) : (
            <>
              <div className='table-page-header p-5 my-3'>
                <div className='add-new-user '>
                  <button onClick={() => setShowModal(true)} className='btn btn-primary '>
                    <i className='fa fa-plus mx-2 '></i> {intl.formatMessage({id: 'ADDNEWFLOW'})}
                  </button>
                </div>
              </div>

              <DataTable
                progressPending={isLoading}
                progressComponent={<Loader />}
                className='table-striped custom-filtered-table'
                pagination
                paginationServer
                noDataComponent={
                  <div style={{margin: '25px 0px'}}>
                    {' '}
                    {intl.formatMessage({id: 'TABLE.NODATA'})}{' '}
                  </div>
                }
                paginationTotalRows={totalRows}
                paginationDefaultPage={page}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                customStyles={{
                  rows: {
                    style: {
                      cursor: 'pointer',
                      backgroundColor: 'var(--table-bg)',
                      '&:hover': {
                        backgroundColor: '#f3f3f3',
                      },
                    },
                  },
                }}
                columns={columns}
                data={data?.items}
              />
              <Modal
                className='modal fade'
                id='kt_modal_select_location'
                data-backdrop='static'
                tabIndex={-1}
                role='dialog'
                show={showModal}
                dialogClassName='modal-xl'
              >
                <div className='modal-content'>
                  <div className='modal-header'>
                    <h5 className='modal-title'> {intl.formatMessage({id: 'ADDNEWFLOW'})} </h5>
                  </div>
                  <div className='modal-body'>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                      placeholder={intl.formatMessage({id: 'FLOWNAME'})}
                      required
                      value={flowName}
                      onChange={(e) => setFlowName(e.target.value)}
                    />
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid mb-3 mb-lg-0 mt-4'
                      placeholder={intl.formatMessage({id: 'FLOWDESC'})}
                      required
                      value={flowDescription}
                      onChange={(e) => setFlowDescription(e.target.value)}
                    />
                  </div>
                  <div className='modal-footer'>
                    <button
                      type='button'
                      className='btn btn-light-primary'
                      onClick={() => setShowModal(false)}
                    >
                      {intl.formatMessage({id: 'CLOSE'})}
                    </button>
                    <button
                      id='submit'
                      type='button'
                      disabled={isLoading || !flowName}
                      className='btn btn-primary'
                      onClick={async () => {
                        setShowModal(false)
                        navigate('/flows/add-flow', {
                          state: {name: flowName, description: flowDescription},
                        })
                        refetch()
                      }}
                    >
                      {intl.formatMessage({id: 'SAVE'})}
                    </button>
                  </div>
                </div>
              </Modal>
            </>
          )}
        </div>
      </div>
    </>
  )
}

export {FlowListPage}
