import DataTable from 'react-data-table-component-with-filter'
import {useNavigate} from 'react-router-dom'
import {Loader} from '../../components/Loader'
import {PageTitle} from '../../_metronic/layout/core'
import Swal from 'sweetalert2'
import {useMutation, useQuery} from 'react-query'
import {usePagination} from '../../hooks'
import {useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import DashboardService from '../../service/dashboard'
import Select from 'react-select'
import {useIntl} from 'react-intl'

const DashboardManagementPage = () => {
  const intl = useIntl()

  const navigate = useNavigate()
  const [showModal, setShowModal] = useState(false)
  const [selectedInterval, setSelectedInterval] = useState({})
  const [name, setName] = useState('')

  const {setTotalRows, page, perPage, totalRows, handlePerRowsChange, handlePageChange} =
    usePagination()
  const {data, isLoading, refetch} = useQuery(['dashboards', page + perPage], async () =>
    DashboardService.getAll({
      page,
      perPage,
    })
  )
  const {data: defaultDashboard} = useQuery(['defaultDashboard'], async () => {
    return await DashboardService.getDefaultDashboard()
  })

  const [defaultId, setDefaultId] = useState('')

  useEffect(() => {
    totalRows !== data?.totalCount && setTotalRows(data?.totalCount)
  }, [data?.totalCount])

  useEffect(() => {
    setDefaultId(defaultDashboard?.id)
  }, [defaultDashboard])

  const mutation = useMutation(async (id) => await DashboardService.remove(id))

  const columns = [
    {
      name: intl.formatMessage({id: 'DASHBOARD.NAME'}),
      selector: (row) => row.name,
      sortable: true,
      filterable: true,
      cell: (row) => {
        return (
          <div className='d-flex align-items-center gap-sm'>
            <div className='d-flex align-items-center'>{row.name}</div>
          </div>
        )
      },
    },
    {
      name: intl.formatMessage({id: 'ACTIONS'}),
      sortable: false,
      filterable: false,
      cell: (row) => {
        return (
          <>
            <div>
              <a href={`/dashboard-management/${row.id}`} className='btn btn-primary'>
                <i className='fa fa-eye mx-2 '></i>
              </a>
            </div>
            <div>
              <button
                disabled={mutation.isLoading}
                onClick={async () => {
                  await Swal.fire({
                    title: intl.formatMessage({id: 'AREYOUSURE'}),
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: intl.formatMessage({id: 'YES'}),
                    cancelButtonText: intl.formatMessage({id: 'NO'}),
                  }).then(async (result) => {
                    if (result.isConfirmed) {
                      await mutation.mutateAsync(row.id)
                      Swal.fire({
                        title: intl.formatMessage({id: 'DELETED'}),
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 1500,
                      })
                      refetch()
                    }
                  })
                }}
                className='btn btn-danger mx-4'
              >
                <i className='fa fa-trash mx-2 '></i>
              </button>
            </div>
            <div className='d-flex align-items-center'>
              <button
                onClick={async () => {
                  await DashboardService.setDefaultDashboard(row.id)
                  Swal.fire({
                    title: intl.formatMessage({id: 'SUCCESS'}),
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 1500,
                  })
                  setDefaultId(row.id)
                  refetch()
                }}
                style={{
                  background: row.id === defaultId ? '#eed707' : '#422a2a',
                }}
                className='btn btn-primary'
              >
                <i className='fa fa-star'></i>
              </button>
            </div>
          </>
        )
      },
    },
  ]
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <div className='card'>
        <div className='card-body p-4'>
          {isLoading ? (
            <div className='text-center mt-2 mb-2'>
              <Loader />
            </div>
          ) : (
            <>
              <div className='table-page-header p-5 my-3'>
                <div className='add-new-user '>
                  <button onClick={() => setShowModal(true)} className='btn btn-primary '>
                    <i className='fa fa-plus mx-2 '></i>{' '}
                    {intl.formatMessage({id: 'MENU.ADDDASHBOARD'})}
                  </button>
                </div>
              </div>

              <DataTable
                progressPending={isLoading}
                progressComponent={<Loader />}
                className='table-striped custom-filtered-table'
                pagination
                paginationServer
                paginationTotalRows={totalRows}
                paginationDefaultPage={page}
                noDataComponent={
                  <div style={{margin: '25px 0px'}}>{intl.formatMessage({id: 'TABLE.NODATA'})}</div>
                }
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                customStyles={{
                  rows: {
                    style: {
                      cursor: 'pointer',
                      backgroundColor: 'var(--table-bg)',
                      '&:hover': {
                        backgroundColor: '#f3f3f3',
                      },
                    },
                  },
                }}
                columns={columns}
                data={data?.items}
              />
              <Modal
                className='modal fade'
                id='kt_modal_select_location'
                data-backdrop='static'
                tabIndex={-1}
                role='dialog'
                show={showModal}
                dialogClassName='modal-xl'
              >
                <div className='modal-content'>
                  <div className='modal-header'>
                    <h5 className='modal-title'>
                      {' '}
                      {intl.formatMessage({id: 'MENU.ADDDASHBOARD'})}
                    </h5>
                  </div>
                  <div className='modal-body'>
                    <input
                      type='text'
                      className='form-control form-control-lg mb-4 mb-lg-0'
                      placeholder={intl.formatMessage({id: 'DASHBOARD.NAME'})}
                      required
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                    <div className='mt-2 mb-2'></div>
                    <label htmlFor=''>{intl.formatMessage({id: 'DASHBOARD.SELECTRANGE'})}</label>
                    <Select
                      onChange={(e) => {
                        setSelectedInterval(e)
                      }}
                      value={selectedInterval}
                      placeholder={intl.formatMessage({id: 'DASHBOARD.SELECTRANGE'})}
                      options={[
                        {value: '10000', label: '10 ' + intl.formatMessage({id: 'SECOND'})},
                        {value: '20000', label: '20 ' + intl.formatMessage({id: 'SECOND'})},
                        {value: '30000', label: '30 ' + intl.formatMessage({id: 'SECOND'})},
                        {value: '45000', label: '45 ' + intl.formatMessage({id: 'SECOND'})},
                        {value: '60000', label: '1 ' + intl.formatMessage({id: 'MINUTE'})},
                        {value: '300000', label: '5 ' + intl.formatMessage({id: 'MINUTES'})},
                        ,
                      ]}
                    />
                  </div>
                  <div className='modal-footer'>
                    <button
                      type='button'
                      className='btn btn-light-primary'
                      onClick={() => setShowModal(false)}
                    >
                      {intl.formatMessage({id: 'CLOSE'})}
                    </button>
                    <button
                      id='submit'
                      type='button'
                      disabled={!name || !selectedInterval.value}
                      className='btn btn-primary'
                      onClick={async () => {
                        setShowModal(false)
                        const created = await DashboardService.create({
                          name,
                          selectedInterval,
                        })
                        navigate(`/dashboard-management/${created?.value?.id}`)
                      }}
                    >
                      {intl.formatMessage({id: 'CONTUNIE'})}
                    </button>
                  </div>
                </div>
              </Modal>
            </>
          )}
        </div>
      </div>
    </>
  )
}

export {DashboardManagementPage}
