import {Handle, Position} from 'reactflow'
import './nodes.module.css'

export default function InputNode(props) {
  return (
    <>
      <Handle
        type='source'
        position={Position.Right}
        isConnectableEnd={false}
        style={{
          width: '15px',
          height: '15px',
          position: 'absolute',
          right: '-5px',
          zIndex: 10,
          background: '#1e88e5',
          border:'2px solid #000'
        }}
      />

      <div
        style={{
          padding: '10px',
          border: `3px solid ${!props.selected ? '#bebebe' : '#1e88e5'}`,
          borderRadius: '20px',
          width: '200px',
          height: '75px',
          backgroundColor: 'white',
          position: 'relative',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <strong>{props.data.label ?? props?.data?.input?.inputValue?.label}</strong>
      </div>
    </>
  )
}
