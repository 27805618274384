import {useState} from 'react'

const usePagination = () => {
  const [perPage, setPerPage] = useState(10)
  const [page, setPage] = useState(1)
  const [totalRows, setTotalRows] = useState(0)

  const handlePageChange = (page) => {
    setPage(page)
  }

  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPage(newPerPage)
  }

  return {
    totalRows,
    perPage,
    page,
    handlePageChange,
    handlePerRowsChange,
    setTotalRows,
  }
}

export {usePagination}
