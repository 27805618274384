import React, {useEffect, useMemo, useState} from 'react'
import NodeConfigPanel from './NodeConfigPanel'
import Select from 'react-select'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import {Modal, Overlay, OverlayTrigger, Tooltip} from 'react-bootstrap'
import {v4 as uuid} from 'uuid'
import {useIntl} from 'react-intl'

import {ToastContainer, toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {KTIcon} from '../../_metronic/helpers'

function OutputConfiguration({
  selectedOutputNode,
  setSelectedOutputNode,
  nodes,
  setNodes,
  outputNodeData,
  setoutPutNodeData,
  inputNodeData,
}) {
  const [showModal, setShowModal] = useState(false)
  const [hasError, setHasError] = useState(false)
  const [tempData, setTempData] = useState([])
  const [selectedInput, setSelectedInput] = useState(null)
  const intl = useIntl()
  const selectedItemData = outputNodeData?.find((item) => item?.id === selectedOutputNode?.id)

  const isButtonDisabled = () => {
    if (selectedItemData?.inputValue?.value === 'email') {
      return !tempData[0]?.email?.email || !tempData[0]?.email?.message
    }
    if (selectedItemData?.inputValue?.value === 'sms') {
      return !tempData[0]?.phone?.number || !tempData[0]?.phone?.message
    }
    if (selectedItemData?.inputValue?.value === 'webhook') {
      try {
        JSON.parse(tempData[0]?.webHook?.json)
        return (
          !tempData[0]?.webHook?.endpoint
        )
      } catch (error) {
        console.log('error', error)
        return true
      }
    }
  }
  if (selectedItemData) {
    selectedItemData.inputValue = {
      value: selectedItemData?.inputValue?.value,
      label:
        selectedItemData?.inputValue?.value === 'sms'
          ? intl.formatMessage({id: 'SENDSMS'})
          : selectedItemData?.inputValue?.value === 'email'
          ? intl.formatMessage({id: 'SENDMAIL'})
          : 'Webhook',
    }
  }

  const relatedInputDatas = useMemo(() => {
    return inputNodeData.filter((item) => !!item?.inputValue) ?? []
  }, [inputNodeData])

  useEffect(() => {
    !showModal && setTempData([])
  }, [showModal])

  return (
    <NodeConfigPanel
      renderCondition={!!selectedOutputNode}
      title={intl.formatMessage({id: 'OUTPUTCONF'})}
      onClose={() => setSelectedOutputNode(null)}
    >
      <Select
        onChange={(e) => {
          const nodesClone = [...nodes.filter((node) => node.id !== selectedOutputNode.id)]
          nodesClone.push({
            ...selectedOutputNode,
            data: {
              label: e.label + 'sa',
              icon: `${e.value}.png`,
            },
          })
          setNodes(nodesClone)

          const outputNodeDataClone = [
            ...outputNodeData.filter((item) => item.id !== selectedOutputNode.id),
          ]
          setoutPutNodeData(
            outputNodeDataClone.concat({id: selectedOutputNode.id, inputValue: e, outputs: []})
          )
        }}
        value={selectedItemData?.inputValue ?? null}
        options={[
          {
            value: 'email',
            label: intl.formatMessage({id: 'SENDMAIL'}),
          },
          {
            value: 'sms',
            label: intl.formatMessage({id: 'SENDSMS'}),
          },
          {
            value: 'webhook',
            label: 'Webhook',
          },
        ]}
      />

      <div className='mt-4 mb-4'></div>

      <div
        style={{
          maxHeight: 310,
          overflowY: 'scroll',
        }}
      >
        {selectedItemData?.outputs?.map((item, index) => (
          <div key={item.id} className='mb-2'>
            <div
              style={{
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                padding: 12,
                border: '1px solid #e4e7eb',
                borderRadius: '5px',
                fontSize: 12,
              }}
            >
              {selectedItemData?.inputValue?.value === 'webhook' && (
                <strong className='d-block mb-2'>Webhook</strong>
              )}

              <div className='d-flex gap-2 justify-content-center'>
                <button
                  className='btn btn-primary btn-sm mb-2'
                  onClick={() => {
                    setShowModal(true)
                    setTempData([item])
                  }}
                >
                  {intl.formatMessage({id: 'EDIT'})}
                </button>
                <button
                  className='btn btn-danger btn-sm mb-2'
                  onClick={() => {
                    const clonneOutputNodeData = outputNodeData.filter(
                      (item) => item.id !== selectedOutputNode.id
                    )
                    setoutPutNodeData((prev) => {
                      return [
                        ...clonneOutputNodeData,
                        {
                          ...selectedItemData,
                          outputs: selectedItemData.outputs.filter(
                            (output) => output.id !== item.id
                          ),
                        },
                      ]
                    })
                  }}
                >
                  {intl.formatMessage({id: 'DELETE'})}
                </button>
              </div>

              <strong className='d-block'>
                {!!item?.email?.email && intl.formatMessage({id: 'CONTACT_MAIL'})}
              </strong>
              <strong className='d-block'>
                {!!item?.email?.email && intl.formatMessage({id: 'CONTACT_MAIL'})}
              </strong>
              {item?.email?.email}
              <strong className='d-block'>
                {!!item?.phone?.number && intl.formatMessage({id: 'PHONE'})}
              </strong>
              {item?.phone?.number}
              <strong className='d-block'>
                {!!item?.email?.subject && intl.formatMessage({id: 'SUBJECT'})}
              </strong>
              {item?.email?.subject}
              <br />
              <strong className='d-block'>
                {!!item?.email?.message && intl.formatMessage({id: 'MAILCONTENT'})}
              </strong>
              {item?.email?.message.split(0, 10)}
              <strong className='d-block'>
                {!!item?.phone?.message && intl.formatMessage({id: 'SMSCONTENT'})}
              </strong>
              {item?.phone?.message.split(0, 10)}
            </div>
          </div>
        ))}
      </div>

      {!!selectedItemData?.inputValue && (
        <button
          className='btn btn-primary mb-4 mt-4'
          onClick={() => {
            setShowModal(true)
            const initialTempData = [
              ...tempData,
              {
                id: uuid(),
                outputId: selectedOutputNode?.id,
                inputValue: selectedItemData?.inputValue,
              },
            ]

            if (selectedItemData?.inputValue?.value === 'webhook') {
              initialTempData[0].webHook = {
                json: '',
              }
              initialTempData[0].webHook.keyValues = [
                {
                  key: '',
                  value: '',
                  id: uuid(),
                },
              ]
            }
            setTempData(initialTempData)
          }}
        >
          {intl.formatMessage({id: 'ADDNEWOUTPUT'})}
        </button>
      )}

      <Modal
        className='modal fade'
        id='kt_modal_select_location'
        data-backdrop='static'
        tabIndex={-1}
        role='dialog'
        show={showModal}
        dialogClassName='modal-xl'
      >
        <div className='modal-header'>
          <h3>
            {intl.formatMessage({id: 'OUTPUT'})} {intl.formatMessage({id: 'EDIT'})}{' '}
          </h3>
        </div>
        <div className='modal-content'>
          <div
            className='modal-body'
            style={{
              maxHeight: 700,
              overflowY: 'scroll',
            }}
          >
            {tempData
              .filter((item) => item.outputId === selectedOutputNode?.id)
              .map((item, index) => (
                <div key={item.id}>
                  <div className='row align-center'>
                    <div className='col-12 col-md-9'>
                      <Select
                        onChange={(e) => {
                          setSelectedInput(e)
                        }}
                        options={relatedInputDatas?.map((item) => {
                          return item.inputValue
                        })}
                        value={selectedInput}
                        placeholder={intl.formatMessage({id: 'CHOOSE'})}
                      />
                    </div>
                    <div className='col-12 col-md-3'>
                      <button
                        className='btn btn-primary'
                        onClick={() => {
                          if (selectedItemData?.inputValue?.value === 'webhook') {
                            try {
                              //include to any inputs current cursor position
                              const textArea = document.getElementById('json-input')
                              const cursorPosition = textArea.selectionStart
                              const newText =
                                tempData[0]?.webHook?.json.slice(0, cursorPosition) +
                                `{{${selectedInput?.value}}}` +
                                tempData[0]?.webHook?.json.slice(cursorPosition)

                              setTempData((prev) => {
                                return [
                                  {
                                    ...prev[0],
                                    webHook: {
                                      ...prev[0]?.webHook,
                                      json: newText,
                                    },
                                  },
                                ]
                              })
                            } catch (error) {
                              console.log('error', error)
                            }

                            return
                          }

                          setTempData((prev) => {
                            if (selectedItemData?.inputValue?.value === 'email') {
                              return [
                                {
                                  ...prev[0],
                                  email: {
                                    ...prev[0]?.email,
                                    message: `${prev[0]?.email?.message || ''} {{${
                                      selectedInput?.value
                                    }}} `,
                                  },
                                },
                              ]
                            }
                            if (selectedItemData?.inputValue?.value === 'sms') {
                              return [
                                {
                                  ...prev[0],
                                  phone: {
                                    ...prev[0]?.phone,
                                    message: `${prev[0]?.phone?.message || ''} {{${
                                      selectedInput?.value
                                    }}} `,
                                  },
                                },
                              ]
                            }
                          })
                        }}
                        disabled={!selectedInput}
                      >
                        {selectedItemData?.inputValue?.value === 'webhook'
                          ? intl.formatMessage({id: 'INCLUDE JSON'})
                          : intl.formatMessage({id: 'INCLUDEMESSAGE'})}
                      </button>

                      {selectedItemData?.inputValue?.value === 'webhook' && (
                        <OverlayTrigger
                          placement='right'
                          delay={{show: 250, hide: 400}}
                          overlay={(props) => (
                            <Tooltip id='button-tooltip' {...props}>
                              <span className='btn-label'>
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: intl.formatMessage({id: 'JSONTOOLTIP'}),
                                  }}
                                />
                              </span>
                            </Tooltip>
                          )}
                        >
                          <span class='svg-icon svg-icon-primary svg-icon-2x' style={{marginLeft:12}}>
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              width='24px'
                              height='24px'
                              viewBox='0 0 24 24'
                              version='1.1'
                            >
                              <defs />
                              <g stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'>
                                <rect x='0' y='0' width='24' height='24' />
                                <circle fill='var(--bs-primary)' opacity='0.3' cx='12' cy='12' r='10' />
                                <rect fill='var(--bs-primary)' x='11' y='10' width='2' height='7' rx='1' />
                                <rect fill='var(--bs-primary)' x='11' y='7' width='2' height='2' rx='1' />
                              </g>
                            </svg>
                          </span>
                        </OverlayTrigger>
                      )}
                    </div>
                  </div>
                  {selectedItemData?.inputValue?.value === 'email' && (
                    <div>
                      <input
                        maxLength={60}
                        type='text'
                        className='form-control mt-2 mb-2'
                        placeholder={intl.formatMessage({id: 'SUBJECT'})}
                        value={tempData[0]?.email?.subject}
                        onChange={(e) => {
                          setTempData((prev) => {
                            return [
                              {
                                ...prev[0],
                                email: {
                                  ...prev[0]?.email,
                                  subject: e.target.value,
                                },
                              },
                            ]
                          })
                        }}
                      />

                      <input
                        maxLength={60}
                        type='email'
                        className='form-control mt-2 mb-2'
                        placeholder={intl.formatMessage({id: 'CONTACT_MAIL'})}
                        value={tempData[0]?.email?.email}
                        onChange={(e) => {
                          setTempData((prev) => {
                            return [
                              {
                                ...prev[0],
                                email: {
                                  ...prev[0]?.email,
                                  email: e.target.value,
                                },
                              },
                            ]
                          })
                        }}
                      />

                      <textarea
                        maxLength={100}
                        type='text'
                        className='form-control mt-2 mb-2'
                        placeholder={intl.formatMessage({id: 'MESSAGE'})}
                        value={tempData[0]?.email?.message}
                        onChange={(e) => {
                          setTempData((prev) => {
                            return [
                              {
                                ...prev[0],
                                email: {
                                  ...prev[0]?.email,
                                  message: e.target.value,
                                },
                              },
                            ]
                          })
                        }}
                      ></textarea>
                    </div>
                  )}
                  {selectedItemData?.inputValue?.value === 'webhook' && (
                    <div>
                      <input
                        maxLength={60}
                        type='text'
                        className='form-control mt-2 mb-2'
                        placeholder={intl.formatMessage({id: 'ENDPOINT'})}
                        value={tempData[0]?.webHook?.endpoint}
                        onChange={(e) => {
                          setTempData((prev) => {
                            return [
                              {
                                ...prev[0],
                                webHook: {
                                  ...prev[0]?.webHook,
                                  endpoint: e.target.value,
                                },
                              },
                            ]
                          })
                        }}
                      />

                      {tempData[0]?.webHook?.keyValues?.map((item, index) => (
                        <div key={item?.id}>
                          <div className='d-flex gap-2 align-items-center'>
                            <input
                              maxLength={60}
                              type='text'
                              className='form-control mt-2 mb-2'
                              placeholder={intl.formatMessage({id: 'KEY'})}
                              value={tempData[0]?.webHook?.keyValues[index]?.key}
                              onChange={(e) => {
                                setTempData((prev) => {
                                  return [
                                    {
                                      ...prev[0],
                                      webHook: {
                                        ...prev[0]?.webHook,
                                        keyValues: prev[0]?.webHook?.keyValues.map((endpoint) => {
                                          if (endpoint.id === item.id) {
                                            return {
                                              ...endpoint,
                                              key: e.target.value,
                                            }
                                          }
                                          return endpoint
                                        }),
                                      },
                                    },
                                  ]
                                })
                              }}
                            />
                            <input
                              maxLength={60}
                              type='text'
                              className='form-control mt-2 mb-2'
                              placeholder={intl.formatMessage({id: 'VALUE'})}
                              value={tempData[0]?.webHook?.keyValues[index]?.value}
                              onChange={(e) => {
                                setTempData((prev) => {
                                  return [
                                    {
                                      ...prev[0],
                                      webHook: {
                                        ...prev[0]?.webHook,
                                        keyValues: prev[0]?.webHook?.keyValues.map((endpoint) => {
                                          if (endpoint.id === item.id) {
                                            return {
                                              ...endpoint,
                                              value: e.target.value,
                                            }
                                          }
                                          return endpoint
                                        }),
                                      },
                                    },
                                  ]
                                })
                              }}
                            />
                            {index !== 0 && (
                              <button
                                className='btn btn-danger'
                                onClick={() => {
                                  setTempData((prev) => {
                                    return [
                                      {
                                        ...prev[0],
                                        webHook: {
                                          ...prev[0]?.webHook,
                                          keyValues: prev[0]?.webHook?.keyValues.filter(
                                            (endpoint) => endpoint.id !== item.id
                                          ),
                                        },
                                      },
                                    ]
                                  })
                                }}
                              >
                                -
                              </button>
                            )}
                            {index === 0 && (
                              <button
                                className='btn btn-primary'
                                onClick={() => {
                                  setTempData((prev) => {
                                    return [
                                      {
                                        ...prev[0],
                                        webHook: {
                                          ...prev[0]?.webHook,
                                          keyValues: [
                                            ...(prev[0]?.webHook?.keyValues ?? []),
                                            {
                                              key: '',
                                              value: '',
                                              id: uuid(),
                                            },
                                          ],
                                        },
                                      },
                                    ]
                                  })
                                }}
                              >
                                +
                              </button>
                            )}
                          </div>
                        </div>
                      ))}

                      <div
                        style={{
                          marginTop: 20,
                        }}
                      >
                        <strong>JSON</strong>
                      </div>
                      <button
                        className='btn btn-primary'
                        onClick={() => {
                          try {
                            setHasError(false)
                            const parsedJson = JSON.parse(tempData[0]?.webHook?.json)
                            const prettyJson = JSON.stringify(parsedJson, null, 2)
                            setTempData((prev) => {
                              return [
                                {
                                  ...prev[0],
                                  webHook: {
                                    ...prev[0]?.webHook,
                                    json: prettyJson,
                                  },
                                },
                              ]
                            })
                          } catch (error) {
                            console.log('error', error)
                            setHasError(true)
                            toast.error(intl.formatMessage({id: 'JSONFORMATINVALID'}))
                          }
                        }}
                      >
                        Format JSON
                      </button>
                      <textarea
                        value={tempData[0]?.webHook?.json}
                        style={{
                          height: 200,
                          borderColor:
                            tempData[0]?.webHook?.json?.length === 0
                              ? 'black'
                              : hasError
                              ? 'red'
                              : '#1e88e5',
                          borderWidth: 1,
                          borderStyle: 'solid',
                        }}
                        id='json-input'
                        className='form-control mt-2 mb-2'
                        onChange={(e) => {
                          try {
                            setTempData((prev) => {
                              return [
                                {
                                  ...prev[0],
                                  webHook: {
                                    ...prev[0]?.webHook,
                                    json: e.target.value,
                                  },
                                },
                              ]
                            })
                          } catch (error) {
                            console.log('error', error)
                          }
                        }}
                      >
                        {JSON.stringify(tempData[0]?.webHook?.json, null, 2)}
                      </textarea>
                    </div>
                  )}

                  {selectedItemData?.inputValue?.value === 'sms' && (
                    <div>
                      <PhoneInput
                        country={'tr'}
                        onlyCountries={['tr']}
                        maxLength={60}
                        className='mt-2 mb-2 w-100'
                        inputStyle={{
                          width: '100%',
                        }}
                        placeholder='Telefon Numarası'
                        value={tempData[0]?.phone?.number}
                        onChange={(phone) => {
                          setTempData((prev) => {
                            return [
                              {
                                ...prev[0],
                                phone: {
                                  ...prev[0]?.phone,
                                  number: phone,
                                },
                              },
                            ]
                          })
                        }}
                      />
                      <textarea
                        maxLength={100}
                        type='text'
                        className='form-control mt-2 mb-2'
                        placeholder={intl.formatMessage({id: 'MESSAGE'})}
                        value={tempData[0]?.phone?.message}
                        onChange={(e) => {
                          setTempData((prev) => {
                            return [
                              {
                                ...prev[0],
                                phone: {
                                  ...prev[0]?.phone,
                                  message: e.target.value,
                                },
                              },
                            ]
                          })
                        }}
                      ></textarea>
                    </div>
                  )}
                </div>
              ))}
          </div>
          <div className='modal-footer'>
            <button
              type='button'
              className='btn btn-light-primary'
              onClick={() => setShowModal(false)}
            >
              {intl.formatMessage({id: 'CLOSE'})}
            </button>
            <button
              id='submit'
              type='button'
              className='btn btn-primary'
              disabled={isButtonDisabled()}
              onClick={async () => {
                setTempData([])
                setShowModal(false)

                if (selectedItemData?.outputs?.find((item) => item.id === tempData[0]?.id)) {
                  const cloneOutputNodeData = outputNodeData.filter(
                    (item) => item.id !== selectedOutputNode.id
                  )

                  setoutPutNodeData((prev) => {
                    return [
                      ...cloneOutputNodeData,
                      {
                        ...selectedItemData,
                        outputs: selectedItemData.outputs.map((output) => {
                          if (output.id === tempData[0].id) {
                            return tempData[0]
                          }
                          return output
                        }),
                      },
                    ]
                  })

                  return
                }

                const cloneOutputNodeData = outputNodeData.filter(
                  (item) => item.id !== selectedOutputNode.id
                )

                setoutPutNodeData((prev) => {
                  return [
                    ...cloneOutputNodeData,
                    {
                      ...selectedItemData,
                      outputs: [...selectedItemData?.outputs, tempData[0]],
                    },
                  ]
                })
              }}
            >
              {intl.formatMessage({id: 'SAVE'})}
            </button>
          </div>
        </div>
      </Modal>
    </NodeConfigPanel>
  )
}

export default OutputConfiguration
