import Swal from 'sweetalert2'
import {useNavigate} from 'react-router-dom'
import {useQuery} from 'react-query'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {useIntl} from 'react-intl'
import DeviceService from '../../service/device'
import TagService from '../../service/tag'
import RentalService from '../../service/rental'

const AddNewRentalCompanyPage = () => {
  const navigate = useNavigate()
  const intl = useIntl()

  const formik = useFormik({
    initialValues: {
      name: '',
      address: '',
      phoneNumber: '',
      gsmNumber: '',
      mailAddress: '',
    },
    // isInitialValid: false,
    // validationSchema: Yup.object().shape({
    //   edgeDeviceId: Yup.number().required(intl.formatMessage({id: 'REQUIRED'})),
    //   runTagId: Yup.number().required(intl.formatMessage({id: 'REQUIRED'})),
    //   errorTagId: Yup.number().required(intl.formatMessage({id: 'REQUIRED'})),
    //   stopTagId: Yup.number().required(intl.formatMessage({id: 'REQUIRED'})),
    //   stopFunction: Yup.number().required(intl.formatMessage({id: 'REQUIRED'})),
    // }),
    onSubmit: async (values) => {
      await RentalService.createRentalCompany(values)
      Swal.fire({
        type: 'success',
        title: intl.formatMessage({id: 'SUCCESS'}),
        showConfirmButton: false,
        timer: 1500,
        icon: 'success',
      })
      navigate('/rental-company')
    },
  })

  return (
    <div className='card'>
      <div className='card-body p-4'>
        <button onClick={() => navigate('/rental-company')} className='btn btn-primary'>
          {intl.formatMessage({id: 'AUTH.GENERAL.BACK_BUTTON'})}
        </button>

        <div id='kt_account_profile_details' className='collapse show'>
          <form noValidate className='form'>
            <div className='card-body p-9'>
              <div className='row mb-6'>
                <label className='col-lg-3 col-form-label fw-bold fs-6'>
                  {intl.formatMessage({id: 'NAME'})}
                </label>
                <div className='col-lg-9'>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    name='name'
                    value={formik.values.name}
                    onChange={formik.handleChange}
                  />
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-3 col-form-label fw-bold fs-6'>
                  {intl.formatMessage({id: 'ADDRESS'})}
                </label>
                <div className='col-lg-9'>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    name='address'
                    value={formik.values.address}
                    onChange={formik.handleChange}
                  />
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-3 col-form-label fw-bold fs-6'>
                  {intl.formatMessage({id: 'PHONE'})}
                </label>
                <div className='col-lg-9'>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    name='phoneNumber'
                    value={formik.values.phoneNumber}
                    onChange={formik.handleChange}
                  />
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-3 col-form-label fw-bold fs-6'>
                  {intl.formatMessage({id: 'GSM'})}
                </label>
                <div className='col-lg-9'>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    name='gsmNumber'
                    value={formik.values.gsmNumber}
                    onChange={formik.handleChange}
                  />
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-3 col-form-label fw-bold fs-6'>
                  {intl.formatMessage({id: 'CONTACT_MAIL'})}
                </label>
                <div className='col-lg-9'>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    name='mailAddress'
                    value={formik.values.mailAddress}
                    onChange={formik.handleChange}
                  />
                </div>
              </div>
            </div>

            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <button
                disabled={!formik.isValid || formik.isSubmitting}
                type='submit'
                onClick={formik.handleSubmit}
                className='btn  btn-primary '
              >
                {intl.formatMessage({id: 'SAVE'})}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export {AddNewRentalCompanyPage}
