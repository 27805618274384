/* eslint-disable jsx-a11y/anchor-is-valid */
import {PageTitle} from '../../_metronic/layout/core'
import {useIntl} from 'react-intl'
import AccountManagementTable from '../../components/AccountManagement/AccountManagementTable'

const AccountManagementPage = () => {
  const intl = useIntl()

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.ACCOUNTMANAGEMENT'})}</PageTitle>
      <AccountManagementTable/>
    </>
  )
}

export {AccountManagementPage}
