import React, {useEffect, useState} from 'react'
import {useQuery} from 'react-query'
import BrandService from '../service/brand'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import DeviceService from '../service/device'
import Swal from 'sweetalert2'
import SourceTypeService from '../service/sourceType'
import {Loader} from './Loader'
import {renderSourceTypeField} from '../utils'
import DataTable from 'react-data-table-component-with-filter'
import CategoryService from '../service/category'
import TagService from '../service/tag'
import {useNavigate} from 'react-router-dom'
import {useIntl} from 'react-intl'

function PackageProductForm({setIsSecondPage, isSecondPage}) {
  const intl = useIntl()
  const [insertedDeviceId, setInsertedDeviceId] = useState(null)
  const [deviceInsertLoading, setInsertDeviceLoading] = useState(false)
  const [tagInsertLoading, setTagInsertLoading] = useState(false)
  const [selectedTags, setSelectedTags] = useState([])
  const [selectedCategoryId, setSelectedCategoryId] = useState(null)
  const navigate = useNavigate()
  console.log(selectedTags)
  const validationSchema = Yup.object().shape({
    name: Yup.string().required(),
    description: Yup.string().nullable(),
    brandId: Yup.string().required(),
    modelId: Yup.string().required(),
    sourceType: Yup.object().nullable(),
    sourceDataKeyValue: Yup.object().required(),
    edgeDeviceId: Yup.number().required(),
  })

  const formik = useFormik({
    initialValues: {
      name: '',
      description: '',
      brandId: null,
      modelId: null,
      sourceType: null,
      edgeDeviceId: null,
      sourceDataKeyValue: {},
    },
    isInitialValid: false,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setInsertDeviceLoading(true)
      const sourceDataKeyValueObject = Object.values(values.sourceDataKeyValue)
      const sourceDataKeyValue = []
      sourceDataKeyValueObject.forEach((item) => {
        sourceDataKeyValue.push({
          sourceDataKeyId: item.sourceDataKeyId,
          value: item.value,
        })
      })

      DeviceService.create({
        name: values.name,
        description: values.description,
        brandId: values.brandId,
        modelId: values.modelId,
        sourceTypeId: values.sourceType.sourceTypeId,
        sourceDataKeyValue: sourceDataKeyValue,
        edgeDeviceId: values.edgeDeviceId,
      })
        .then((res) => {
          setInsertedDeviceId(res?.value?.id)
          setIsSecondPage(true)
        })
        .catch((err) => {
          console.log(err)
          Swal.fire({
            type: 'error',
            title: 'Hata',
            text: 'Cihaz oluşturulamadı.',
            showConfirmButton: false,
            timer: 1500,
            icon: 'error',
          })
        })
        .finally(() => {
          setInsertDeviceLoading(false)
        })
      // Swal.fire({
      //   type: 'success',
      //   title: 'Başarılı',
      //   text: 'Cihaz başarıyla oluşturuldu.',
      //   showConfirmButton: false,
      //   timer: 1500,
      //   icon: 'success',
      // })
      // navigate('/device-management')
    },
  })

  const {data: brands, isLoading: isBrandsLoading} = useQuery(['brands'], async () =>
    BrandService.getAll({page: 1, perPage: 1000})
  )

  const {data: models} = useQuery(
    ['getModelWithBrandId', formik.values.brandId],
    async () =>
      formik.values.brandId && BrandService.getModelWithBrandId({id: formik.values.brandId})
  )

  const {data: orderNumbers} = useQuery(
    ['orderNumbers', formik.values.brandId],
    async () =>
      formik.values.brandId && BrandService.getPackageDeviceByModelId({id: formik.values.brandId})
  )

  const {data: sourceDataKeys, isLoading: isSourceDataKeysLoading} = useQuery(
    ['sourceDataKeys', formik.values.sourceType],
    async () =>
      formik.values.sourceType &&
      SourceTypeService.getSourceDataKeysWithSourceTypeId(formik.values.sourceType?.sourceTypeId)
  )
  const {data: packageTags, isLoading: isPackageTagsLoading} = useQuery(
    ['packageTags', insertedDeviceId, formik.values.sourceType],
    async () =>
      insertedDeviceId &&
      DeviceService.getPackageTagWithPackageDeviceId(formik.values.sourceType.id)
  )
  const {data: edgeDevices} = useQuery('edgeDevices', async () =>
    DeviceService.getAllEdgeDevices({page: 1, perPage: 1000})
  )

  const {data: categories} = useQuery('categories', async () =>
    CategoryService.getAll({
      page: 1,
      perPage: 1000,
    })
  )

  useEffect(() => {
    formik.setFieldValue('sourceDataKeyValue', [])
  }, [formik.values.selectedTypeId])

  useEffect(() => {
    if (categories?.items?.length === 1) {
      setSelectedCategoryId(categories?.items[0].id)
    }
  }, [categories])

  const buttonIsDisabled = () => {
    if (
      !formik.isValid ||
      formik.isSubmitting ||
      isBrandsLoading ||
      isSourceDataKeysLoading ||
      deviceInsertLoading
    ) {
      return true
    }

    if (!sourceDataKeys?.length || sourceDataKeys?.length === 0) {
      return true
    }

    if (
      sourceDataKeys?.length > 0 &&
      sourceDataKeys?.length !== Object.keys(formik.values.sourceDataKeyValue)?.length
    ) {
      return true
    }

    return false
  }

  if (isSecondPage) {
    const columns = [
      {
        name: 'Ad',
        selector: (row) => row.name,
        sortable: true,
        filterable: true,
      },
      {
        name: 'Adres',
        selector: (row) => row.address,
        sortable: true,
        filterable: true,
      },
      {
        name: 'İşlemler',
        sortable: false,
        filterable: false,
        cell: (row) => {
          const isSelected = selectedTags.some((item) => item.id === row.id)
          return (
            <>
              <div>
                <button
                  onClick={() => {
                    let selectedTagsClone = [...selectedTags]
                    if (selectedTagsClone.some((item) => item.id === row.id)) {
                      selectedTagsClone = selectedTagsClone.filter((item) => item.id !== row.id)
                    } else {
                      selectedTagsClone.push(row)
                    }
                    setSelectedTags(selectedTagsClone)
                  }}
                  style={{
                    cursor: 'pointer!important',
                  }}
                  className={`btn ${isSelected ? 'btn-danger' : 'btn-dark'} mx-4 cursor-pointer`}
                >
                  {isSelected ? 'Kaldır' : 'Ekle'}
                </button>
              </div>
            </>
          )
        },
      },
    ]

    return (
      <div>
        <div className='row mb-6'>
          <label className='col-lg-3 col-form-label fw-bold fs-6'>
            {intl.formatMessage({id: 'CATEGORY'})}:{' '}
          </label>

          <div className='col-lg-9'>
            <div className='row'>
              <div className='col-lg-12 fv-row'>
                <select
                  value={selectedCategoryId}
                  onChange={(e) => {
                    setSelectedCategoryId(e.target.value)
                  }}
                  name='categoryId'
                  className='form-select form-control-solid'
                >
                  <option selected>{intl.formatMessage({id: 'CATEGORY'})}</option>
                  {categories?.items?.map((item) => (
                    <option value={item.id}>{item.name}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            maxHeight: 380,
            overflowY: 'auto',
          }}
        >
          <DataTable
            progressPending={isPackageTagsLoading}
            progressComponent={<Loader />}
            className='table-striped custom-filtered-table'
            noDataComponent={
              <div style={{margin: '25px 0px'}}>{intl.formatMessage({id: 'TABLE.NODATA'})}</div>
            }
            customStyles={{
              rows: {
                style: {
                  cursor: 'pointer',
                  backgroundColor: 'var(--table-bg)',
                  '&:hover': {
                    backgroundColor: '#f3f3f3',
                  },
                },
              },
            }}
            columns={columns}
            data={packageTags?.value ?? []}
          />
        </div>
        <div className='card-footer d-flex justify-content-between py-6 px-9'>
          <div className='select-buttons'>
            <button
              onClick={async () => {
                const selectAll = []
                packageTags?.value?.map((item) => {
                  selectAll.push(item)
                })
                console.log(selectAll)
                setSelectedTags(selectAll)
              }}
              type='button'
              className='btn  btn-primary pull-left'
            >
              {intl.formatMessage({id: 'SELECTALL'})}
            </button>
            {selectedTags.length > 0 && (
              <button
                onClick={async () => {
                  setSelectedTags([])
                }}
                type='button'
                className='btn  btn-danger mx-5 pull-left'
              >
                {intl.formatMessage({id: 'UNSELECTALL'})}
              </button>
            )}
          </div>

          <button
            disabled={selectedTags?.length === 0 || !selectedCategoryId || tagInsertLoading}
            onClick={async () => {
              setTagInsertLoading(true)
              await TagService.createMany(
                selectedTags.map((item) => {
                  return {
                    deviceId: insertedDeviceId,
                    name: item.name,
                    address: item.address,
                    dataTypeId: item.dataTypeId,
                    registerOrder: item.registerOrder,
                    areaId: selectedCategoryId,
                  }
                })
              )

              Swal.fire({
                type: 'success',
                title: 'Başarılı',
                text: 'Cihaz başarıyla oluşturuldu.',
                showConfirmButton: false,
                timer: 1500,
                icon: 'success',
              })
              navigate('/device-management')
              setTagInsertLoading(true)
            }}
            type='button'
            className='btn  btn-primary'
          >
            {intl.formatMessage({id: 'SAVE'})}
          </button>
        </div>
      </div>
    )
  }
  return (
    <div>
      <div className='row mb-6'>
        <label className='col-lg-3 col-form-label fw-bold fs-6'>
          {' '}
          {intl.formatMessage({id: 'EDGEDEVICE'})}{' '}
        </label>
        <div className='col-lg-9'>
          <div className='row'>
            <div className='col-lg-12 fv-row'>
              <select
                name='edgeDeviceId'
                className='form-select form-control-solid'
                onChange={(e) => {
                  formik.setFieldValue('edgeDeviceId', parseInt(e.target.value))
                }}
              >
                <option>{intl.formatMessage({id: 'CHOOSE'})}</option>
                {edgeDevices?.items?.map((data) => (
                  <option
                    selected={formik?.edgeDeviceId === data.id}
                    value={data.id}
                    key={data.name}
                  >
                    {data.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      </div>
      <div className='row mb-6'>
        <label className='col-lg-3 col-form-label fw-bold fs-6'>
          {intl.formatMessage({id: 'BRAND'})}
        </label>
        <div className='col-lg-9'>
          <div className='row'>
            <div className='col-lg-12 fv-row'>
              <select
                name='brandId'
                onChange={(e) => {
                  formik.setFieldValue('brandId', e.target.value)
                }}
                className='form-select form-control-solid'
              >
                <option>{intl.formatMessage({id: 'CHOOSE'})}</option>
                {brands?.items?.map((brand) => (
                  <option value={brand.id} key={brand.id}>
                    {brand.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      </div>
      <div className='row mb-6'>
        <label className='col-lg-3 col-form-label fw-bold fs-6'>
          {intl.formatMessage({id: 'MODEL'})}
        </label>
        <div className='col-lg-9'>
          <div className='row'>
            <div className='col-lg-12 fv-row'>
              <select
                name='brandId'
                className='form-select form-control-solid'
                onChange={(e) => {
                  formik.setFieldValue('modelId', e.target.value)
                }}
              >
                <option>{intl.formatMessage({id: 'CHOOSE'})}</option>
                {models?.map((model) => (
                  <option
                    value={model.id}
                    key={model.name}
                    selected={formik?.values?.modelId === model.id}
                  >
                    {model.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      </div>
      <div className='row mb-6'>
        <label className='col-lg-3 col-form-label fw-bold fs-6'>
          {intl.formatMessage({id: 'ORDERNO'})}
        </label>
        <div className='col-lg-9'>
          <div className='row'>
            <div className='col-lg-12 fv-row'>
              <select
                name='parent'
                className='form-select form-control-solid'
                onChange={(e) => {
                  formik.setFieldValue(
                    'sourceType',
                    orderNumbers.find((item) => item.id === parseInt(e.target.value))
                  )
                }}
              >
                <option>{intl.formatMessage({id: 'CHOOSE'})}</option>
                {orderNumbers?.map((model) => (
                  <option
                    value={model.id}
                    key={model.name}
                    selected={formik?.values?.orderNumber === model.id}
                  >
                    {model.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      </div>
      <div className='row mb-6'>
        <label className='col-lg-3 col-form-label required fw-bold fs-6'>
          {intl.formatMessage({id: 'NAME'})}
        </label>
        <div className='col-lg-9'>
          <div className='row'>
            <div className='col-lg-12 fv-row'>
              <input
                type='text'
                className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                placeholder={intl.formatMessage({id: 'NAME'})}
                required
                value={formik.values.name}
                onChange={formik.handleChange}
                name='name'
              />
            </div>
          </div>
        </div>
      </div>

      <div className='row mb-6'>
        <label className='col-lg-3 col-form-label fw-bold fs-6'>
          {intl.formatMessage({id: 'DESCRIPTION'})}
        </label>
        <div className='col-lg-9'>
          <div className='row'>
            <div className='col-lg-12 fv-row'>
              <textarea
                type='text'
                className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                placeholder={intl.formatMessage({id: 'DESCRIPTION'})}
                name='description'
                value={formik.values.description}
                onChange={formik.handleChange}
              />
            </div>
          </div>
        </div>
      </div>

      {isSourceDataKeysLoading && <Loader />}
      {sourceDataKeys?.map((dataKey) => (
        <div className='row mb-6'>
          <label className='col-lg-3 col-form-label required fw-bold fs-6'>{dataKey.name}</label>
          <div className='col-lg-9'>
            <div className='row'>
              <div className='col-lg-12 fv-row'>{renderSourceTypeField(dataKey, formik)}</div>
            </div>
          </div>
        </div>
      ))}

      <div className='card-footer d-flex justify-content-end py-6 px-9'>
        <button
          disabled={buttonIsDisabled()}
          onClick={formik.handleSubmit}
          type='button'
          className='btn  btn-primary'
        >
          {intl.formatMessage({id: 'SAVEANDCONTINUE'})}
        </button>
      </div>
    </div>
  )
}

export default PackageProductForm
