import React, {useEffect, useState} from 'react'
import {DndProvider} from 'react-dnd'
import {useDrop} from 'react-dnd'
import {HTML5Backend} from 'react-dnd-html5-backend'
import {Dropdown, Modal} from 'react-bootstrap'
import Select from 'react-select'
import Gauge from './DragAndDrop/Gauge'
import Line from './DragAndDrop/Line'
import Bar from './DragAndDrop/Bar'
import TrueFalse from './DragAndDrop/TrueFalse'
import Swal from 'sweetalert2'
import '/node_modules/react-grid-layout/css/styles.css'
import '/node_modules/react-resizable/css/styles.css'
import {Responsive as ResponsiveGridLayout} from 'react-grid-layout'
import {useIntl} from 'react-intl'
import ReactECharts from 'echarts-for-react'

import DashboardService from '../service/dashboard'
import {Loader} from './Loader'
import Machine from './DragAndDrop/Machine'

const ItemTypes = {
  GAUGE: 'gauge',
  LINE: 'line',
  BAR: 'bar',
  BOOLEAN: 'boolean',
  MACHINE: 'machine',
}

const DropTarget = ({
  setBoxes,
  boxes,
  tagsList,
  dashboardDetail,
  barValues,
  fetchDashboardData,
  fetchBarLineValues,
}) => {
  const intl = useIntl()

  const [editItem, setEditItem] = useState(null)
  const [selectedTag, setSelectedTag] = useState(null)
  const [hasErrorTag, setHasErrorTag] = useState(null)
  const [isRunningTag, setIsRunningTag] = useState(null)
  const [selectedChartInterval, setSelectedChartInterval] = useState('')
  const [selectedCalculationType, setSelectedCalculationType] = useState('')
  const [showCalculationType, setShowCalculationType] = useState(true)

  const [name, setName] = useState('')
  const [minValue, setMinValue] = useState(null)
  const [maxValue, setMaxValue] = useState(null)

  const [{canDrop, isOver}, drop] = useDrop({
    accept: [ItemTypes.GAUGE, ItemTypes.LINE, ItemTypes.BAR, ItemTypes.BOOLEAN, ItemTypes.MACHINE],
    drop: (item) => {
      setEditItem(item)
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  })

  useEffect(() => {
    if (!!editItem) {
      setName(editItem.name)

      if (editItem.type === 'gauge') {
        setMinValue(editItem.data.gauge.minValue)
        setMaxValue(editItem.data.gauge.maxValue)
      }
      if (editItem.type === 'line' || editItem.type === 'bar') {
        if (editItem.selectedChartInterval?.value.toLowerCase() === 'live') {
          setShowCalculationType(false)
          editItem.selectedChartInterval.label = intl.formatMessage({id: 'LATESTDATA'})
        }
        if (editItem.selectedChartInterval?.value.toLowerCase() === 'daily') {
          editItem.selectedChartInterval.label = intl.formatMessage({id: 'DAILY'})
        }
        if (editItem.selectedChartInterval?.value.toLowerCase() === 'hourly') {
          editItem.selectedChartInterval.label = intl.formatMessage({id: 'HOURLY'})
        }
        if (editItem.selectedChartInterval?.value.toLowerCase() === 'weekly') {
          editItem.selectedChartInterval.label = intl.formatMessage({id: 'WEEKLY'})
        }
        if (editItem.selectedChartInterval?.value.toLowerCase() === 'monthly') {
          editItem.selectedChartInterval.label = intl.formatMessage({id: 'MONTHLY'})
        }
        if (editItem.selectedCalculationType?.value.toLowerCase() === 'min') {
          editItem.selectedCalculationType.label = intl.formatMessage({id: 'MINIMUM'})
        }
        if (editItem.selectedCalculationType?.value.toLowerCase() === 'max') {
          editItem.selectedCalculationType.label = intl.formatMessage({id: 'MAXIMUM'})
        }
        if (editItem.selectedCalculationType?.value.toLowerCase() === 'avg') {
          editItem.selectedCalculationType.label = intl.formatMessage({id: 'AVERAGE'})
        }

        setSelectedChartInterval(editItem.selectedChartInterval)
        setSelectedCalculationType(editItem.selectedCalculationType)
      }

      if (editItem.type === 'machine') {
        setHasErrorTag(editItem.hasErrorTag)
        setIsRunningTag(editItem.isRunningTag)
      } else {
        setSelectedTag(editItem.selectedTag || editItem.selectedTags)
      }
    }
  }, [editItem])

  const isActive = canDrop && isOver
  let backgroundColor = 'white'
  if (boxes.length === 0) {
    backgroundColor = '#e1e1e1'
  }
  if (isActive) {
    backgroundColor = 'lightgreen'
  } else if (canDrop) {
    backgroundColor = 'lightyellow'
  }

  const isEditButtonDisabled = () => {

    if(editItem?.type === 'machine' && (!isRunningTag || !hasErrorTag || !name)) {
      return true
    }
    if (editItem?.type !== 'machine' && (!selectedTag || !name)) {
      return true
    }

    if (editItem.type === 'gauge') {
      if (!minValue || !maxValue) {
        return true
      }

      if (Number(minValue) >= Number(maxValue)) {
        return true
      }
    }

    return false
  }

  return (
    <div
      ref={drop}
      className=''
      style={{
        backgroundColor,
        textAlign: 'center',
        minHeight: 400,
      }}
    >
      <ResponsiveGridLayout
        draggableHandle='.drag-handle'
        breakpoints={{lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0}}
        cols={{lg: 12, md: 10, sm: 6, xs: 4, xxs: 2}}
        onLayoutChange={(newLayoutItems) => {
          const clonedBoxes = [...boxes]
          const boxData = clonedBoxes.map((item) => {
            const newItem = newLayoutItems.find((i) => i.i === item.id)
            return {
              ...item,
              dataGrid: newItem,
            }
          })

          DashboardService.update(dashboardDetail?.id, {
            ...dashboardDetail,
            graphs: boxData,
          })

          setBoxes(boxData)
        }}
        draggableCancel='.cancelSelectorName'
        autoSize
        className='layout'
        rowHeight={60}
        width={1600}
      >
        {boxes.map((box) => (
          <div
            style={{
              backgroundColor:
                box.type === 'boolean'
                  ? !!barValues?.find((item) => item.id === box?.id)?.boolean
                    ? '#46df2b'
                    : '#df2e2b'
                  : '#ffffff',
              padding: box.type === 'machine' ? 0 : 20,
              // border: '1px solid black',
              borderRadius: 6,
              border:
                box.type === 'machine' || box.type === 'boolean'
                  ? '2px solid #e3e3e3'
                  : '2px solid var(--bs-primary)',
            }}
            data-grid={{
              ...box.dataGrid,
            }}
            key={box.id}
            className='draggable-chart-box'
          >
            <div
              className='d-flex justify-content-between align-items-center gap-sm'
              style={{
                padding: box.type === 'machine' ? '10px' : 0,
                backgroundColor: box.type === 'machine' ? '#f3f3f3' : 'transparent',
              }}
            >
              <div
                className='drag-handle'
                style={{
                  cursor: 'move',
                }}
              >
                <img src='/images/draggable.png' width='30' />
              </div>
              {box.type !== 'boolean' && <h3 className='mb-0'>{box?.name}</h3>}
              <div className='d-flex gap-2 justify-content-center mt-2' style={{}}>
                <Dropdown className='dropdown'>
                  <Dropdown.Toggle variant='link' id='dropdown-basic' className='pt-0'>
                    <i
                      style={{
                        color: '#000',
                      }}
                      className='fa fa-cog'
                    ></i>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => setEditItem(box)}>
                      {intl.formatMessage({id: 'EDIT'})}
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        Swal.fire({
                          title: 'Emin misiniz?',
                          text: 'Bu islemi geri alamazsiniz!',
                          icon: 'warning',
                          showCancelButton: true,
                          confirmButtonText: 'Evet, sil!',
                          cancelButtonText: 'Iptal',
                        }).then((result) => {
                          if (result.isConfirmed) {
                            setBoxes((prev) => {
                              return prev.filter((item) => item.id !== box.id)
                            })
                          }
                        })
                      }}
                    >
                      {intl.formatMessage({id: 'DELETE'})}
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
            {box.type === 'gauge' && (
              <div
                style={{
                  height: '85%',
                }}
              >
                <ReactECharts
                  option={{
                    response: true,
                    series: [
                      {
                        name: '',
                        type: 'gauge',
                        progress: {
                          show: true,
                        },
                        detail: {
                          valueAnimation: true,
                          formatter: '{value}',
                        },
                        min:
                          barValues?.find((item) => item.id === box?.id)?.minValue ??
                          box.data?.gauge?.minValue,
                        max:
                          barValues?.find((item) => item.id === box?.id)?.maxValue ??
                          box.data?.gauge?.maxValue,
                        data: [
                          {
                            value:
                              barValues?.find((item) => item.id === box?.id)?.gauge ||
                              box.data.gauge.value,
                            name: '',
                          },
                        ],
                      },
                    ],
                  }}
                />
              </div>
            )}
            {box.type === 'line' && (
              <div
                style={{
                  height: '80%',
                }}
              >
                <ReactECharts
                  option={
                    barValues?.find((item) => item.id === box?.id)?.lineOptions ??
                    box.data.line.options
                  }
                />
              </div>
            )}
            {box.type === 'bar' && (
              <div
                style={{
                  height: '80%',
                }}
              >
                <ReactECharts
                  option={{
                    ...(barValues?.find((item) => item.id === box?.id)?.barOptions ??
                      box.data.bar.options),
                  }}
                />
              </div>
            )}
            {box.type === 'boolean' && (
              <div
                style={{
                  justifyContent: 'center',
                  alignItems: 'center',
                  margin: 'auto',
                }}
              >
                <div style={{color: '#fff'}}>
                  <h3 style={{color: '#fff'}}>{box?.name}</h3>
                  <strong>
                    {!!barValues?.find((item) => item.id === box?.id)?.boolean ? 'TRUE' : 'FALSE'}
                  </strong>
                </div>
              </div>
            )}
            {box.type === 'machine' && (
              <div
                style={{
                  justifyContent: 'center',
                  alignItems: 'center',
                  margin: 'auto',
                }}
              >
                <div
                  style={{
                    paddingLeft: 60,
                  }}
                >
                  <div className='d-flex align-items-center mt-10'>
                    <div
                      className='col-12 col-md-6'
                      style={{
                        textAlign: 'left',
                      }}
                    >
                      <strong
                        style={{
                          fontSize: 20,
                          fontWeight: 'bold',
                        }}
                      >
                        {intl.formatMessage({id: 'ISRUNNING'})}
                      </strong>
                    </div>
                    <div className='col-12 col-md-6'>
                      <div
                        style={{
                          margin: 'auto',
                        }}
                      >
                        <img
                          alt=''
                          src={
                            Number(barValues?.find((item) => item.id === box?.id)?.isRunning) === 1
                              ? '/images/tick.png'
                              : '/images/tick_gray.png'
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className='d-flex align-items-center mt-10'>
                    <div
                      className='col-12 col-md-6'
                      style={{
                        textAlign: 'left',
                      }}
                    >
                      <strong
                        style={{
                          fontSize: 20,
                          fontWeight: 'bold',
                        }}
                      >
                        {intl.formatMessage({id: 'HASERROR'})}
                      </strong>
                    </div>
                    <div className='col-12 col-md-6'>
                      <div
                        style={{
                          margin: 'auto',
                        }}
                      >
                        <img
                          alt=''
                          src={
                            Number(barValues?.find((item) => item.id === box?.id)?.hasError) === 1
                              ? '/images/error.png'
                              : '/images/cross_gray.png'
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        ))}
      </ResponsiveGridLayout>

      {!!editItem && (
        <Modal
          className='modal fade'
          id='kt_modal_select_location'
          data-backdrop='static'
          tabIndex={-1}
          role='dialog'
          show={!!editItem}
          dialogClassName='modal-xl'
        >
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>{intl.formatMessage({id: 'DASHBOARD.EDITGRAP'})}</h5>
            </div>
            <div className='modal-body'>
              <label htmlFor=''>{intl.formatMessage({id: 'DASHBOARD.GRAPNAME'})}</label>
              <input
                type='text'
                className='form-control'
                placeholder='Adı'
                value={name}
                maxLength={25}
                onChange={(e) => setName(e.target.value)}
              />
              <div style={{marginTop: 12}}></div>

              {editItem.type !== 'machine' && (
                <>
                  <label htmlFor=''>{intl.formatMessage({id: 'TAG'})}</label>
                  <Select
                    onChange={(e) => {
                      setSelectedTag(e)
                    }}
                    isMulti={editItem.type === 'line' || editItem.type === 'bar'}
                    value={selectedTag}
                    placeholder={intl.formatMessage({id: 'TAG'})}
                    options={tagsList?.map((tag) => {
                      return {
                        value: tag.id,
                        label: tag.name,
                      }
                    })}
                  />
                </>
              )}
              {editItem.type === 'machine' && (
                <>
                  <label htmlFor=''>{intl.formatMessage({id: 'RUNNINGSTATUSTAG'})}</label>
                  <Select
                    onChange={(e) => {
                      setIsRunningTag(e)
                    }}
                    isMulti={false}
                    value={isRunningTag}
                    placeholder={intl.formatMessage({id: 'TAG'})}
                    options={tagsList?.map((tag) => {
                      return {
                        value: tag.id,
                        label: tag.name,
                      }
                    })}
                  />
                </>
              )}
              {editItem.type === 'machine' && (
                <>
                  <label htmlFor='' className='mt-4'>
                    {intl.formatMessage({id: 'ERRORSTATUSTAG'})}
                  </label>
                  <Select
                    onChange={(e) => {
                      setHasErrorTag(e)
                    }}
                    isMulti={false}
                    value={hasErrorTag}
                    placeholder={intl.formatMessage({id: 'TAG'})}
                    options={tagsList?.map((tag) => {
                      return {
                        value: tag.id,
                        label: tag.name,
                      }
                    })}
                  />
                </>
              )}

              <div style={{marginTop: 12}}></div>
              {(editItem.type === 'line' || editItem.type === 'bar') && (
                <div style={{marginTop: 10}}>
                  <label htmlFor=''>{intl.formatMessage({id: 'TIMEPERIOD'})}</label>
                  <Select
                    onChange={(e) => {
                      setSelectedChartInterval(e)

                      if (e.value === 'live') {
                        setSelectedCalculationType(null)
                        setShowCalculationType(false)
                        return
                      }
                      setShowCalculationType(true)
                    }}
                    value={selectedChartInterval}
                    placeholder={intl.formatMessage({id: 'TIMEPERIOD'})}
                    options={[
                      {
                        value: 'live',
                        label: intl.formatMessage({id: 'LATESTDATA'}),
                      },
                      {
                        value: 'hourly',
                        label: intl.formatMessage({id: 'HOURLY'}),
                      },
                      {
                        value: 'daily',
                        label: intl.formatMessage({id: 'DAILY'}),
                      },
                      {
                        value: 'weekly',
                        label: intl.formatMessage({id: 'WEEKLY'}),
                      },
                      {
                        value: 'monthly',
                        label: intl.formatMessage({id: 'MONTHLY'}),
                      },
                    ]}
                  />
                </div>
              )}
              {(editItem.type === 'line' || editItem.type === 'bar') && showCalculationType && (
                <div style={{marginTop: 10}}>
                  <label htmlFor=''>{intl.formatMessage({id: 'CALCTYPE'})}</label>
                  <Select
                    onChange={(e) => {
                      setSelectedCalculationType(e)
                    }}
                    value={selectedCalculationType}
                    placeholder={intl.formatMessage({id: 'CALCTYPE'})}
                    options={[
                      {
                        value: 'min',
                        label: intl.formatMessage({id: 'MINIMUM'}),
                      },
                      {
                        value: 'max',
                        label: intl.formatMessage({id: 'MAXIMUM'}),
                      },
                      {
                        value: 'avg',
                        label: intl.formatMessage({id: 'AVERAGE'}),
                      },
                    ]}
                  />
                </div>
              )}

              {editItem.type === 'gauge' && (
                <div style={{marginTop: 10}}>
                  <label htmlFor=''>{intl.formatMessage({id: 'MINVAL'})}</label>
                  <input
                    min={1}
                    type='number'
                    className='form-control'
                    placeholder={intl.formatMessage({id: 'MINVAL'})}
                    value={minValue}
                    onChange={(e) => setMinValue(e.target.value)}
                  />
                  <div style={{marginTop: 12}}></div>
                  <label htmlFor=''>{intl.formatMessage({id: 'MAXVAL'})}</label>
                  <input
                    type='number'
                    className='form-control'
                    placeholder={intl.formatMessage({id: 'MAXVAL'})}
                    value={maxValue}
                    onChange={(e) => setMaxValue(e.target.value)}
                  />
                  <div style={{marginTop: 12}}></div>
                </div>
              )}
            </div>
            <div className='modal-footer'>
              <button
                type='button'
                className='btn btn-light-primary'
                onClick={() => {
                  setEditItem(null)
                  setSelectedTag(null)
                  setShowCalculationType(true)
                  setSelectedChartInterval('')
                  setSelectedCalculationType('')
                  setName('')
                  setMaxValue(0)
                  setMinValue(0)
                  setHasErrorTag(null)
                  setIsRunningTag(null)
                }}
              >
                {intl.formatMessage({id: 'CLOSE'})}
              </button>
              <button
                id='submit'
                type='button'
                disabled={isEditButtonDisabled()}
                className='btn btn-primary'
                onClick={async () => {
                  let newChartData = {
                    ...editItem,
                    name: name,
                  }

                  if (
                    editItem.type === 'machine' ||
                    editItem.type === 'bar' ||
                    editItem.type === 'line'
                  ) {
                    newChartData.selectedTags = selectedTag
                  } else if (editItem.type !== 'machine') {
                    newChartData.selectedTag = selectedTag
                  }

                  if (editItem.type === 'machine') {
                    newChartData.isRunningTag = isRunningTag
                    newChartData.hasErrorTag = hasErrorTag

                    delete newChartData.selectedTags
                  }

                  if (editItem.type === 'line' || editItem.type === 'bar') {
                    newChartData.selectedChartInterval = selectedChartInterval
                    newChartData.selectedCalculationType = selectedCalculationType
                  }
                  if (editItem.type === 'gauge') {
                    newChartData.data.gauge.minValue = Number(minValue)
                    newChartData.data.gauge.maxValue = Number(maxValue)
                  }
                  let boxData
                  const clonnedBoxes = [...boxes]
                  if (clonnedBoxes.find((item) => item.id === editItem.id)) {
                    boxData = boxes.map((item) => {
                      if (item.id === editItem.id) {
                        return newChartData
                      }
                      return item
                    })
                  } else {
                    boxData = [...clonnedBoxes, newChartData]
                  }

                  DashboardService.update(dashboardDetail?.id, {
                    ...dashboardDetail,
                    graphs: boxData,
                  }).finally(() => {
                    fetchDashboardData()
                    fetchBarLineValues()
                  })
                  setBoxes(boxData)

                  setEditItem(null)
                  setSelectedChartInterval('')
                  setSelectedCalculationType('')
                  setName('')
                  setSelectedTag(null)
                  setMaxValue(0)
                  setMinValue(0)
                  setHasErrorTag(null)
                  setIsRunningTag(null)
                }}
              >
                {intl.formatMessage({id: 'SAVE'})}
              </button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  )
}

function DragAndDropCharts({setShowAdd, showAdd, tagsList, dashboardDetail, fetchDashboardData}) {
  const [boxes, setBoxes] = useState(
    dashboardDetail?.graphs?.map((item) => {
      return {...item}
    }) ?? []
  )

  useEffect(() => {
    setBoxes(
      dashboardDetail?.graphs?.map((item) => {
        return {...item}
      }) ?? []
    )
  }, [dashboardDetail?.graphs])
  const intl = useIntl()

  const [barValues, setBarValues] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  const fetchBarLineValues = () => {
    if (dashboardDetail?.id === undefined) return
    DashboardService.getBarLineValues(dashboardDetail?.id)
      .then((res) => {
        setBarValues(res)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  useEffect(() => {
    fetchBarLineValues()

    const interval = setInterval(() => {
      fetchBarLineValues()
    }, [10000])

    return () => clearInterval(interval)
  }, [Number(dashboardDetail?.selectedInterval?.value) ?? 10000])

  if (isLoading) {
    return <Loader />
  }
  return (
    <DndProvider backend={HTML5Backend}>
      <div>
        <div>
          <DropTarget
            dashboardDetail={dashboardDetail}
            tagsList={tagsList}
            setBoxes={setBoxes}
            boxes={boxes}
            fetchDashboardData={fetchDashboardData}
            barValues={barValues}
            fetchBarLineValues={fetchBarLineValues}
          />
        </div>
      </div>
      {showAdd && (
        <div
          className='card  p-10 custom-dashboard-wrapper'
          style={{
            position: 'fixed',
            right: 0,
            top: 100,
            zIndex: 1000,
          }}
        >
          <span
            onClick={() => setShowAdd(false)}
            className=' d-flex align-items-center justify-content-center cursor-pointer'
            style={{
              position: 'absolute',
              right: 10,
              top: 10,
              width: 25,
              height: 35,
            }}
          >
            <i className='fa fa-times'></i>
          </span>
          <h3>{intl.formatMessage({id: 'MENU.ADDDASHBOARD'})}</h3>
          <div
            style={{
              overflowY: 'scroll',
            }}
          >
            {/* <div className='d-flex gap-2'> */}
            <Gauge />
            <Line />
            {/* </div> */}
            {/* <div className='d-flex gap-2'> */}
            <Bar />
            <TrueFalse />
            <Machine />
            {/* </div> */}
          </div>
        </div>
      )}
    </DndProvider>
  )
}

export default DragAndDropCharts
