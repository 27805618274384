/* eslint-disable jsx-a11y/anchor-is-valid */
import {PageTitle} from '../../_metronic/layout/core'
import {useIntl} from 'react-intl'
import RoleManagementTable from '../../components/RoleManagement/RoleManagementTable'

const RoleManagementPage = () => {
  const intl = useIntl()

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.ROLEMANAGEMENT'})}</PageTitle>
    <RoleManagementTable/>
    </>
  )
}

export {RoleManagementPage}
