import {useLocation, useNavigate} from 'react-router-dom'
import PackageProductForm from '../../components/PackageProductForm'
import NewDeviceForm from '../../components/NewDeviceForm'
import {useState} from 'react'
import {useIntl} from 'react-intl'

const AddNewDevicePage = () => {
  const navigate = useNavigate()
  const intl = useIntl()
  const location = useLocation()
  const [isSecondPage, setIsSecondPage] = useState(false)

  return (
    <div className='card'>
      <div className='card-body p-4'>
        {!isSecondPage && (
          <button onClick={() => navigate('/device-management')} className='btn btn-primary'>
            {intl.formatMessage({id: 'AUTH.GENERAL.BACK_BUTTON'})}
          </button>
        )}

        <div id='kt_account_profile_details' className='collapse show'>
          <div className='card-body p-9'>
            {location?.search?.includes('package') ? (
              <PackageProductForm isSecondPage={isSecondPage} setIsSecondPage={setIsSecondPage} />
            ) : (
              <NewDeviceForm />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export {AddNewDevicePage}
