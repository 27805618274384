import {useFormik} from 'formik'
import React, {useEffect} from 'react'
import * as Yup from 'yup'
import DeviceService from '../service/device'
import Swal from 'sweetalert2'
import {useNavigate} from 'react-router-dom'
import {useQuery} from 'react-query'
import SourceTypeService from '../service/sourceType'
import {Loader} from './Loader'
import {renderSourceTypeField} from '../utils'
import {useIntl} from 'react-intl'

function NewDeviceForm() {
  const navigate = useNavigate()
  const intl = useIntl()
  const validationSchema = Yup.object().shape({
    name: Yup.string().required(),
    description: Yup.string().nullable(),
    sourceDataKeyValue: Yup.object().required(),
    sourceTypeId: Yup.number().required(),
    edgeDeviceId: Yup.number().required(),
  })

  const formik = useFormik({
    initialValues: {
      name: null,
      description: null,
      sourceDataKeyValue: {},
      sourceTypeId: null,
      edgeDeviceId: null,
    },
    isInitialValid: false,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const sourceDataKeyValueObject = Object.values(values.sourceDataKeyValue)
      const sourceDataKeyValue = []
      sourceDataKeyValueObject.forEach((item) => {
        sourceDataKeyValue.push({
          sourceDataKeyId: item.sourceDataKeyId,
          value: item.value,
        })
      })

      await DeviceService.create({...values, sourceDataKeyValue})
      Swal.fire({
        type: 'success',
        title: 'Başarılı',
        text: 'Cihaz başarıyla oluşturuldu.',
        showConfirmButton: false,
        timer: 1500,
        icon: 'success',
      })
      navigate('/device-management')
    },
  })

  const {data: sourceTypes} = useQuery('sourceTypes', async () =>
    SourceTypeService.getAll({page: 1, perPage: 1000})
  )
  const {data: edgeDevices} = useQuery('edgeDevices', async () =>
    DeviceService.getAllEdgeDevices({page: 1, perPage: 1000})
  )

  const {data: sourceDataKeys, isLoading: isSourceDataKeysLoading} = useQuery(
    ['sourceDataKeys', formik.values.sourceTypeId],
    async () =>
      formik.values.sourceTypeId &&
      SourceTypeService.getSourceDataKeysWithSourceTypeId(formik.values.sourceTypeId),
    {
      enabled: !!formik.values.sourceTypeId,
    }
  )

  useEffect(() => {
    formik.setFieldValue('sourceDataKeyValue', {})
  }, [formik.values.selectedTypeId])

  const buttonIsDisabled = () => {
    if (!formik.isValid || isSourceDataKeysLoading || formik.isSubmitting) {
      return true
    }

    if (!sourceDataKeys?.length || sourceDataKeys?.length === 0) {
      return true
    }
    if (
      sourceDataKeys?.length > 0 &&
      sourceDataKeys?.length !== Object.keys(formik.values.sourceDataKeyValue).length
    ) {
      return true
    }

    return false
  }

  return (
    <div>
      <div className='row mb-6'>
        <label className='col-lg-3 col-form-label fw-bold fs-6'>
          {intl.formatMessage({id: 'EDGEDEVICE'})}
        </label>
        <div className='col-lg-9'>
          <div className='row'>
            <div className='col-lg-12 fv-row'>
              <select
                name='edgeDeviceId'
                className='form-select form-control-solid'
                onChange={(e) => {
                  formik.setFieldValue('edgeDeviceId', parseInt(e.target.value))
                }}
              >
                <option>{intl.formatMessage({id: 'CHOOSE'})}</option>
                {edgeDevices?.items?.map((data) => (
                  <option
                    selected={formik?.edgeDeviceId === data.id}
                    value={data.id}
                    key={data.name}
                  >
                    {data.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      </div>

      <div className='row mb-6'>
        <label className='col-lg-3 col-form-label fw-bold fs-6'>
          {intl.formatMessage({id: 'CONNECTIONTYPE'})}
        </label>
        <div className='col-lg-9'>
          <div className='row'>
            <div className='col-lg-12 fv-row'>
              <select
                name='sourceTypeId'
                className='form-select form-control-solid'
                onChange={(e) => {
                  formik.setFieldValue('sourceTypeId', parseInt(e.target.value))
                }}
              >
                <option>{intl.formatMessage({id: 'CHOOSE'})}</option>
                {sourceTypes?.items?.map((data) => (
                  <option
                    selected={formik?.sourceTypeId === data.id}
                    value={data.id}
                    key={data.name}
                  >
                    {data.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      </div>
      <div className='row mb-6'>
        <label className='col-lg-3 col-form-label required fw-bold fs-6'>
          {intl.formatMessage({id: 'NAME'})}
        </label>
        <div className='col-lg-9'>
          <div className='row'>
            <div className='col-lg-12 fv-row'>
              <input
                autoComplete='off'
                type='text'
                className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                placeholder={intl.formatMessage({id: 'NAME'})}
                required
                value={formik.values.name}
                onChange={formik.handleChange}
                name='name'
              />
            </div>
          </div>
        </div>
      </div>
      <div className='row mb-6'>
        <label className='col-lg-3 col-form-label fw-bold fs-6'>
          {intl.formatMessage({id: 'DESCRIPTION'})}
        </label>
        <div className='col-lg-9'>
          <div className='row'>
            <div className='col-lg-12 fv-row'>
              <textarea
                autoComplete='off'
                type='text'
                className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                placeholder={intl.formatMessage({id: 'DESCRIPTION'})}
                name='description'
                value={formik.values.description}
                onChange={formik.handleChange}
              />
            </div>
          </div>
        </div>
      </div>
      {isSourceDataKeysLoading && <Loader />}
      {sourceDataKeys?.map((dataKey) => (
        <div className='row mb-6'>
          <label className='col-lg-3 col-form-label required fw-bold fs-6'>{dataKey.name}</label>
          <div className='col-lg-9'>
            <div className='row'>
              <div className='col-lg-12 fv-row'>{renderSourceTypeField(dataKey, formik)}</div>
            </div>
          </div>
        </div>
      ))}

      <div className='card-footer d-flex justify-content-end py-6 px-9'>
        <button
          disabled={buttonIsDisabled()}
          onClick={formik.handleSubmit}
          type='button'
          className='btn  btn-primary'
        >
          {intl.formatMessage({id: 'SAVE'})}
        </button>
      </div>
    </div>
  )
}

export default NewDeviceForm
