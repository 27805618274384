import DataTable from 'react-data-table-component-with-filter'
import {useNavigate} from 'react-router-dom'
import {Loader} from '../Loader'
import Swal from 'sweetalert2'
import {useMutation, useQuery} from 'react-query'
import CategoryService from '../../service/category'
import {useEffect} from 'react'
import {usePagination} from '../../hooks'
import {useIntl} from 'react-intl'

const CategoryManagementTable = () => {
  const navigate = useNavigate()
  const intl = useIntl()
  const {setTotalRows, page, perPage, totalRows, handlePerRowsChange, handlePageChange} =
    usePagination()
  const {data, isLoading, refetch} = useQuery(['categories', page + perPage], async () =>
    CategoryService.getAll({
      page,
      perPage,
    })
  )

  useEffect(() => {
    totalRows !== data?.totalCount && setTotalRows(data?.totalCount)
  }, [data?.totalCount])

  const mutation = useMutation(async (id) => await CategoryService.remove(id))

  const columns = [
    {
      name: intl.formatMessage({id: 'CATEGORY'}),
      selector: (row) => row.name,
      sortable: true,
      filterable: true,
    },
    {
      name: intl.formatMessage({id: 'PARENTCATEGORY'}),
      selector: (row) => row.parentId,
      sortable: true,
      filterable: true,
      cell: (row) => {
        return <div>{data?.items?.find((item) => item?.id === row?.parentId)?.name}</div>
      },
    },
    {
      name: intl.formatMessage({id: 'ACTIONS'}),
      sortable: false,
      filterable: false,
      cell: (row) => {
        return (
          <>
            <div>
              <button
                disabled={mutation.isLoading}
                onClick={() => navigate(`/category/${row.id}`)}
                className='btn btn-primary'
              >
                <i className='fa fa-pencil mx-2 '></i>
              </button>
            </div>
            <div>
              <button
                disabled={mutation.isLoading}
                onClick={async () => {
                  await Swal.fire({
                    title: intl.formatMessage({id: 'AREYOUSURE'}),
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: intl.formatMessage({id: 'YES'}),
                    cancelButtonText: intl.formatMessage({id: 'NO'}),
                  }).then(async (result) => {
                    if (result.isConfirmed) {
                      await mutation.mutateAsync(row.id)
                      Swal.fire({
                        title: intl.formatMessage({id: 'SUCCESS'}),
                        // text: 'Saha Alanı başarıyla silindi.',
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 1500,
                      })
                      refetch()
                    }
                  })
                }}
                className='btn btn-danger mx-4'
              >
                <i className='fa fa-trash mx-2 '></i>
              </button>
            </div>
          </>
        )
      },
    },
  ]
  return (
    <div className='card'>
      <div className='card-body p-4'>
        {isLoading ? (
          <div className='text-center mt-2 mb-2'>
            <Loader />
          </div>
        ) : (
          <>
            <div className='table-page-header p-5 my-3'>
              <div className='add-new-user '>
                <button onClick={() => navigate('/category/add')} className='btn btn-primary '>
                  <i className='fa fa-plus mx-2 '></i> {intl.formatMessage({id: 'NEWCATEGORY'})}
                </button>
              </div>
            </div>

            <DataTable
              progressPending={isLoading}
              progressComponent={<Loader />}
              className='table-striped custom-filtered-table'
              pagination
              paginationServer
              paginationTotalRows={totalRows}
              noDataComponent={
                <div style={{margin: '25px 0px'}}>{intl.formatMessage({id: 'TABLE.NODATA'})}</div>
              }
              paginationDefaultPage={page}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
              customStyles={{
                rows: {
                  style: {
                    cursor: 'pointer',
                    backgroundColor: 'var(--table-bg)',
                    '&:hover': {
                      backgroundColor: '#f3f3f3',
                    },
                  },
                },
              }}
              columns={columns}
              data={data?.items}
            />
          </>
        )}
      </div>
    </div>
  )
}

export default CategoryManagementTable
