/* eslint-disable jsx-a11y/anchor-is-valid */
import {PageTitle} from '../../_metronic/layout/core'
import {useIntl} from 'react-intl'
import AddNewData from '../../components/DataManagement/AddNewData'

const AddNewDataPage = () => {
  const intl = useIntl()

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DATAMANAGEMENT'})}</PageTitle>
      <AddNewData />
    </>
  )
}

export {AddNewDataPage}
