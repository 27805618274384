import React, {useEffect, useMemo, useState} from 'react'
import NodeConfigPanel from './NodeConfigPanel'
import Select from 'react-select'
import {v4 as uuid} from 'uuid'
import {Modal} from 'react-bootstrap'
import {useIntl} from 'react-intl'

function ConditionConfiguration({
  selectedConditionNode,
  setSelectedConditionNode,
  conditionNodeData,
  inputNodeData,
  edges,
  setConditionNodeData,
}) {
  const [showModal, setShowModal] = useState(false)
  const [tempData, setTempData] = useState([])
  const intl = useIntl()
  useEffect(() => {
    !showModal && setTempData([])
  }, [showModal])

  const relatedInputDatas = useMemo(() => {
    return (
      edges
        .filter((edge) => edge.target === selectedConditionNode?.id)
        .map((edge) => {
          return inputNodeData.find((item) => item.id === edge.source && !!item.inputValue)
        })
        ?.filter((item) => !!item?.inputValue) ?? []
    )
  }, [edges, inputNodeData, selectedConditionNode])

  const conditions = ['=', '!=', '>', '>=', '<', '<=']

  const updateField = (id, field, value) => {
    setConditionNodeData((prev) => {
      const updatedData = prev.map((prevItem) => {
        if (prevItem.id === id) {
          return {
            ...prevItem,
            [field]: value,
          }
        }
        return prevItem
      })
      return updatedData
    })
  }

  const updateFieldTemp = (id, field, value) => {
    setTempData((prev) => {
      const updatedData = prev.map((prevItem) => {
        if (prevItem.id === id) {
          return {
            ...prevItem,
            [field]: value,
          }
        }
        return prevItem
      })
      return updatedData
    })
  }

  return (
    <div>
      <NodeConfigPanel
        renderCondition={!!selectedConditionNode}
        title={intl.formatMessage({id: 'CONDITIONCONF'})}
        onClose={() => {
          setSelectedConditionNode(null)
        }}
      >
        <div>
          <button
            className='btn btn-primary mb-4'
            onClick={() => {
              setShowModal(true)
              setTempData([
                ...tempData,
                {
                  id: uuid(),
                  condition: '',
                  inputTag: null,
                  conditionValue: '',
                  and: true,
                  or: false,
                  conditionId: selectedConditionNode?.id,
                },
              ])
            }}
          >
            {intl.formatMessage({id: 'ADDNEWCONDITION'})}
          </button>

          <div
            style={{
              maxHeight: 450,
              overflowY: 'scroll',
            }}
          >
            {conditionNodeData
              .filter((item) => item.conditionId === selectedConditionNode?.id)
              .map((item, index) => (
                <div key={item.id}>
                  <div className='d-flex gap-2'>
                    <button
                      className='btn btn-primary btn-sm mb-2'
                      style={{marginLeft: 'auto'}}
                      onClick={() => {
                        setShowModal(true)
                        setTempData([item])
                      }}
                    >
                      {intl.formatMessage({id: 'EDIT'})}
                    </button>
                    <button
                      className='btn btn-danger btn-sm mb-2'
                      onClick={() => {
                        setConditionNodeData((prev) => {
                          return prev?.filter((prevItem) => prevItem.id !== item.id)
                        })
                      }}
                    >
                      {intl.formatMessage({id: 'DELETE'})}
                    </button>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '50px',
                      border: '1px solid #e4e7eb',
                      borderRadius: '5px',
                      fontSize: 16,
                    }}
                  >
                    {item?.inputTag?.label}{' '}
                    <strong style={{fontSize: 20, margin: '0 12px'}}>{item.condition}</strong>{' '}
                    {item.conditionValue}
                  </div>
                  {conditionNodeData.length - 1 !== index && (
                    <>
                      <select
                        className='form-select mb-4'
                        onChange={(e) => {
                          updateField(item.id, 'and', e.target.value === 'and')
                          updateField(item.id, 'or', e.target.value === 'or')
                        }}
                      >
                        <option selected={item.and} value='and'>
                          {intl.formatMessage({id: 'AND'})}
                        </option>
                        <option selected={item.or} value='or'>
                          {intl.formatMessage({id: 'OR'})}
                        </option>
                      </select>
                    </>
                  )}
                </div>
              ))}
          </div>
        </div>
      </NodeConfigPanel>

      <Modal
        className='modal fade'
        id='kt_modal_select_location'
        data-backdrop='static'
        tabIndex={-1}
        role='dialog'
        show={showModal}
        dialogClassName='modal-xl'
      >
        <div className='modal-content'>
          <div className='modal-body'>
            {tempData
              .filter((item) => item.conditionId === selectedConditionNode?.id)
              .map((item, index) => (
                <div key={item.id}>
                  <Select
                    onChange={(e) => {
                      updateFieldTemp(item.id, 'inputTag', e)
                    }}
                    defaultValue={item?.inputTag}
                    value={item?.inputTag}
                    options={relatedInputDatas?.map(({inputValue}) => {
                      return {
                        value: inputValue.value,
                        label: inputValue.label,
                      }
                    })}
                  />
                  <select
                    className='form-select mt-2 mb-4'
                    onChange={(e) => {
                      updateFieldTemp(item.id, 'condition', e.target.value)
                    }}
                  >
                    <option selected={!item.condition} value=''>
                      {intl.formatMessage({id: 'CHOOSE'})}
                    </option>
                    {conditions.map((condition) => {
                      return (
                        <option selected={item.condition === condition} value={condition}>
                          {condition}
                        </option>
                      )
                    })}
                  </select>
                  <input
                    type='text'
                    className='form-control mb-2'
                    placeholder='Condition Value'
                    onChange={(e) => {
                      updateFieldTemp(item.id, 'conditionValue', e.target.value)
                    }}
                    value={item.conditionValue}
                    disabled={false}
                  />
                </div>
              ))}
          </div>
          <div className='modal-footer'>
            <button
              type='button'
              className='btn btn-light-primary'
              onClick={() => setShowModal(false)}
            >
              {intl.formatMessage({id: 'CLOSE'})}
            </button>
            <button
              id='submit'
              type='button'
              disabled={tempData.some(
                (item) => !item.inputTag || !item.condition || !item.conditionValue
              )}
              className='btn btn-primary'
              onClick={async () => {
                setTempData([])
                setShowModal(false)
                if (conditionNodeData.find((item) => item.id === tempData[0]?.id)) {
                  //update item by tempData[0].id without lose sort
                  setConditionNodeData((prev) => {
                    return prev.map((prevItem) => {
                      if (prevItem.id === tempData[0].id) {
                        return tempData[0]
                      }
                      return prevItem
                    })
                  })
                  return
                }

                setConditionNodeData((prev) => {
                  return [...prev, ...tempData]
                })
              }}
            >
              {intl.formatMessage({id: 'SAVE'})}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default ConditionConfiguration
