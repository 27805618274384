import axios from './index'

const getAll = async (params) => {
  try {
    const res = await axios.get('/tag', {
      params,
    })

    return res?.data?.value
  } catch (error) {}
}
const remove = async (id) => {
  const res = await axios.delete(`/tag/${id}`)
  return res?.data
}
const create = async (data) => {
  const res = await axios.post(`/tag`, data)
  return res?.data
}
const importExcel = async (data) => {
  const res = await axios.post(
    `/packageDeviceTag/upload?packageDeviceId=${data.deviceId}`,
    {
      file: data.file,
    },
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  )
  return res?.data
}
const get = async (id) => {
  const res = await axios.get(`/tag/${id}`)
  return res?.data?.value
}

const update = async (data, id) => {
  const res = await axios.put(`/tag/${id}`, data)
  return res?.data
}

const createMany = async (data) => {
  const res = await axios.post(`/tag/CreateMany`, data)
  return res?.data
}

const TagService = {
  getAll,
  remove,
  create,
  get,
  update,
  importExcel,
  createMany,
}

export default TagService
