import {useCallback, useEffect, useMemo, useState} from 'react'
import ReactFlow, {
  Background,
  Controls,
  MiniMap,
  addEdge,
  useEdgesState,
  useNodesState,
} from 'reactflow'
import InputNode from '../../components/Nodes/InputNode'
import OutputNode from '../../components/Nodes/OutputNode'
import ConditionNode from '../../components/Nodes/ConditionNode'
import {v4 as uuid} from 'uuid'
import FlowAside from '../../components/FlowPage/FlowAside'
import NodeConfigPanel from '../../components/FlowPage/NodeConfigPanel'
import {Loader} from '../../components/Loader'
import Select from 'react-select'
import {useQuery} from 'react-query'
import TagService from '../../service/tag'
import ConditionConfiguration from '../../components/FlowPage/ConditionConfiguration'
import {Modal} from 'react-bootstrap'
import FlowService from '../../service/flow'
import Swal from 'sweetalert2'
import {Link, useNavigate} from 'react-router-dom'
import OutputConfiguration from './OutputConfiguration'
import {useIntl} from 'react-intl'

const NewFlowPageHome = ({flowData}) => {
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()
  const intl = useIntl()
  const [reactFlowInstance, setReactFlowInstance] = useState(null)
  const [selectedInputNode, setSelectedInputNode] = useState(null)
  const [selectedOutputNode, setSelectedOutputNode] = useState(null)
  const [flowName, setFlowName] = useState(flowData?.name ?? '')
  const [flowDescription, setFlowDescription] = useState(flowData?.description ?? '')
  const [outputNodeData, setoutPutNodeData] = useState([])
  const [inputNodeData, setInputNodeData] = useState([])
  const [conditionNodeData, setConditionNodeData] = useState([])
  const [selectedConditionNode, setSelectedConditionNode] = useState(null)
  const [isFlowValid, setIsFlowValid] = useState(true)
  const [showModal, setShowModal] = useState(false)
  const {data: tagsList, isLoading: isTagsLoading} = useQuery(['tags'], async () =>
    TagService.getAll({
      page: 1,
      perPage: 1000,
    })
  )

  const generateNodeId = () => `node_${uuid()}`

  const nodeTypes = useMemo(
    () => ({inputNode: InputNode, outputNode: OutputNode, conditionNode: ConditionNode}),
    []
  )

  const [nodes, setNodes, onNodesChange] = useNodesState(flowData?.flowNodeDatas || [])
  const [edges, setEdges, onEdgesChange] = useEdgesState(flowData?.flowConnections ?? [])

  useEffect(() => {
    let isValid = true
    nodes.forEach((node) => {
      if (node.type === 'inputNode') {
        if (!inputNodeData.find((item) => item.id === node.id)) {
          isValid = false
        }
      }
      if (node.type === 'outputNode') {
        if (!outputNodeData.find((item) => item.id === node.id)) {
          isValid = false
        }
      }
    })
    setIsFlowValid(isValid)
  }, [nodes, edges, inputNodeData, outputNodeData, conditionNodeData])
  useEffect(() => {
    if (!!flowData?.flowNodeDatas) {
      flowData?.flowNodeDatas?.forEach((node) => {
        if (node.id.includes('input')) {
          setInputNodeData((prev) =>
            prev
              .filter((item) => item.id !== node.id)
              ?.concat({id: node.id, inputValue: node?.data?.input?.inputValue})
          )
        }

        if (node.id.includes('output')) {
          setoutPutNodeData((prev) =>
            prev
              .filter((item) => item.id !== node.id)
              .concat({
                id: node.id,
                inputValue: node?.data?.output?.inputValue,
                outputs: node?.data?.output?.outputs,
              })
          )
        }

        if (node.id.includes('condition')) {
          setConditionNodeData((prev) => [
            ...prev?.filter((item) => item.id !== node?.id).concat(node?.data?.condition),
          ])
        }
      })
    }
  }, [flowData?.id])

  const onConnect = useCallback(
    (params) =>
      setEdges((eds) =>
        addEdge(
          {
            ...params,
          },
          eds
        )
      ),
    [setEdges]
  )

  const onDragStart = (event, nodeType, nodeField) => {
    event.dataTransfer.setData('application/reactflow', nodeType, nodeField)
    event.dataTransfer.effectAllowed = 'move'
    event.dataTransfer.brand = nodeField
  }

  const onDrop = useCallback(
    (event) => {
      event.preventDefault()

      const type = event.dataTransfer.getData('application/reactflow')
      if (typeof type === 'undefined' || !type) {
        return
      }
      const position = reactFlowInstance.screenToFlowPosition({
        x: event.clientX,
        y: event.clientY,
      })
      var newNode

      switch (type) {
        case 'outputNode':
          newNode = {
            id: `output_${generateNodeId()}`,
            type: type,
            position,
            data: {label: intl.formatMessage({id: 'OUTPUT'})},
          }
          break
        case 'inputNode':
          newNode = {
            id: `input_${generateNodeId()}`,
            type: type,
            position,
            data: {label: intl.formatMessage({id: 'INPUT'})},
          }
          break
        default:
          newNode = {
            id: `condition_${generateNodeId()}`,
            type: type,
            position,
            data: {label: intl.formatMessage({id: 'CONDITION'})},
          }
          break
      }

      setNodes((nds) => (nds ?? []).concat(newNode))
    },
    [reactFlowInstance]
  )

  const onDragOver = useCallback((event) => {
    event.preventDefault()
    event.dataTransfer.dropEffect = 'move'
  }, [])

  function miniMapNodeColor(node) {
    switch (node.type) {
      case 'inputNode':
        return '#1e88e5'
      case 'outputNode':
        return '#1e88e5'
      default:
        return '#1ee564'
    }
  }

  const onSaveFlow = async () => {
    setIsLoading(true)

    if (!isFlowValid) {
      Swal.fire(intl.formatMessage({id: 'INPUTOUTPUTBLANK'}), '', 'error')
      setIsLoading(false)
      return
    }
    try {
      const flowObject = {
        flowNodeDatas: nodes.map((node) => {
          let externalParams = {}
          if (node.type === 'conditionNode') {
            externalParams.condition = conditionNodeData?.filter(
              (item) => item.conditionId === node.id
            )
          }
          if (node.type === 'inputNode') {
            externalParams.input = inputNodeData?.find((item) => item.id === node.id)
          }
          if (node.type === 'outputNode') {
            externalParams.output = outputNodeData?.find((item) => item.id === node.id)
          }
          return {
            ...node,
            data: {
              ...externalParams,
            },
          }
        }),
        flowConnections: edges,
        name: flowName,
        description: flowDescription,
      }

      if (!flowData?.id) {
        const createdFlow = await FlowService.create(flowObject)
        navigate(`/flows/${createdFlow.id}`)
      } else {
        await FlowService.update(
          {...flowObject, name: flowName, description: flowDescription},
          flowData?.id
        )
        Swal.fire(intl.formatMessage({id: 'SUCCESS'}), '', 'success')
      }
    } catch (error) {
      console.log(error);
      Swal.fire(intl.formatMessage({id: 'ERROR'}), JSON.stringify(error), 'error')
      setIsLoading(false)
    }
    setIsLoading(false)
  }

  return (
    <div>
      <style
        dangerouslySetInnerHTML={{
          __html: `#kt_app_toolbar{
          display: none!important;
      }    
      #kt_app_content{
        padding-top: 0px!important;
      }
      `,
        }}
      />
      <Link className='btn  btn-danger btn-sm ' to='/flows'>
        {intl.formatMessage({id: 'AUTH.GENERAL.BACK_BUTTON'})}
      </Link>
      <div className='d-flex align-items-center gap-2 mb-4 mt-4'>
        <h1>{flowName}</h1>
        <i
          onClick={() => setShowModal(true)}
          className='fa fa-pen btn btn-primary'
          style={{fontSize: 16, marginBottom: 6, cursor: 'pointer'}}
        ></i>
      </div>

      <div className='d-flex align-items-center justify-content-between'>
        <FlowAside
          isConditionDraggable={nodes.filter((node) => node.type === 'conditionNode').length === 0}
          onDragStart={onDragStart}
        />

        <button disabled={isLoading} className='btn btn-primary' onClick={() => onSaveFlow()}>
          <i className='fa fa-save'></i> {intl.formatMessage({id: 'SAVE'})}
        </button>
      </div>

      <div style={{width: '100%', height: '70vh'}}>
        <ReactFlow
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          nodeTypes={nodeTypes}
          onDragOver={onDragOver}
          onDrop={onDrop}
          onInit={setReactFlowInstance}
          deleteKeyCode={['Delete', 'Backspace']}
          defaultEdgeOptions={{
            animated: true,
            style: {
              strokeWidth: 4,
              stroke: '#1e88e5',
            },
          }}
          onNodeClick={(event, node) => {
            if (node.id.toLocaleLowerCase().includes('input')) {
              setSelectedInputNode(node)
              setSelectedOutputNode(null)
              setSelectedConditionNode(null)
            }
            if (node.id.toLocaleLowerCase().includes('condition')) {
              setSelectedInputNode(null)
              setSelectedOutputNode(null)
              setSelectedConditionNode(node)
            }
            if (node.id.toLocaleLowerCase().includes('output')) {
              setSelectedInputNode(null)
              setSelectedConditionNode(null)
              setSelectedOutputNode(node)
            }
          }}
          onPaneClick={() => {
            setSelectedInputNode(null)
          }}
        >
          <Controls />
          <MiniMap nodeColor={miniMapNodeColor} />
          <Background variant='lines' gap={12} size={1} />
        </ReactFlow>
      </div>

      <NodeConfigPanel
        renderCondition={!!selectedInputNode}
        title='Giriş Konfigürasyon'
        onClose={() => setSelectedInputNode(null)}
      >
        {isTagsLoading ? (
          <Loader />
        ) : (
          <>
            {!!tagsList && (
              <Select
                onChange={(e) => {
                  const nodesClone = [...nodes.filter((node) => node.id !== selectedInputNode.id)]
                  nodesClone.push({
                    ...selectedInputNode,
                    data: {
                      label: e.label,
                    },
                  })
                  setNodes(nodesClone)

                  const inputNodeDataClone = [
                    ...inputNodeData.filter((item) => item.id !== selectedInputNode.id),
                  ]

                  setInputNodeData(
                    inputNodeDataClone.concat({id: selectedInputNode.id, inputValue: e})
                  )
                }}
                value={
                  inputNodeData?.find((item) => item?.id === selectedInputNode?.id)?.inputValue ??
                  null
                }
                options={tagsList?.map((tag) => {
                  return {
                    value: tag.id,
                    label: tag.name,
                  }
                })}
              />
            )}
          </>
        )}
      </NodeConfigPanel>

      <OutputConfiguration
        selectedOutputNode={selectedOutputNode}
        setSelectedOutputNode={setSelectedOutputNode}
        nodes={nodes}
        edges={edges}
        setNodes={setNodes}
        outputNodeData={outputNodeData}
        setoutPutNodeData={setoutPutNodeData}
        inputNodeData={inputNodeData}
        flowData={flowData}
      />

      <ConditionConfiguration
        edges={edges}
        inputNodeData={inputNodeData}
        selectedConditionNode={selectedConditionNode}
        setSelectedConditionNode={setSelectedConditionNode}
        conditionNodeData={conditionNodeData}
        setConditionNodeData={setConditionNodeData}
      />

      <Modal
        className='modal fade'
        id='kt_modal_select_location'
        data-backdrop='static'
        tabIndex={-1}
        role='dialog'
        show={showModal}
        dialogClassName='modal-xl'
      >
        <div className='modal-content'>
          <div className='modal-header'>
            <h5 className='modal-title'>{intl.formatMessage({id: 'EDITFLOW'})}</h5>
          </div>
          <div className='modal-body'>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
              placeholder={intl.formatMessage({id: 'FLOWNAME'})}
              required
              value={flowName}
              onChange={(e) => setFlowName(e.target.value)}
            />
            <input
              type='text'
              className='form-control form-control-lg form-control-solid mb-3 mb-lg-0 mt-4'
              placeholder={intl.formatMessage({id: 'FLOWDESC'})}
              required
              value={flowDescription}
              onChange={(e) => setFlowDescription(e.target.value)}
            />
          </div>
          <div className='modal-footer'>
            <button
              id='submit'
              type='button'
              disabled={!flowName}
              className='btn btn-primary'
              onClick={async () => {
                setShowModal(false)
              }}
            >
              {intl.formatMessage({id: 'SAVE'})}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export {NewFlowPageHome}
