import Swal from 'sweetalert2'
import {useNavigate, useParams} from 'react-router-dom'
import CategoryService from '../../service/category'
import {useQuery} from 'react-query'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {useEffect, useState} from 'react'
import {Loader} from '../../components/Loader'
import {useIntl} from 'react-intl'

const EditCategoryPage = () => {
  const navigate = useNavigate()
  const params = useParams()
  const intl = useIntl()
  const {data: categories, isLoading: isCategoriesLoading} = useQuery('categories', async () =>
    CategoryService.getAll({
      page: 1,
      perPage: 1000,
    })
  )
  const {data, isLoading} = useQuery(['category', params.id], async () =>
    CategoryService.get(params.id)
  )

  const [isMain, setIsMain] = useState(!data?.parentId)

  useEffect(() => {
    if (data?.parentId) {
      setIsMain(false)
    }
  }, [data?.parentId])

  const formik = useFormik({
    initialValues: {
      name: data?.name ?? '',
      description: data?.description ?? '',
      parentId: data?.parentId ?? null,
    },
    enableReinitialize: true,
    isInitialValid: false,
    validationSchema: Yup.object().shape({
      name: Yup.string().required('Category Name is required'),
      description: Yup.string().nullable(),
      parentId: Yup.mixed().nullable(),
    }),
    onSubmit: async (values) => {
      await CategoryService.update(values, params.id)
      Swal.fire({
        type: 'success',
        title: 'Başarılı',
        text: 'Saha alanı başarıyla düzenlendi.',
        showConfirmButton: false,
        timer: 1500,
        icon: 'success',
      })
    },
  })
  return (
    <div className='card'>
      <div className='card-body p-4'>
        <button onClick={() => navigate('/category')} className='btn btn-primary'>
          {intl.formatMessage({id: 'AUTH.GENERAL.BACK_BUTTON'})}
        </button>
        {isCategoriesLoading || isLoading ? (
          <div className='card-body p-9'>
            <Loader />
          </div>
        ) : (
          <div id='kt_account_profile_details' className='collapse show'>
            <form noValidate className='form'>
              <div className='card-body p-9'>
                <div className='row mb-6'>
                  <label className='col-lg-3 col-form-label required fw-bold fs-6'>
                    {intl.formatMessage({id: 'ISMAINCATEGORY'})}{' '}
                  </label>

                  <div className='col-lg-9'>
                    <div className='row'>
                      <div className='col-lg-12 fv-row'>
                        <input
                          type='checkbox'
                          className='form-check-input'
                          name='isMain'
                          id=''
                          checked={isMain}
                          onChange={(e) => {
                            setIsMain(!isMain)
                            console.log(isMain)
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {!isMain && (
                  <div className='row mb-6'>
                    <label className='col-lg-3 col-form-label fw-bold fs-6'>
                      {intl.formatMessage({id: 'PARENTCATEGORY'})}
                    </label>

                    <div className='col-lg-9'>
                      <div className='row'>
                        <div className='col-lg-12 fv-row'>
                          <select
                            value={formik.values.parentId}
                            onChange={formik.handleChange}
                            name='parentId'
                            className='form-select form-control-solid'
                          >
                            <option selected> {intl.formatMessage({id: 'PARENTCATEGORY'})}</option>
                            {categories?.items
                              ?.filter((category) => category.id !== data?.id)
                              ?.map((item) => (
                                <option selected={item.id === data?.parentId} value={item.id}>
                                  {item.name}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <div className='row mb-6'>
                  <label className='col-lg-3 col-form-label required fw-bold fs-6'>
                    {intl.formatMessage({id: 'CATEGORYNAME'})}
                  </label>

                  <div className='col-lg-9'>
                    <div className='row'>
                      <div className='col-lg-12 fv-row'>
                        <input
                          type='text'
                          className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                          placeholder={intl.formatMessage({id: 'CATEGORYNAME'})}
                          name='name'
                          value={formik.values.name}
                          onChange={formik.handleChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-3 col-form-label fw-bold fs-6'>
                    {intl.formatMessage({id: 'DESCRIPTION'})}
                  </label>

                  <div className='col-lg-9'>
                    <div className='row'>
                      <div className='col-lg-12 fv-row'>
                        <input
                          type='text'
                          className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                          placeholder={intl.formatMessage({id: 'DESCRIPTION'})}
                          name='description'
                          value={formik.values.description}
                          onChange={formik.handleChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='card-footer d-flex justify-content-end py-6 px-9'>
                <button
                  disabled={!formik.isValid || formik.isSubmitting}
                  type='submit'
                  onClick={formik.handleSubmit}
                  className='btn  btn-primary '
                >
                  {intl.formatMessage({id: 'SAVE'})}
                </button>
              </div>
            </form>
          </div>
        )}
      </div>
    </div>
  )
}

export {EditCategoryPage}
