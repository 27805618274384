import {Loader} from '../../components/Loader'
import {useEffect} from 'react'
import DashboardService from '../../service/dashboard'
import {useQuery} from 'react-query'

const DefaultDashboardPage = () => {
  const {data: defaultDashboard, isLoading} = useQuery(['defaultDashboard'], async () => {
    return await DashboardService.getDefaultDashboard()
  })

  useEffect(() => {
    if (!isLoading) {
      if (defaultDashboard && defaultDashboard.id) {
        window.location.href = `/dashboard-management/${defaultDashboard.id}`
      } else {
        window.location.href = '/dashboard-management'
      }
    }
  }, [defaultDashboard, isLoading])
  return <Loader show />
}

export {DefaultDashboardPage}
