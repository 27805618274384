import axios from './index'

// const identityBaseUrl = process.env.REACT_APP_API_BASE_URL
// console.log(identityBaseUrl)
const login = async (params) => {
  try {
    const res = await axios.post('https://api.photonce.com/connect/token', params, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
    return res
  } catch (error) {
    return await error?.response?.data
  }
}

const me = async () => {
  try {
    const res = await axios.get('/account/my-profile')

    return res
  } catch (error) {
    return await error?.response?.data
  }
}

const getUsers = async (params) => {
  try {
    const res = await axios.get('/identity/users', {params})

    return res.data
  } catch (error) {
    return await error?.response?.data
  }
}
const getRoles = async (params) => {
  try {
    const res = await axios.get('/identity/users/assignable-roles', {params})

    return res.data
  } catch (error) {
    return await error?.response?.data
  }
}
const create = async (params) => {
  try {
    const res = await axios.post('/identity/users', params)
    console.log(res)
    return res
  } catch (error) {
    return await error?.response
  }
}
const remove = async (id) => {
  const res = await axios.delete(`/identity/users/${id}`)
  return res?.data
}
const updateProfile = async (params) => {
  try {
    const res = await axios.put('/account/my-profile', params)

    return res.data
  } catch (error) {
    return await error?.response?.data
  }
}
const get = async (id) => {
  try {
    const res = await axios.get(`/identity/users/${id}`)
    return res.data
  } catch (error) {
    return await error?.response
  }
}
const update = async (id, data) => {
  try {
    const res = await axios.put(`/identity/users/${id}`, data)
    return res
  } catch (error) {
    return await error?.response
  }
}

const sendMail = async (data) => {
  try {
    const res = await axios.post('/communication', data)

    return res
  } catch (error) {
    return await error?.response?.data
  }
}

const register = async (params) => {
  try {
    const res = await axios.post('/account/register', params)

    return res
  } catch (error) {
    return await error?.response?.data
  }
}
const resetPassword = async (params) => {
  try {
    const res = await axios.post('/account/send-password-reset-code', params)

    return res?.data
  } catch (error) {}
}

const AccountService = {
  login,
  register,
  getUsers,
  resetPassword,
  update,
  getRoles,
  remove,
  create,
  sendMail,
  get,
  me,
  updateProfile,
}

export default AccountService
