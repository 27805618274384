import axios from './index'

const getAll = async (params) => {
  try {
    const res = await axios.get('/dataType', {
      params,
    })

    return res?.data?.value
  } catch (error) {}
}
 
const DataTypeService = {
  getAll,
}

export default DataTypeService
