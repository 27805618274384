import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'

const TreeItem = ({item, formik, initialOpen, initialSelectedItemId}) => {
  const [isOpen, setIsOpen] = useState(!!initialOpen)
  const intl = useIntl()
  useEffect(() => {
    setIsOpen(!!initialOpen && !item.forceStop && initialSelectedItemId !== item.id)
    if (initialSelectedItemId === item.id && item.subDatas) {
      item.forceStop = true
      item.subDatas.forEach((_, i) => {
        item.subDatas[i].forceStop = true
      })
    }
  }, [initialOpen, initialSelectedItemId])
  const handleToggle = () => {
    setIsOpen(!isOpen)
  }

  return (
    <div className='tree-item '>
      <div
        className='tree-node cursor-pointer'
        style={{
          marginLeft: `${item.depth * 20}px`,
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <div onClick={handleToggle}>
          <span className='toggle btn btn-primary btn-sm' style={{cursor: 'pointer'}}>
            {isOpen ? '-' : '+'}
          </span>
          <span>{item.name}</span>
        </div>
        <button
          onClick={() => formik.setFieldValue('areaId', item.id)}
          className={`btn cursor-pointer ${
            formik.values.areaId === item.id ? 'btn-dark' : 'btn-primary'
          } btn-sm`}
        >
          {formik.values.areaId === item.id
            ? intl.formatMessage({id: 'CHOOSED'})
            : intl.formatMessage({id: 'CHOOSE'})}
        </button>
      </div>
      {isOpen && (
        <div className='tree-children' style={{marginLeft: '20px'}}>
          {item?.subDatas?.map((child, index) => (
            <TreeItem
              initialOpen={initialOpen}
              formik={formik}
              key={index}
              item={child}
              initialSelectedItemId={initialSelectedItemId}
            />
          ))}
        </div>
      )}
    </div>
  )
}

const TreeList = ({data, formik, initialSelectedItemId}) => {
  return (
    <div className='tree-list'>
      {data?.map((item, index) => (
        <TreeItem
          initialSelectedItemId={initialSelectedItemId}
          formik={formik}
          key={index}
          item={item}
          data={data}
          initialOpen={item.initialOpen}
        />
      ))}
    </div>
  )
}

export default TreeList
