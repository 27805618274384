import React from 'react'
import {v4 as uuidv4} from 'uuid'
import {useDrag} from 'react-dnd'
import {useIntl} from 'react-intl'

const Bar = () => {
  const intl = useIntl()

  const [{isDragging}, drag] = useDrag({
    type: 'bar',
    item: {
      selectedTags: [],
      id: uuidv4(),
      type: 'bar',
      dataGrid: {x: 0, y: 0, w: 4, h: 6, minW: 4, minH: 6,maxH:6},
      data: {
        bar: {
          options: {
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'shadow',
              },
            },
            grid: {
              left: '3%',
              right: '4%',
              bottom: '3%',
              containLabel: true,
            },
            xAxis: [
              {
                type: 'category',
                data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
                axisTick: {
                  alignWithLabel: true,
                },
              },
            ],
            yAxis: [
              {
                type: 'value',
              },
            ],
            series: [
              {
                name: 'Direct',
                type: 'bar',
                barWidth: '60%',
                data: [10, 52, 200, 334, 390, 330, 220],
              },
            ],
          },
        },
      },
    },

    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  })

  return (
    <div
      ref={drag}
      style={{
        opacity: isDragging ? 0.5 : 1,
        cursor: 'move',
        // border: '1px solid black',
        width: '100%',
        padding: 15,
        borderRadius: 4,
        marginTop: 20,
      }}
    >
      <div className='text-center'>
        <h6>{intl.formatMessage({id: 'BARCHART'})}</h6>
        <img alt='' src='/images/bar.png' width='75' height={50} />
      </div>
    </div>
  )
}

export default Bar
